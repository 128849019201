import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import Modal from '../Modal';

const CancelPrompt = (props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onExitClick, title } = props;

  return (
    <Modal
      body={
        <p>{t('Cancelling now will discard the form data you entered.')}</p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Keep Editing')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Confirm Cancel')}
            variant="solidRed"
            onClick={() => {
              onExitClick();
            }}
          />
        </div>
      }
      header={<span>{title}</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

CancelPrompt.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onExitClick: PropTypes.func,
  title: PropTypes.string,
};

CancelPrompt.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onExitClick: () => {},
  title: '',
};

export default CancelPrompt;
