import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo-full.svg';
import useApiErrorTranslation from '../../hooks/useApiErrorTranslation';

const ActivateUserErrorScreen = (props) => {
  const { fetchedUserError } = props;
  const { t } = useTranslation();
  const { translateError } = useApiErrorTranslation();

  return (
    <div className="absolute top-1/3 w-full">
      <div className="container mx-auto">
        <div className="flex flex-1 flex-col items-center">
          <div className="pb-10 text-center">
            <Link to="/">
              <span>
                <img alt="" height="96" src={logo} />
              </span>
            </Link>
          </div>
          <div className="px-9 pb-8 text-center text-base font-semibold text-primary-dark">
            <div>{translateError(fetchedUserError)}</div>
            {t(`Please contact your Urbify administrator.`)}
          </div>
        </div>
      </div>
    </div>
  );
};

ActivateUserErrorScreen.propTypes = {
  fetchedUserError: PropTypes.shape({}),
};

ActivateUserErrorScreen.defaultProps = {
  fetchedUserError: undefined,
};

export default ActivateUserErrorScreen;
