import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

const DismissAlertsModal = ({ handleReadAll, isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {t(
            'This action will mark all active alerts as read and move them to Recent.',
          )}
        </p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Dismiss')}
            variant="solidRed"
            onClick={handleReadAll}
          />
        </div>
      }
      header={<span>{t('Dismiss all active alerts?')}</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

DismissAlertsModal.propTypes = {
  handleReadAll: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

DismissAlertsModal.defaultProps = {
  handleReadAll: () => {},
};

export default DismissAlertsModal;
