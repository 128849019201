import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { io } from 'socket.io-client';

import UserRole from '../../../enums/UserRole';
import isMobileUserAgent from '../../../helpers/isMobileUserAgent';
import useCustomToast from '../../../hooks/useCustomToast';
import { useSocketId } from '../../../providers/SocketIdProvider';
import { useUser } from '../../../providers/UserProvider';
import useFetch from './useFetch';

const WebsocketContext = createContext();

/**
 * Used for mocking in Cypress
 */
const mockIo = window.io;

export const WebsocketProvider = (props) => {
  const { children, enabled } = props;
  const { setSocketId, socketId } = useSocketId();
  const { refreshToken } = useFetch();
  const { toastInfo } = useCustomToast();
  const { t } = useTranslation();
  // must be ref to prevent reinitializing socket on change
  const isReconnecting = useRef(false);

  const socket = useRef(null);

  const { getAuth, user } = useUser();

  const isValidUser =
    user &&
    user?.role !== UserRole.Customer &&
    user?.role !== UserRole.Crew &&
    user?.carrier?.workflow;

  const connectionShouldBeActive = enabled && isValidUser;

  const timeoutRef = useRef(null);
  const socketConnectionLostTimeoutRef = useRef(null);

  useEffect(() => {
    if (socket.current) {
      return;
    }

    if (mockIo) {
      socket.current = mockIo;
    } else {
      // eslint-disable-next-line no-console
      console.log('[socket] create socket.io instance');
      socket.current = io(import.meta.env.VITE_API_URL || '/', {
        auth: {},
        reconnection: true,
        autoConnect: false,
        transports: ['websocket'],
      });
    }
  }, []);

  const openWSConnection = useCallback(() => {
    if (socket.current) {
      // eslint-disable-next-line no-console
      console.log('[socket] opening websocket connection');
      if (socket.current.auth) {
        socket.current.auth.token = getAuth()?.accessToken;
      }

      if (socket.current.connect) {
        // no-op if the socket is already connected
        socket.current.connect();
      }
    }
  }, [getAuth]);

  const closeWSConnection = useCallback(() => {
    isReconnecting.current = false;

    if (socket.current) {
      // always call disconnect() on closeWSConnection, but show console log if
      // the socket is connected or trying to connect. otherwise, it is not necessary
      if (socket.current.connected || socket.current.active) {
        // eslint-disable-next-line no-console
        console.log('[socket] closing websocket connection');
      }

      socket.current.disconnect();
      // remove auth data
      socket.current.auth = {};
    }
  }, []);

  useEffect(() => {
    if (connectionShouldBeActive) {
      openWSConnection();
    } else {
      closeWSConnection();
      clearTimeout(timeoutRef?.current);
      clearTimeout(socketConnectionLostTimeoutRef?.current);
    }

    const timeoutRefCurrent = timeoutRef.current;

    return () => {
      closeWSConnection();
      clearTimeout(timeoutRefCurrent);
      clearTimeout(socketConnectionLostTimeoutRef?.current);
    };
  }, [closeWSConnection, connectionShouldBeActive, openWSConnection]);

  useEffect(() => {
    function onConnect() {
      setSocketId(socket.current.id);
      // eslint-disable-next-line no-console
      console.log('[socket] connect', '--', 'socketId:', socket.current.id);
      clearTimeout(socketConnectionLostTimeoutRef.current);
      if (isReconnecting.current) {
        // only show if the tab is visible on screen
        if (!document.hidden) {
          toastInfo(t('Live updates have been restored.'));
        }

        isReconnecting.current = false;
      }
    }

    function onDisconnect(reason, details) {
      setSocketId(undefined);

      socketConnectionLostTimeoutRef.current = setTimeout(() => {
        // if user is not logged in, don't show toast
        if (getAuth()) {
          // only show if the tab is visible on screen
          if (!document.hidden) {
            // set reconnecting to true so the user sees "connection restored" toast only if they see the "lost connection" toast
            isReconnecting.current = true;
            toastInfo(
              t(
                'Live updates are temporary unavailable. Reconnecting to server...',
              ),
            );
            socketConnectionLostTimeoutRef.current = null;
          }
        }
      }, 3000);
      // eslint-disable-next-line no-console
      console.log('[socket] disconnect', '--', `reason: ${reason}`, details);
    }

    async function onConnectError(e) {
      // eslint-disable-next-line no-console
      console.error(`[socket] connect_error - message: "${e.message}"`);

      if (socket.current.active) {
        // eslint-disable-next-line no-console
        console.log(
          '[socket] connect_error - temporary failure, the socket will automatically try to reconnect',
        );
        return;
      }

      if (!getAuth()?.refreshToken) {
        // eslint-disable-next-line no-console
        console.error(
          `[socket] connect_error - reconnection skipped, refresh token not found`,
        );
        return;
      }

      // backend sends websocket exception with this message in case of invalid credentials
      // only in this case, we can try to refresh the token and reconnect
      if (e?.message !== 'Invalid Credentials') {
        // eslint-disable-next-line no-console
        console.log(
          `[socket] connect_error - refresh skipped, error unrelated for refresh`,
        );
        return;
      }

      // eslint-disable-next-line no-console
      console.error(`[socket] connect_error - refresh started`);

      refreshToken()
        .then(() => {
          // eslint-disable-next-line no-console
          console.log(
            '[socket] connect_error - refresh finished, attempting reconnect',
          );

          socket.current.auth.token = getAuth()?.accessToken;
          socket.current.connect();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }

    function onReconnectAttempt(attempt) {
      // eslint-disable-next-line no-console
      console.log('[socket] reconnect_attempt - attempt: ', attempt);
      socket.current.auth.token = getAuth()?.accessToken;
    }

    socket.current.on('connect', onConnect);
    socket.current.on('disconnect', onDisconnect);
    socket.current.on('connect_error', onConnectError);
    // listener must be set on .io object
    // https://socket.io/docs/v3/migrating-from-2-x-to-3-0/index.html#the-socket-instance-will-no-longer-forward-the-events-emitted-by-its-manager

    if (socket.current.io) {
      socket.current.io.on('reconnect_attempt', onReconnectAttempt);
    }

    return () => {
      socket.current.off('connect', onConnect);
      socket.current.off('disconnect', onDisconnect);
      socket.current.off('connect_error', onConnectError);

      if (socket.current.io) {
        socket.current.io.off('reconnect_attempt', onReconnectAttempt);
      }
    };
  }, [getAuth, refreshToken, setSocketId, t, toastInfo]);

  // visibility listener
  useEffect(() => {
    function handleVisibilityChange() {
      if (document.hidden) {
        if (socket.current?.disconnect && isMobileUserAgent()) {
          const oneMinute = 1000 * 60;
          // Disconnect socket when tab is in background for 1 minute, only on mobile devices
          timeoutRef.current = setTimeout(() => {
            socket.current.disconnect();
          }, oneMinute);
        }
      } else {
        clearTimeout(timeoutRef.current);
        if (socket.current?.connect && connectionShouldBeActive) {
          // Reconnect socket when tab is in foreground
          socket.current.connect();
        }
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [connectionShouldBeActive]);

  const value = useMemo(() => ({ socket, socketId }), [socket, socketId]);

  return (
    <WebsocketContext.Provider value={value}>
      {children}
    </WebsocketContext.Provider>
  );
};

WebsocketProvider.propTypes = {
  children: PropTypes.node,
  enabled: PropTypes.bool,
};

WebsocketProvider.defaultProps = {
  children: undefined,
  enabled: true,
};

export const useSocket = () => useContext(WebsocketContext);

export const useSocketListen = (event, onEvent, ignoreOwnRequests = true) => {
  // `socketId` is used in dependency array because while the hook is created, the connection is not yet established.
  //  In `socket.current.on('connect')` handler, we update the `socketId` state with the connection identifier, which is used as a confirmation that the events can be sent and received.
  //  If the `socketId` is not used in the dependency array, a call to `useSocketListen` would not handle events if the `socket.current` is `undefined`, before the socket.io is instantiated.
  //  And, as that variable is saved in ref, it would never get invalidated in the dependency array.
  const { socket, socketId } = useContext(WebsocketContext);

  useEffect(() => {
    const webSocket = socket.current;
    if (!socketId) {
      return undefined;
    }
    const callback = (data) => {
      // ignore event if it's made by the same user -> see useFetch.js
      if (data?.originSocketId === socketId && ignoreOwnRequests) {
        return;
      }
      onEvent(data);
    };

    if (webSocket && event) {
      webSocket.on(event, callback);
    }
    return () => {
      if (webSocket && event) {
        webSocket.off(event, callback);
      }
    };
  }, [socket, event, onEvent, socketId, ignoreOwnRequests]);
};

export const useSocketIOChannel = (payload) => {
  const { socket, socketId } = useContext(WebsocketContext);

  useEffect(() => {
    if (socket && socket.current && payload && socketId) {
      const socketInstance = socket.current;
      socketInstance.emit('joinChannel', payload);
      // eslint-disable-next-line no-console
      console.log('[socket] join channel ', payload);
      return () => {
        socketInstance.emit('leaveChannel', payload);
        // eslint-disable-next-line no-console
        console.log('[socket] leave channel', payload);
      };
    }

    return undefined;
  }, [payload, socket, socketId]);
};

export const SocketHandler = (props) => {
  const { entity, id, onEvent } = props;

  const socketChannelConfig = useMemo(
    () => ({
      entity,
      id,
    }),
    [entity, id],
  );

  useSocketIOChannel(socketChannelConfig);

  const eventName = id ? `${entity}|${id}` : entity;
  useSocketListen(eventName, onEvent);

  return null;
};

SocketHandler.propTypes = {
  entity: PropTypes.string.isRequired,
  id: PropTypes.string,
  onEvent: PropTypes.func.isRequired,
};

SocketHandler.defaultProps = {
  id: null,
};
