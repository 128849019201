import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { usePrevious } from 'react-use';

import Button from '../../../components/Button';
import DebouncedSearch from '../../../components/DebouncedSearch';
import Drawer from '../../../components/Drawer';
import parseSearchParams from '../../../helpers/parseSearchParams';

const SuperadminMemberFiltersDrawer = (props) => {
  const { isOpen, onClose } = props;
  const prevIsOpen = usePrevious(isOpen);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(parseSearchParams(searchParams));

  const searchQuery = searchParams.get('search') || '';

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setParams(parseSearchParams(searchParams));
    }
  }, [searchParams, isOpen, prevIsOpen]);

  const [searchInputKey] = useState(new Date().toString());

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      header={t('Filters')}
      footer={
        <Button
          data-test="modal-button-action"
          text={t('Apply')}
          variant="solidBlue"
          isFullWidth
          onClick={() => {
            const urlParams = new URLSearchParams(params);
            setSearchParams(urlParams);
            onClose();
          }}
        />
      }
      body={
        <div className="flex flex-col">
          <DebouncedSearch
            key={searchInputKey}
            debounceMs={0}
            placeholder={`${t('Find member')}...`}
            searchValue={searchQuery}
            onSearch={(newSearchValue) => {
              const newParams = { ...params };
              if (newSearchValue === searchQuery) {
                return;
              }
              newParams.page = 1;
              if (!newSearchValue) {
                delete newParams.search;
              } else {
                newParams.search = newSearchValue?.trim();
              }
              setParams(newParams);
            }}
          />
        </div>
      }
    />
  );
};

SuperadminMemberFiltersDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

SuperadminMemberFiltersDrawer.defaultProps = {
  isOpen: false,
  onClose: undefined,
};

export default SuperadminMemberFiltersDrawer;
