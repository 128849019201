import Breakpoint from '../../enums/Breakpoint';
import SuperadminMembersPageDesktop from '../../features/superadmin/SuperadminMembersPage/SuperadminMembersPageDesktop';
import SuperadminMembersPageMobile from '../../features/superadmin/SuperadminMembersPage/SuperadminMembersPageMobile';
import useWindowSize from '../../hooks/useWindowSize';

const Superadmins = () => {
  const { width } = useWindowSize();

  const isMobile = width < Breakpoint.LG;

  if (isMobile) {
    return <SuperadminMembersPageMobile />;
  }

  return <SuperadminMembersPageDesktop />;
};

export default Superadmins;
