import { useQuery } from '@tanstack/react-query';

import ErrorPage from '../../components/ErrorPage';
import Breakpoint from '../../enums/Breakpoint';
import MembersPageDesktop from '../../features/members/MyOrganisationMebersPage/MembersPageDesktop';
import MembersPageMobile from '../../features/members/MyOrganisationMebersPage/MembersPageMobile';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationMembers = () => {
  const { user } = useUser();
  const { width } = useWindowSize();
  const { fetch } = useFetch();
  const isMobile = width < Breakpoint.LG;

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${user.carrier.id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${user.carrier.id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (isMobile) {
    return (
      <MembersPageMobile organisation={organisation} isLoading={isPending} />
    );
  }
  return (
    <MembersPageDesktop organisation={organisation} isLoading={isPending} />
  );
};

export default MyOrganisationMembers;
