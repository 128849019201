import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import ShiftsCardList from '../../features/shifts/ShiftsCardList';
import ShiftsTable from '../../features/shifts/ShiftsTable';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';

const OrganisationShifts = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const { fetch } = useFetch();
  const { id } = useParams();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={`/organisations/${id}`}
        closeOnLocationChange={false}
        textPrimary={`${t('Shifts')} (${organisation?.shiftCount})`}
        textSecondary={organisation?.name}
        isLoading={isPending}
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource resourceUrl={`/carriers/${id}/shifts`}>
          {isDesktop ? <ShiftsTable /> : <ShiftsCardList />}
        </Resource>
      </Page.Content>
    </Page>
  );
};

export default OrganisationShifts;
