import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../components/Dropdown/Dropdown';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import UserRole from '../../../enums/UserRole';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import { useIsRole } from '../../../providers/UserProvider';

const TasksTableHeaderActions = ({
  onDataImportClick,
  onShowHideColumnsClick,
}) => {
  const { t } = useTranslation();
  const { enableSelection } = useListSelection();

  const isAdmin = useIsRole(UserRole.Admin);

  const menuItems = useMemo(() => {
    const items = [
      {
        icon: 'edit',
        iconClassName: 'text-grey-700',
        onClick: () => {
          enableSelection();
        },
        text: t('Bulk edit mode'),
      },
      {
        icon: 'visibilityOn',
        iconClassName: 'text-grey-700',
        onClick: onShowHideColumnsClick,
        text: t('Show/hide columns'),
      },
    ];
    if (isAdmin) {
      return [
        ...items,
        {
          icon: 'dataImport',
          iconClassName: 'text-grey-700',
          onClick: onDataImportClick,
          text: t('Manual data import'),
        },
      ];
    }

    return items;
  }, [enableSelection, isAdmin, onDataImportClick, onShowHideColumnsClick, t]);

  return (
    <div className="flex">
      <Dropdown menu={menuItems}>
        <div className="flex">
          <Tooltip placement="top" size="sm" text={t('Table Options')}>
            <IconButton
              icon="menu"
              iconSize="s"
              size="s"
              variant="ghostBlack"
            />
          </Tooltip>
        </div>
      </Dropdown>
    </div>
  );
};

TasksTableHeaderActions.propTypes = {
  onShowHideColumnsClick: PropTypes.func.isRequired,
  onDataImportClick: PropTypes.func.isRequired,
};

export default TasksTableHeaderActions;
