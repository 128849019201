import moment from 'moment/moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useCopyToClipboard from './useCopyToClipboard';
import useCustomToast from './useCustomToast';
import useTaskStatusTranslations from './useTaskStatusTranslations';
import useTaskTypeTranslations from './useTaskTypeTranslations';

const useCopyTaskInfoToClipboard = () => {
  const { t } = useTranslation();
  const { toastSuccess } = useCustomToast();
  const copyToClipboard = useCopyToClipboard();
  const taskTypeTranslations = useTaskTypeTranslations();
  const taskStatusTranslations = useTaskStatusTranslations();

  return useCallback(
    async (task) => {
      await copyToClipboard(
        t(
          `Task code: {{code}} / Task type: {{type}} / Task status: {{status}} / Address: {{address}} / Execution date: {{executionDate}} / Execution slot: {{timeFrom}}-{{timeTo}}`,
          {
            code: task.code,
            type: taskTypeTranslations[task.type],
            status: taskStatusTranslations[task.status],
            address: task.address,
            executionDate: moment(task.date).format('DD.MM.YYYY.'),
            timeFrom: moment(task.timeFrom).format('HH:mm'),
            timeTo: moment(task.timeTo).format('HH:mm'),
          },
        ),
      );
      toastSuccess(t('Task info copied to clipboard.'));
    },
    [
      copyToClipboard,
      t,
      taskStatusTranslations,
      taskTypeTranslations,
      toastSuccess,
    ],
  );
};

export default useCopyTaskInfoToClipboard;
