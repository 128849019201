import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../../../components/Select';
import SelectVariant from '../../../components/Select/SelectVariant';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';

const ShiftsSelect = (props) => {
  const { className, onChange, placeholder, value, variant, ...rest } = props;

  const { user } = useUser();
  const { carrier } = user;
  const { fetch } = useFetch();
  const { t } = useTranslation();

  const url = `/carriers/${carrier?.id}/shifts`;
  const { data: fetchedShifts, isPending } = useQuery({
    queryKey: [url],

    queryFn: async () => {
      const response = await fetch(url);
      return response.json();
    },
  });

  const shifts = useMemo(() => {
    if (fetchedShifts?.data) {
      return [...new Set(fetchedShifts?.data.map((item) => item.number))];
    }
    return [];
  }, [fetchedShifts?.data]);

  const options = useMemo(
    () =>
      shifts
        .map((shift) => ({
          label: `${t('Shift')} ${shift}`,
          value: String(shift),
        }))
        .sort((a, b) => a.value - b.value),
    [shifts, t],
  );

  return (
    <Select
      variant={variant}
      className={className}
      id="shift-select"
      isLoading={isPending}
      options={options}
      placeholder={placeholder || t('Shift')}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

ShiftsSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
};

ShiftsSelect.defaultProps = {
  className: undefined,
  onChange: undefined,
  placeholder: undefined,
  value: undefined,
  variant: SelectVariant.Default,
};

export default ShiftsSelect;
