import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';

import logo from '../../assets/images/logo-full.svg';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const ForgotPassword = () => {
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();

  const requestResetPasswordMutation = useMutation({
    mutationFn: (values) =>
      fetch('/users/request-reset-password', {
        body: { email: values.email },
        method: 'POST',
      }),
    onError: (error) => toastFetchError(error),
  });

  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const { handleSubmit } = methods;

  if (
    !requestResetPasswordMutation.isPending &&
    requestResetPasswordMutation.data
  ) {
    return <Navigate replace to="/forgot-password-success" />;
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center pt-18">
      <img
        alt="Urbify logo"
        className="h-[96px] w-[128px]"
        height="96"
        src={logo}
      />

      <div className="my-[60px] flex w-full justify-center px-4">
        <div className="w-full max-w-[480px] rounded-md bg-white px-4 py-5 shadow-elevation-200 sm:p-8">
          <FormProvider {...methods}>
            <form
              noValidate
              onSubmit={handleSubmit(requestResetPasswordMutation.mutate)}
            >
              <div className="flex flex-col gap-5">
                <p className="font-semibold">{t('Reset Password')}</p>
                <div className="flex flex-col gap-4">
                  <span className="block text-sm text-grey-700">
                    {t(
                      'Enter the e-mail address of your Urbify account, and we’ll send you the link to set a new password.',
                    )}
                  </span>
                  <FormInput
                    id="email"
                    label={t('E-mail Address')}
                    name="email"
                    placeholder={t('Enter your e-mail address')}
                    required
                    type="email"
                  />
                </div>
                <div className="border-b-2 border-grey-200" />
                <div className="flex flex-col items-center gap-3">
                  <Button
                    isFullWidth
                    text={t('Submit')}
                    type="submit"
                    variant="solidBlack"
                  />

                  <Link className="w-full text-sm font-medium" to="/login">
                    <Button
                      icon="chevronLeft"
                      iconSize="s"
                      isFullWidth
                      text={t('Back to Login')}
                      type="button"
                      variant="ghostBlack"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
