import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import IconButton from '../../components/IconButton';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationType from '../../enums/OrganisationType';
import UserRole from '../../enums/UserRole';
import BasicInformationCard from '../../features/organisations/BasicInformationCard';
import OrganisationDetailsHubsCard from '../../features/organisations/OrganisationDetailsHubsCard';
import OrganisationDetailsPlatformFeaturesCard from '../../features/organisations/OrganisationDetailsPlatformFeaturesCard';
import OrganisationDetailsShiftsCard from '../../features/organisations/OrganisationDetailsShiftsCard';
import OrganisationPerformance from '../../features/organisations/OrganisationPerformance';
import MembersCard from '../../features/shared/MembersCard';
import OrganisationDetailsSectionCard from '../../features/shared/OrganisationDetailsSectionCard';
import useFetch from '../../lib/api/hooks/useFetch';
import Allow from '../../lib/rbac/Allow';
import { useIsRole, useUser } from '../../providers/UserProvider';

const MyOrganisation = () => {
  const { t } = useTranslation();

  const isAdmin = useIsRole(UserRole.Admin);
  const { isGroceries, user } = useUser();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${user.carrier.id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${user.carrier.id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  const title =
    organisation?.type === OrganisationType.Carrier
      ? organisation?.name
      : organisation?.name?.concat(' (', organisation?.shortCode, ')');

  const getDetailsCardTitle = (mainTitle, count) => {
    const subTitle = ((count || count === 0) && `(${count})`) || '';

    return `${mainTitle} ${subTitle}`;
  };

  return (
    <Page>
      <Titlebar
        icon={<Icon className="h-5 w-5 text-primary-yellow" icon="domain" />}
        textPrimary={title}
        isLoading={isPending}
      />

      <Page.Content variant="grey">
        <div className="flex flex-col gap-4">
          {!isGroceries &&
            (isPending ? (
              <Skeleton wrapper={Card} height={140} />
            ) : (
              <OrganisationPerformance
                performance={organisation?.performance}
              />
            ))}

          <div
            className={cn(
              'grid gap-4',
              (isGroceries || !isAdmin) && 'xl:grid-cols-2',
              !isGroceries && isAdmin && 'xl:grid-cols-3',
            )}
          >
            <MembersCard organisation={organisation} />
            <OrganisationDetailsHubsCard hubCount={organisation?.hubCount} />
            {!isGroceries && isAdmin && (
              <OrganisationDetailsShiftsCard
                shiftCount={organisation?.shiftCount}
              />
            )}
          </div>

          {!isGroceries && (
            <Allow roles={[UserRole.Admin]}>
              <OrganisationDetailsSectionCard
                noWrap
                title={
                  <div className="flex items-center gap-4">
                    <Icon className="h-5 w-5" icon="settings" />
                    <div className="font-semibold">{t('Routing')}</div>
                  </div>
                }
                topRightContent={
                  <Button
                    as={Link}
                    className="w-fit"
                    iconRight="chevronRight"
                    iconSize="s"
                    text={t('Configuration')}
                    to="routing"
                    variant="outlineBlue"
                  />
                }
              />
            </Allow>
          )}

          <div>
            {isPending ? (
              <Skeleton wrapper={Card} height={214} />
            ) : (
              <BasicInformationCard
                organisation={organisation}
                topRightContent={
                  isAdmin ? (
                    <Button
                      as={Link}
                      className="w-full sm:w-fit"
                      icon="edit"
                      state={{
                        backLink: `/organisations/${organisation.id}`,
                      }}
                      text={t('Edit Organisation')}
                      to="edit"
                      variant="outlineBlack"
                    />
                  ) : null
                }
              />
            )}
          </div>

          <div className="grid gap-4 xl:grid-cols-2">
            <OrganisationDetailsSectionCard
              noWrap
              title={getDetailsCardTitle(
                t('Subcarriers'),
                organisation?.subcarrierCount,
              )}
              topRightContent={
                <IconButton
                  as={Link}
                  icon="chevronRight"
                  iconSize="xs"
                  size="m"
                  to="subcarriers"
                  variant="outlineBlack"
                />
              }
            />

            <OrganisationDetailsSectionCard
              noWrap
              title={getDetailsCardTitle(
                t('Clients'),
                organisation?.clients?.length,
              )}
              topRightContent={
                <IconButton
                  as={Link}
                  icon="chevronRight"
                  iconSize="xs"
                  size="m"
                  to="clients"
                  variant="outlineBlack"
                />
              }
            />
          </div>

          <Allow roles={[UserRole.Admin, UserRole.Superadmin]}>
            {isPending ? (
              <Skeleton wrapper={Card} height={160} />
            ) : (
              <OrganisationDetailsSectionCard title={t('Settings')}>
                <OrganisationDetailsPlatformFeaturesCard
                  organisationId={organisation?.id}
                  organisationType={OrganisationType.Carrier}
                />
              </OrganisationDetailsSectionCard>
            )}
          </Allow>
        </div>
      </Page.Content>
    </Page>
  );
};

export default MyOrganisation;
