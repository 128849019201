import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useFormContext } from 'react-hook-form';

import Collapse from '../../../components/Collapse';
import Icon from '../../../components/Icon';
import useDeliveryUnitStatusTranslations from '../../../hooks/useDeliveryUnitStatusTranslations';
import UnitProgressIcon from '../UnitProgressIcon/UnitProgressIcon';

const ExpandableStatusItem = (props) => {
  const {
    children,
    collapsedChildren,
    currentUnitStatus,
    isCurrent,
    isTourActiveOrCompleted,
    isUnitRouted,
    item,
  } = props;
  const {
    formState: { isDirty },
    watch,
  } = useFormContext();
  const status = watch('status');
  const isSelected = status === item.value;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();

  useEffect(() => {
    if (isCurrent && isDirty) {
      setIsDropdownOpen(false);
    }
  }, [isCurrent, isDirty]);

  return (
    <div>
      <div
        className={cn(
          'rounded-md border px-3 py-2.5 transition-colors',
          isSelected && !isDropdownOpen
            ? 'border-grey-500'
            : 'border-transparent',
          isCurrent && 'cursor-default',
          (!isCurrent || isDirty) && 'hover:bg-grey-100 cursor-pointer',
        )}
        data-test="status-item"
        data-test-status={item.value}
        data-test-status-is-selected={isCurrent}
        onClick={() => {
          if (!isCurrent || isDirty) {
            setIsDropdownOpen(!isDropdownOpen);
          }
        }}
      >
        <div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1 text-sm">
              <div className="font-medium text-primary-dark flex items-center gap-2">
                <UnitProgressIcon
                  unitStatus={item.value}
                  isUnitRouted={isUnitRouted}
                  currentUnitStatus={currentUnitStatus}
                  isTourActiveOrCompleted={isTourActiveOrCompleted}
                />
                <span>{deliveryUnitStatusTranslations[item.value]}</span>
              </div>
            </div>
            <div
              className={cn(
                'transition-all',
                isDropdownOpen ? 'rotate-180' : 'rotate-0',
              )}
            >
              {(!isCurrent || isDirty) && (
                <Icon className="h-2.5 w-2.5" icon="chevronDown" />
              )}
              {isCurrent && !isDirty && !isDropdownOpen && (
                <Icon className="h-4 w-4 text-ui-blue" icon="checkmark" />
              )}
            </div>
          </div>

          {isSelected && children && (
            <AnimateHeight duration={300} height={isDropdownOpen ? 0 : 'auto'}>
              <div className="my-2.5 h-0 w-full border-t-[1px] border-grey-200" />
              {children}
            </AnimateHeight>
          )}
        </div>
      </div>

      <Collapse isOpen={isDropdownOpen}>{collapsedChildren}</Collapse>
    </div>
  );
};

ExpandableStatusItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  isCurrent: PropTypes.bool,
  children: PropTypes.node,
  isUnitRouted: PropTypes.bool,
  collapsedChildren: PropTypes.node,
  currentUnitStatus: PropTypes.string,
  isTourActiveOrCompleted: PropTypes.bool,
};

ExpandableStatusItem.defaultProps = {
  item: undefined,
  isCurrent: false,
  children: undefined,
  isUnitRouted: false,
  collapsedChildren: undefined,
  currentUnitStatus: undefined,
  isTourActiveOrCompleted: false,
};

export default ExpandableStatusItem;
