import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce, useMount } from 'react-use';

import useFetch from '../lib/api/hooks/useFetch';
import useInfiniteScrollQuery from './useInfiniteScrollQuery';

const useInfiniteSelect = ({
  isMountedValueQueryEnabled = true,
  itemsPerPage,
  searchKey,
  singleItemUrl,
  transformOptionFn,
  url,
  value,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [mountedValue, setMountedValue] = useState(undefined);

  useMount(() => {
    setMountedValue(value);
  });

  const { fetch } = useFetch();

  const { data: mountedValueData, isFetching: mountedValueIsFetching } =
    useQuery({
      queryKey: [singleItemUrl, mountedValue],

      queryFn: async () => {
        const response = await fetch(`${singleItemUrl}${mountedValue}`, {
          method: 'GET',
        });
        return response.json();
      },
      enabled:
        !!mountedValue &&
        typeof singleItemUrl !== 'undefined' &&
        isMountedValueQueryEnabled,
    });

  const params = useMemo(
    () => ({ [searchKey]: debouncedSearchValue }),
    [debouncedSearchValue, searchKey],
  );

  const { flattenedData, isFetching, onBottomReached } = useInfiniteScrollQuery(
    {
      itemsPerPage,
      params,
      url,
    },
  );

  const [, cancel] = useDebounce(
    () => {
      setDebouncedSearchValue(searchValue.trim());
    },
    300,
    [searchValue],
  );

  useEffect(
    () => () => {
      if (cancel) {
        cancel();
      }
    },
    [cancel],
  );

  const mergedData = useMemo(() => {
    const isMountedValueInOptionsArray = flattenedData.find(
      (item) => item.id === mountedValueData?.data?.id,
    );

    // if the selected value is not yet loaded into options array, and no filtering is enabled,
    // ...then add the mounted value to the array to prepare it as option for display
    if (
      !isMountedValueInOptionsArray &&
      mountedValueData?.data &&
      !debouncedSearchValue
    ) {
      return [...flattenedData, mountedValueData?.data];
    }
    return flattenedData;
  }, [flattenedData, mountedValueData?.data, debouncedSearchValue]);

  const options = useMemo(
    () => mergedData.map(transformOptionFn),
    [mergedData, transformOptionFn],
  );

  return {
    options,
    isFetching,
    onBottomReached,
    setSearchValue,
    mountedValueIsFetching,
  };
};
export default useInfiniteSelect;
