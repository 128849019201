import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import DeleteOrganisationModal from '../../shared/DeleteOrganisationModal';
import OrganisationDetailsSettingsCard from '../OrganisationDetailsSettingsCard';

const DeleteOrganisation = ({ organisation }) => {
  const { t } = useTranslation();
  const { toastSuccess } = useCustomToast();
  const [isDeleteOrganisationModalOpen, setIsDeleteOrganisationModalOpen] =
    useState(false);

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const deleteOrganisation = useMutation({
    mutationFn: () =>
      fetch(`/carriers/${organisation.id}`, {
        method: 'DELETE',
      }),
    onError: (error) => toastFetchError(error),
    onSuccess: () => {
      toastSuccess(t('Organisation is successfully deleted.'));
    },
  });

  if (deleteOrganisation.isSuccess) {
    return <Navigate replace to="/organisations" />;
  }

  return (
    <>
      <DeleteOrganisationModal
        handleDelete={deleteOrganisation.mutate}
        isOpen={isDeleteOrganisationModalOpen}
        title={t('Delete Organisation?')}
        onClose={() => setIsDeleteOrganisationModalOpen(false)}
      />
      <OrganisationDetailsSettingsCard
        text={
          <div className="flex items-center gap-2">
            <Icon className="h-4 w-4 text-grey-500" icon="warning" />
            <span>
              {t(
                'Deleting the Organisation is permanent, all Organisation users will lose access to the dashboard.',
              )}
            </span>
          </div>
        }
        title={t('Delete Organisation')}
      >
        <Button
          className="w-full"
          icon="delete"
          text={t('Delete Organisation')}
          variant="outlineRed"
          onClick={() => setIsDeleteOrganisationModalOpen(true)}
        />
      </OrganisationDetailsSettingsCard>
    </>
  );
};

DeleteOrganisation.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
  }),
};

DeleteOrganisation.defaultProps = {
  organisation: undefined,
};

export default DeleteOrganisation;
