import ActivateUserGuestScreen from '../../features/activate-user/ActivateUserGuestScreen';
import ActivateUserLoggedInScreen from '../../features/activate-user/ActivateUserLoggedInScreen';
import { useUser } from '../../providers/UserProvider';

const ActivateUser = () => {
  const { user } = useUser();

  if (user) {
    return <ActivateUserLoggedInScreen />;
  }

  return <ActivateUserGuestScreen />;
};

export default ActivateUser;
