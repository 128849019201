import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { Card } from '../../../components/Card';
import ErrorPage from '../../../components/ErrorPage';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import useFetch from '../../../lib/api/hooks/useFetch';
import DeliveryOption from '../DeliveryOption/DeliveryOption';
import AlternativeDeliveryOptionDrawer from './AlternativeDeliveryOptionDrawer';
import DirectDeliveryOptionDrawer from './DirectDeliveryOptionDrawer';

const AlternativeDeliveryOptions = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [openedDeliveryOptionId, setOpenedDeliveryOptionId] = useState(null);
  const [isDirectDeliveryDrawerOpened, setIsDirectDeliveryDrawerOpened] =
    useState(false);

  const { fetch } = useFetch();
  const {
    data,
    error,
    isError,
    isPending: isClientPending,
  } = useQuery({
    queryKey: [`/clients/`, id],

    queryFn: async () => {
      const response = await fetch(`/clients/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const {
    data: carrierDeliveryOptionsData,
    error: carrierDeliveryOptionsError,
    isError: isCarrierDeliveryOptionsError,
    isPending: isCarrierDeliveryOptionsPending,
  } = useQuery({
    queryKey: ['/delivery-options'],

    queryFn: async () => {
      const response = await fetch('/delivery-options', {
        method: 'GET',
      });
      return response.json();
    },
  });

  const clientDeliveryOptionsUrl = `/clients/${id}/delivery-options`;

  const {
    data: clientDeliveryOptionsData,
    error: clientDeliveryOptionsError,
    isError: isClientDeliveryOptionsError,
    isPending: isClientDeliveryOptionsPending,
  } = useQuery({
    queryKey: [clientDeliveryOptionsUrl],

    queryFn: async () => {
      const response = await fetch(clientDeliveryOptionsUrl, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const client = data?.data || null;

  const carrierDeliveryOptions = carrierDeliveryOptionsData?.data || null;
  const clientDeliveryOptions = clientDeliveryOptionsData?.data || null;

  const deliveryOptions = useMemo(() => {
    if (!carrierDeliveryOptions || carrierDeliveryOptions?.length === 0) {
      return [];
    }

    return carrierDeliveryOptions.map((option) => {
      const foundOption = clientDeliveryOptions?.find(
        (clientOption) => clientOption.deliveryOption.id === option.id,
      );

      if (foundOption) {
        return {
          ...foundOption,
          id: option.id,
          code: option.code,
          enabled: true,
        };
      }

      return {
        ...option,
        enabled: false,
      };
    });
  }, [clientDeliveryOptions, carrierDeliveryOptions]);

  const selectedDeliveryOption = useMemo(
    () =>
      deliveryOptions?.find((option) => option.id === openedDeliveryOptionId),
    [deliveryOptions, openedDeliveryOptionId],
  );

  if (
    isError ||
    isClientDeliveryOptionsError ||
    isCarrierDeliveryOptionsError
  ) {
    return (
      <ErrorPage
        error={
          error || clientDeliveryOptionsError || carrierDeliveryOptionsError
        }
      />
    );
  }

  return (
    <>
      <AlternativeDeliveryOptionDrawer
        clientId={id}
        deliveryOption={selectedDeliveryOption}
        isOpen={!!selectedDeliveryOption}
        onClose={() => setOpenedDeliveryOptionId(null)}
      />
      {client && (
        <DirectDeliveryOptionDrawer
          clientId={id}
          clientData={client}
          isOpen={isDirectDeliveryDrawerOpened}
          onClose={() => setIsDirectDeliveryDrawerOpened(false)}
        />
      )}
      <Page>
        <Titlebar
          backLink={`/clients/${id}`}
          textPrimary={t('Delivery Options Configuration')}
          textSecondary={client?.name}
          isLoading={isClientPending}
        />
        <Page.Content variant="grey">
          {isClientDeliveryOptionsPending || isCarrierDeliveryOptionsPending ? (
            <div className="grid gap-4">
              <Skeleton wrapper={Card} height={84} />
              <Skeleton wrapper={Card} height={84} />
              <Skeleton wrapper={Card} height={84} />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <Card>
                <h2 className="font-semibold text-base py-2 mb-4">
                  {t('Direct Delivery')}
                </h2>

                <div className="flex flex-col gap-4">
                  <DeliveryOption
                    showEnabledState={false}
                    key="customer"
                    label={t('Customer')}
                    deliveryOption={{
                      id: '',
                      code: 'customer',
                    }}
                    onButtonClick={() => setIsDirectDeliveryDrawerOpened(true)}
                  />
                </div>
              </Card>

              <Card>
                <h2 className="font-semibold text-base my-2">
                  {t('Alternative Delivery Options')}
                </h2>
                <div className="my-4 text-primary-dark text-base">
                  {t(
                    'Enable the alternative safe delivery options for Drivers and determine whether options require customer consent',
                  )}
                </div>
                <div
                  className="flex flex-col gap-4"
                  data-test="delivery-options"
                >
                  {deliveryOptions?.map((option) => (
                    <DeliveryOption
                      key={option.id}
                      deliveryOption={option}
                      onButtonClick={(value) =>
                        setOpenedDeliveryOptionId(value)
                      }
                    />
                  ))}
                </div>
              </Card>
            </div>
          )}
        </Page.Content>
      </Page>
    </>
  );
};

export default AlternativeDeliveryOptions;
