import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Drawer from '../../../components/Drawer';
import DrawerTabs from '../../../components/Drawer/DrawerTabs';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useFetch from '../../../lib/api/hooks/useFetch';
import UnitDetailsEventHistory from './UnitDetailsEventHistory';
import UnitDetailsGeneralInfo from './UnitDetailsGeneralInfo';

const Loader = () => (
  <div className="relative left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2">
    <LoadingSpinner className="text-grey-700" />
  </div>
);

const UnitDetailsDrawer = (props) => {
  const { isOpen, onClose, showBackButton, transparentBackdrop, unitId } =
    props;
  const { t } = useTranslation();

  const { fetch } = useFetch();
  const { data, isFetching } = useQuery({
    // different query key, because of unit status drawer
    queryKey: [`/units/${unitId}`, 'details'],

    queryFn: async () => {
      const response = await fetch(`/units/${unitId}`, {
        method: 'GET',
      });
      return response.json();
    },
    enabled: !!unitId && isOpen,
  });

  const unit = data?.data;
  const ref = useRef(null);

  return (
    <Drawer
      ref={ref}
      isOpen={isOpen}
      onClose={onClose}
      bodyPadding={false}
      showBackButton={showBackButton}
      transparentBackdrop={transparentBackdrop}
      bodyClassname="flex flex-col"
      body={
        <DrawerTabs
          drawerBodyRef={ref}
          tabs={[
            {
              label: t('General Info'),
              content: isFetching ? (
                <Loader />
              ) : (
                <UnitDetailsGeneralInfo unit={unit} />
              ),
            },
            {
              label: t('Event History'),
              content: isFetching ? (
                <Loader />
              ) : (
                <UnitDetailsEventHistory unit={unit} />
              ),
            },
          ]}
        />
      }
      header={
        <span className="flex items-center gap-2">{t('Unit Details')}</span>
      }
    />
  );
};

UnitDetailsDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  unitId: PropTypes.string,
  showBackButton: PropTypes.bool,
  transparentBackdrop: PropTypes.bool,
};

UnitDetailsDrawer.defaultProps = {
  unitId: undefined,
  showBackButton: false,
  transparentBackdrop: false,
};

export default UnitDetailsDrawer;
