import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import FormCheckbox from '../../../components/FormCheckbox';
import FormSelect from '../../../components/FormSelect';
import DeliveryProof from '../../../enums/DeliveryProof';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';

const DirectDeliveryOptionDrawer = ({
  clientData,
  clientId,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const queryClient = useQueryClient();
  const { fetch } = useFetch();

  const mutation = useMutation({
    mutationFn: async (values) => {
      const {
        address,
        bundling,
        frozenGoods,
        logoZplCode,
        name,
        publicName,
        routeCreatedParcels,
      } = clientData;
      const body = {
        address,
        bundling,
        frozenGoods,
        name,
        logoZplCode,
        publicName,
        routeCreatedParcels,
        directDeliveryProof: values.directDeliveryProof,
        directDeliveryNoteRequired: values.directDeliveryNoteRequired,
      };
      return fetch(`/clients/${clientId}`, {
        body,
        method: 'PATCH',
      });
    },
    onError: (error) => {
      toastFetchError(error);
      onClose();
    },
    onSuccess: () => {
      toastSuccess(t('Delivery option configured.'));
      queryClient.invalidateQueries([`/clients/`, clientId]);
      onClose();
    },
  });

  const methods = useForm({
    defaultValues: {
      directDeliveryNoteRequired:
        clientData.directDeliveryNoteRequired || false,
      directDeliveryProof:
        clientData.directDeliveryProof || DeliveryProof.Signature,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const { handleSubmit, reset } = methods;

  return (
    <FormProvider {...methods}>
      <Drawer
        onOpen={() => {
          reset({
            directDeliveryNoteRequired:
              clientData.directDeliveryNoteRequired || false,
            directDeliveryProof:
              clientData.directDeliveryProof || DeliveryProof.Signature,
          });
        }}
        data-test="delivery-option-drawer"
        body={
          <form>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col text-sm">
                <h2 className="font-semibold text-base mb-5">{t('Setup')}</h2>

                <div>
                  <FormSelect
                    id="proof"
                    label={t('Delivery Proof')}
                    name="directDeliveryProof"
                    required
                    options={[
                      {
                        value: DeliveryProof.Signature,
                        label: t('Signature'),
                      },
                      {
                        value: DeliveryProof.Photo,
                        label: t('Photo'),
                      },
                    ]}
                  />
                </div>

                <Alert
                  className="mt-2"
                  fontWeight="normal"
                  variant="info"
                  message={t(
                    'Proof represents a mandatory action that the Driver needs to perform to save proof of the delivery in the database.',
                  )}
                />

                <div className="mt-4">
                  <FormCheckbox
                    name="directDeliveryNoteRequired"
                    label={t(
                      'Details of completed delivery required (Driver note)',
                    )}
                  />
                </div>

                <Alert
                  className="mt-2"
                  fontWeight="normal"
                  variant="info"
                  message={t(
                    "A mandatory Driver note for Drivers to enter delivery details, for example, if delivered to neighbour - neighbour's name.",
                  )}
                />
              </div>
            </div>
          </form>
        }
        header={t('Customer')}
        isOpen={isOpen}
        onClose={onClose}
        footer={
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={onClose}
            />
            <Button
              type="submit"
              data-test="modal-button-action"
              text={t('Save')}
              variant="solidBlue"
              onClick={handleSubmit(mutation.mutate)}
            />
          </div>
        }
      />
    </FormProvider>
  );
};

DirectDeliveryOptionDrawer.propTypes = {
  clientId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clientData: PropTypes.shape({
    name: PropTypes.string,
    publicName: PropTypes.string,
    logoZplCode: PropTypes.string,
    address: PropTypes.string,
    bundling: PropTypes.bool,
    directDeliveryProof: PropTypes.oneOf(Object.values(DeliveryProof)),
    directDeliveryNoteRequired: PropTypes.bool,
    frozenGoods: PropTypes.bool,
    routeCreatedParcels: PropTypes.bool,
  }).isRequired,
};

DirectDeliveryOptionDrawer.defaultProps = {};

export default DirectDeliveryOptionDrawer;
