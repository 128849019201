import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

const ExitEditModeModal = ({ isOpen, onClose, onExitClick }) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {t(
            "Items are still selected. Selection won't be saved for the next bulk edit.",
          )}
        </p>
      }
      footer={
        <div
          className="grid grid-cols-1 gap-4 sm:grid-cols-2"
          data-test="exit-edit-mode-modal"
        >
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Exit')}
            variant="solidRed"
            onClick={() => {
              onExitClick();
            }}
          />
        </div>
      }
      header={<span>{t('Exit Bulk Edit Mode?')}</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

ExitEditModeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExitClick: PropTypes.func,
};

ExitEditModeModal.defaultProps = {
  onExitClick: undefined,
};

export default ExitEditModeModal;
