import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import useLogout from '../../hooks/useLogout';

const ActivateUserLoggedInScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logout = useLogout();
  const location = useLocation();

  return (
    <div>
      <Modal
        isOpen
        header={t('Log Out From The Current Account?')}
        body={
          <p>
            {t(
              'To open the account activation link, you need to log out from the current account. Note that if you have multiple dashboard tabs open, all of them will be logged out.',
            )}
          </p>
        }
        footer={
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Button
              onClick={() => {
                navigate('/');
              }}
              variant="outlineBlack"
              text={t('Go to Main Page')}
            />
            <Button
              onClick={() => {
                logout({
                  navigateUrl: location.pathname,
                  navigateOptions: {
                    replace: true,
                  },
                });
              }}
              variant="solidBlue"
              text={t('Log Out')}
            />
          </div>
        }
      />
    </div>
  );
};

export default ActivateUserLoggedInScreen;
