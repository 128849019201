import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationForm from '../../features/shared/OrganisationForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const MyOrganisationNewCustomer = () => {
  const { toastSuccess } = useCustomToast();
  const navigate = useNavigate();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { t } = useTranslation();

  const [isFormDirty, setIsFormDirty] = useState(false);

  const mutation = useMutation({
    mutationFn: (values) => {
      const body = {
        address: values.address,
        bundling: false,
        name: values.name,
        publicName: values.publicName,
        shortCode: values.shortCode,
      };

      return fetch('/clients', {
        body,
        method: 'POST',
      });
    },
    onError: toastFetchError,
    onSuccess: () => {
      setIsFormDirty(false);
      toastSuccess(t('Client created.'));
    },
  });

  const shouldBlock = useMemo(() => {
    if (mutation?.data?.ok) {
      return false;
    }
    return isFormDirty;
  }, [mutation?.data?.ok, isFormDirty]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate(`/my-organisation/clients`, {
        replace: true,
      });
    }
  }, [mutation.isSuccess, navigate]);

  const title = t('New Client');

  return (
    <>
      <CancelPrompt
        title={t('Cancel Creating Client?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        onExitClick={() => blocker.proceed()}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="plus" />}
          textPrimary={title}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              disabled={mutation.isPending}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate(`/my-organisation/clients`)}
            />
          }
        />

        <Page.Content centerContent variant="grey">
          <div className="w-full max-w-[480px]">
            <Card className="pt-6 sm:p-8">
              <OrganisationForm
                errorCode={mutation?.error?.data?.errorCode}
                errors={mutation?.error?.data?.errors}
                isClient
                isSubmitting={mutation.isPending}
                onCancel={() => navigate('/my-organisation/clients')}
                onIsDirtyChange={setIsFormDirty}
                onSubmit={mutation.mutate}
              />
            </Card>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default MyOrganisationNewCustomer;
