import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import MemberStatusType from '../../../enums/MemberStatusType';

const ChangeMemberStatusConfirmationModal = ({
  handleChange,
  isOpen,
  memberStatus,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {memberStatus === MemberStatusType.Active
            ? t(
                'This action will set the Member to inactive. The Member will remain visible in the Members list but won’t be available for actions like assigning Tours. You can reactivate the Member at any time.',
              )
            : t(
                'This action will set the Member to active. The Member will be visible and available for actions like assigning Tours.',
              )}
        </p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={
              memberStatus === MemberStatusType.Active
                ? t('Deactivate')
                : t('Activate')
            }
            variant={
              memberStatus === MemberStatusType.Active
                ? 'solidRed'
                : 'solidBlue'
            }
            onClick={() => {
              onClose();
              handleChange();
            }}
          />
        </div>
      }
      header={
        <span>
          {memberStatus === MemberStatusType.Active
            ? t('Deactivate Member?')
            : t('Activate Member?')}
        </span>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

ChangeMemberStatusConfirmationModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  memberStatus: PropTypes.oneOf(Object.values(MemberStatusType)),
};

ChangeMemberStatusConfirmationModal.defaultProps = {
  memberStatus: undefined,
};

export default ChangeMemberStatusConfirmationModal;
