import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import HubsCardList from '../../features/hubs/HubsCardList';
import HubsTable from '../../features/hubs/HubsTable';
import HubFilters from '../../features/shared/HubFilters';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';

const OrganisationHubs = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const [searchParams] = useSearchParams({});

  const nameQuery = searchParams.get('name') || '';
  const clientIdQuery = searchParams.get('clientId') || '';
  const hasFiltersApplied = !!(clientIdQuery || nameQuery);

  const params = useMemo(
    () => ({
      clientId: clientIdQuery,
      name: nameQuery,
    }),
    [clientIdQuery, nameQuery],
  );

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={`/organisations/${id}`}
        isActive={hasFiltersApplied}
        menu={
          <HubFilters carrierName={organisation?.name} organisationId={id} />
        }
        isLoading={isPending}
        textPrimary={`${t('Hubs')} (${organisation?.hubCount})`}
        textSecondary={organisation?.name}
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource params={params} resourceUrl={`/carriers/${id}/hubs`}>
          {isDesktop ? <HubsTable /> : <HubsCardList />}
        </Resource>
      </Page.Content>
    </Page>
  );
};

export default OrganisationHubs;
