import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../../../components/Select';
import SelectVariant from '../../../components/Select/SelectVariant';
import TourStatusFilter from '../../../enums/TourStatusFilter';

const TourStatusSelect = (props) => {
  const { onChange, value, variant } = props;

  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        label: t('Created'),
        value: TourStatusFilter.Created,
      },
      {
        label: t('Not assigned'),
        value: TourStatusFilter.NotAssignedDriver,
      },
      {
        label: t('Pending'),
        value: TourStatusFilter.Pending,
      },
      {
        label: t('Active'),
        value: TourStatusFilter.Active,
      },
      {
        label: t('Completed'),
        value: TourStatusFilter.Completed,
      },
      {
        label: t('Cancelled'),
        value: TourStatusFilter.Canceled,
      },
    ],
    [t],
  );

  return (
    <Select
      variant={variant}
      id="tour-status-select"
      options={options}
      placeholder={t('Status')}
      required={false}
      value={value}
      onChange={onChange}
    />
  );
};

TourStatusSelect.propTypes = {
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

TourStatusSelect.defaultProps = {
  variant: undefined,
  onChange: undefined,
  value: undefined,
};

export default TourStatusSelect;
