import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';
import Breakpoint from '../../enums/Breakpoint';
import OrganisationMembersPageDesktop from '../../features/organisations/OrganisationMembersPage/OrganisationMembersPageDesktop';
import OrganisationMembersPageMobile from '../../features/organisations/OrganisationMembersPage/OrganisationMembersPageMobile';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';

const OrganisationMembers = () => {
  const { id } = useParams();
  const { width } = useWindowSize();
  const { fetch } = useFetch();
  const isMobile = width < Breakpoint.LG;

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (isMobile) {
    return (
      <OrganisationMembersPageMobile
        organisation={organisation}
        isLoading={isPending}
      />
    );
  }
  return (
    <OrganisationMembersPageDesktop
      organisation={organisation}
      isLoading={isPending}
    />
  );
};

export default OrganisationMembers;
