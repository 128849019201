import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Field from './Field';

const UnitsInput = (props) => {
  const { name } = props;

  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const unitCode = watch(name);

  const { append, fields, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <div className="flex flex-col gap-4">
      {fields.map((field, index) => (
        <Field
          name={name}
          index={index}
          id={field.id}
          key={field.id}
          onRemoveClick={() => remove(index)}
        />
      ))}
      <div>
        <Button
          text={t('Add More')}
          variant="outlineBlack"
          size="s"
          iconSize="s"
          icon="plus"
          disabled={
            (errors[name] && errors[name].length > 0) ||
            unitCode.some((unit) => unit.reference === '')
          }
          onClick={() => {
            append({ reference: '' });
          }}
        />
      </div>
    </div>
  );
};

UnitsInput.propTypes = {
  name: PropTypes.string,
};

UnitsInput.defaultProps = {
  name: '',
};

export default UnitsInput;
