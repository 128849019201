import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import MemberSortType from '../enums/MemberSortType';

const useMembersPageSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hubIdQuery = searchParams.get('hubId') || '';
  const searchQuery = searchParams.get('search') || '';
  const roleQuery = searchParams.get('role') || '';
  const statusQuery = searchParams.get('status') || '';
  const subcarrierIdQuery = searchParams.get('subcarrierId') || '';

  const sortTypeQuery = searchParams.get('sort');

  const currentSortType = useMemo(() => {
    if (sortTypeQuery === MemberSortType.EarliestCreated) {
      return MemberSortType.EarliestCreated;
    }
    if (sortTypeQuery === MemberSortType.LastCreated) {
      return MemberSortType.LastCreated;
    }
    if (sortTypeQuery === MemberSortType.RatingBadToExcellent) {
      return MemberSortType.RatingBadToExcellent;
    }

    return MemberSortType.LastActive;
  }, [sortTypeQuery]);

  const isFilterApplied = useMemo(
    () =>
      !!hubIdQuery ||
      !!searchQuery ||
      !!roleQuery ||
      !!subcarrierIdQuery ||
      !!statusQuery,
    [hubIdQuery, searchQuery, roleQuery, subcarrierIdQuery, statusQuery],
  );

  const allParams = {
    hubId: hubIdQuery,
    role: roleQuery,
    subcarrierId: subcarrierIdQuery,
    status: statusQuery,
    ...(searchQuery && { name: searchQuery }),
    sort: currentSortType,
  };

  return {
    hubIdQuery,
    subcarrierIdQuery,
    currentSortType,
    searchParams,
    roleQuery,
    setSearchParams,
    searchQuery,
    allParams,
    isFilterApplied,
    statusQuery,
  };
};

export default useMembersPageSearchParams;
