import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import InputLayout from '../../components/InputLayout';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import UserRole from '../../enums/UserRole';
import LanguagePicker from '../../features/my-account/LanguagePicker';
import MemberBadge from '../../features/shared/MemberBadge';
import MemberOrganisationBadge from '../../features/shared/MemberOrganisationBadge';
import useFetch from '../../lib/api/hooks/useFetch';
import { useIsRole } from '../../providers/UserProvider';

const MyAccount = () => {
  const { fetch } = useFetch();

  const {
    data: fetchedUser,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: ['/users/self'],

    queryFn: async () => {
      const response = await fetch('/users/self');
      return response.json();
    },
  });

  const user = fetchedUser?.data;

  const { t } = useTranslation();

  const title = t('My Account');

  const isSuperadmin = useIsRole([UserRole.Superadmin]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar textPrimary={title} />
      <Page.Content variant="grey">
        {isPending ? (
          <div className="grid gap-4">
            <Skeleton wrapper={Card} height={156} />
            <Skeleton wrapper={Card} height={98} />
            <Skeleton wrapper={Card} height={98} />
            <Skeleton wrapper={Card} height={98} />
          </div>
        ) : (
          <div className="grid gap-4">
            <Card>
              <h3 className="mb-6 mt-2 font-semibold">
                {t('Basic Information')}
              </h3>
              <div className="flex flex-col gap-4">
                <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                  <div className="flex-1">
                    <div className="mb-1.5 text-xs text-grey-700">
                      {t('First Name')}
                    </div>
                    <div className="text-sm">{user?.firstName}</div>
                  </div>
                  <div className="flex-1">
                    <div className="mb-1.5 text-xs text-grey-700">
                      {t('Last Name')}
                    </div>
                    <div className="text-sm">{user?.lastName}</div>
                  </div>
                </div>
                <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                  <div className="flex-1">
                    <div className="mb-1.5 text-xs text-grey-700">
                      {t('Phone Number')}
                    </div>
                    <div className="text-sm">{user?.phoneNumber}</div>
                  </div>
                  <div className="flex-1">
                    <div className="mb-1.5 text-xs text-grey-700">
                      {t('E-mail')}
                    </div>
                    <div className="text-sm">{user?.email}</div>
                  </div>
                </div>
              </div>
            </Card>
            {!isSuperadmin && (
              <>
                <Card>
                  <h3 className="mb-6 mt-2 font-semibold">
                    {t('Organisation')}
                  </h3>
                  <div className="flex flex-col gap-4">
                    <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                      <div className="flex-1">
                        <div className="mb-1.5 text-xs text-grey-700">
                          {t('Name')}
                        </div>
                        <div className="text-sm">
                          {user?.subcarrier
                            ? `${user.subcarrier?.name} (${user.subcarrier?.shortCode})`
                            : user?.carrier?.name}
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="mb-1.5 text-xs text-grey-700">
                          {t('Type')}
                        </div>
                        <div className="text-sm">
                          <MemberOrganisationBadge user={user} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card>
                  <h3 className="mb-6 mt-2 font-semibold">{t('Setup')}</h3>
                  <div className="flex flex-col gap-4">
                    <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                      <div className="flex-1">
                        <div className="mb-1.5 text-xs text-grey-700">
                          {t('Role')}
                        </div>
                        <MemberBadge memberRole={user.role} size="s" />
                      </div>
                      <div className="flex-1">
                        <div className="mb-1.5 text-xs text-grey-700">
                          {t('Hub')}
                        </div>
                        <div className="text-sm">
                          {user?.hub?.name ? user?.hub?.name : '/'}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            )}
            <Card>
              <h3 className="pb-6 pt-2 font-semibold">{t('Settings')}</h3>

              <div className="w-full md:w-1/2 xl:w-1/4">
                <InputLayout label={t('App Language')}>
                  <LanguagePicker />
                </InputLayout>
              </div>
            </Card>
          </div>
        )}
      </Page.Content>
    </Page>
  );
};

export default MyAccount;
