import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BlockNavigation from '../../../components/BlockNavigation';
import Button from '../../../components/Button';
import CancelPrompt from '../../../components/CancelPrompt';
import Drawer from '../../../components/Drawer';
import FormCheckbox from '../../../components/FormCheckbox';
import FormDatePicker from '../../../components/FormDatePicker';
import FormInput from '../../../components/FormInput';
import FormPhoneInput from '../../../components/FormPhoneInput';
import FormSelect from '../../../components/FormSelect';
import FormTextarea from '../../../components/FormTextarea';
import InfiniteScrollFormSelect from '../../../components/InfiniteScrollFormSelect';
import Tooltip from '../../../components/Tooltip';
import TaskType from '../../../enums/TaskType';
import useCustomToast from '../../../hooks/useCustomToast';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import UnitsInput from './UnitsInput';

const ManualDataImport = (props) => {
  const { isOpen, onClose: propOnClose } = props;
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      clientId: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      addressNote: '',
      reference: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      endCustomerNote: '',
      date: null,
      units: [
        {
          reference: '',
        },
      ],
      alternativeDeliveryOptionConsent: false,
      alternativeDeliveryOptionCode: '',
      deliveryInstructions: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const { formState, getValues, reset, resetField, watch } = methods;
  const { isDirty, isValid } = formState;

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();

  const { nameTranslations } = useDeliveryOptionsTranslations();

  const { toastSuccess } = useCustomToast();

  const onClose = () => {
    propOnClose();
    reset();
  };

  const currentClientId = watch('clientId');
  const currentAlternativeDeliveryOptionConsent = watch(
    'alternativeDeliveryOptionConsent',
  );
  const currentAlternativeDeliveryOptionCode = watch(
    'alternativeDeliveryOptionCode',
  );

  const {
    data: clientDeliveryOptionsData,
    isFetching: isDeliveryOptionsFetching,
  } = useQuery({
    queryKey: [`/clients/${currentClientId}/delivery-options`],

    queryFn: async () => {
      const response = await fetch(
        `/clients/${currentClientId}/delivery-options`,
        {
          method: 'GET',
        },
      );
      return response.json();
    },
    enabled: !!currentClientId,
  });

  const consentableDeliveryOptions = useMemo(() => {
    const optionsRequiringConsent = clientDeliveryOptionsData?.data?.filter(
      (deliveryOption) => deliveryOption.requiredConsent,
    );
    return optionsRequiringConsent?.map((deliveryOption) => ({
      label:
        nameTranslations[deliveryOption.deliveryOption.code] ||
        deliveryOption.deliveryOption.code,
      value: deliveryOption.deliveryOption.code,
    }));
  }, [clientDeliveryOptionsData, nameTranslations]);

  const mutation = useMutation({
    mutationFn: async () => {
      const {
        alternativeDeliveryOptionCode,
        alternativeDeliveryOptionConsent,
        date,
        deliveryInstructions,
        email,
        phoneNumber,
        units,
        ...rest
      } = getValues();

      const extendedUnits = units.map(({ reference }, index) => ({
        reference,
        number: index + 1,
      }));

      const body = {
        ...rest,
        units: extendedUnits,
        type: TaskType.Delivery,
        date: date?.toISOString(),
        phoneNumber: phoneNumber ? `+${phoneNumber}` : null,
        email: email !== '' ? email : null,
        timeFrom: null,
        timeTo: null,
        country: null,
      };

      if (alternativeDeliveryOptionConsent) {
        body.deliveryOptionsConsent = [alternativeDeliveryOptionCode];
        const selectedDeliveryOption = clientDeliveryOptionsData?.data?.find(
          (deliveryOption) =>
            deliveryOption.deliveryOption.code ===
            alternativeDeliveryOptionCode,
        );

        if (selectedDeliveryOption.requiredInstructions) {
          body.deliveryInstructions = [
            {
              deliveryOption: alternativeDeliveryOptionCode,
              instruction: deliveryInstructions,
            },
          ];
        }
      }

      const response = await fetch(`/tasks`, {
        method: 'POST',
        body,
      });

      return response.json();
    },
    onError: toastFetchError,
    onSuccess: () => {
      toastSuccess(
        t(`Task {{taskId}} imported.`, {
          taskId: getValues().reference,
        }),
      );
      onClose();
      queryClient.invalidateQueries({
        queryKey: [`/tasks`],
      });
    },
  });

  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const modalOnClose = () => {
    if (isDirty) {
      setIsPromptVisible(true);
      return;
    }
    onClose();
  };

  return (
    <>
      <FormProvider {...methods}>
        <Drawer
          onClose={modalOnClose}
          isOpen={isOpen}
          header={<span>{t('Manual Data Import')}</span>}
          body={
            <div className="flex flex-col gap-8">
              <InfiniteScrollFormSelect
                required
                useLocalSearch
                url="/clients"
                name="clientId"
                placeholder={t('Select Client')}
                label={t('Client')}
                transformOptionFn={(client) => ({
                  label: `${client.publicName} (${client?.name})`,
                  value: client.id,
                })}
                onChange={() => {
                  resetField('alternativeDeliveryOptionCode');
                  resetField('alternativeDeliveryOptionConsent');
                  resetField('deliveryInstructions');
                }}
              />
              <div className="flex flex-col gap-4">
                <h3 className="font-medium">{t('Task')}</h3>
                <div className="flex flex-col gap-4">
                  <FormInput
                    label={t('Task Code')}
                    name="reference"
                    id="reference"
                    required
                    placeholder={t('Enter Task code')}
                    minLength={{
                      message:
                        'Task code must be longer than or equal to 8 characters.',
                      value: 8,
                    }}
                  />
                  <hr className="border-grey-200" />
                  <UnitsInput name="units" />
                  <hr className="border-grey-200" />
                  <FormDatePicker
                    dateFormat="dd.MM.yyyy"
                    isClearable
                    label={t('Execution Date')}
                    minDate={new Date()}
                    name="date"
                    id="date"
                    placeholderText={t('Select date')}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <h3 className="font-medium">{t('Customer')}</h3>
                <div className="flex flex-col gap-4">
                  <FormInput
                    label={t('First Name')}
                    name="firstName"
                    id="firstName"
                    required
                    placeholder={t("Enter customer's first name")}
                  />
                  <FormInput
                    label={t('Last Name')}
                    name="lastName"
                    id="lastName"
                    required
                    placeholder={t("Enter customer's last name")}
                  />
                  <hr className="border-grey-200" />
                  <FormInput
                    label={t('Address')}
                    name="street"
                    id="street"
                    required
                    placeholder={t('Enter address and street number')}
                  />
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <FormInput
                        label={t('Postcode')}
                        name="postcode"
                        id="postcode"
                        required
                        placeholder={t('Enter postcode')}
                      />
                    </div>
                    <div className="flex-1">
                      <FormInput
                        label={t('City')}
                        name="city"
                        id="city"
                        required
                        placeholder={t('Enter city name')}
                      />
                    </div>
                  </div>
                  <FormInput
                    label={t('Address Details')}
                    placeholder={t('Floor, apartment, other')}
                    name="addressNote"
                    id="addressNote"
                  />
                  <div>
                    <FormInput
                      label={t('Address Instructions')}
                      placeholder={t(
                        'e.g. Doorbell not functional, call on the cell',
                      )}
                      name="endCustomerNote"
                      id="endCustomerNote"
                    />
                  </div>
                </div>
                <hr className="border-grey-200" />
                <div className="flex flex-col gap-4">
                  <FormCheckbox
                    label={t('Alternative delivery option consent provided')}
                    name="alternativeDeliveryOptionConsent"
                    disabled={currentClientId === ''}
                    tooltip={
                      currentClientId === '' && t('Client must be selected')
                    }
                  />
                  {currentClientId !== '' &&
                    currentAlternativeDeliveryOptionConsent && (
                      <FormSelect
                        isLoading={isDeliveryOptionsFetching}
                        options={consentableDeliveryOptions}
                        label={t('Consented Option')}
                        required
                        placeholder={t('Select option')}
                        name="alternativeDeliveryOptionCode"
                      />
                    )}
                  {clientDeliveryOptionsData?.data?.find(
                    (deliveryOption) =>
                      deliveryOption.deliveryOption.code ===
                        currentAlternativeDeliveryOptionCode &&
                      currentAlternativeDeliveryOptionConsent &&
                      deliveryOption.requiredInstructions,
                  ) && (
                    <FormTextarea
                      name="deliveryInstructions"
                      required
                      label={t('Delivery Instructions')}
                      placeholder={t('Type here...')}
                    />
                  )}
                </div>
                <hr className="border-grey-200" />
                <FormPhoneInput
                  name="phoneNumber"
                  id="phoneNumber"
                  label={t('Phone Number')}
                />
                <FormInput
                  id="email"
                  label={t('E-mail')}
                  name="email"
                  placeholder={t('Enter e-mail address')}
                  type="email"
                />
              </div>
            </div>
          }
          footer={
            <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
              <Button
                className="order-last sm:order-none"
                data-test="modal-button-cancel"
                text={t('Cancel')}
                variant="outlineBlack"
                onClick={modalOnClose}
              />
              <Tooltip
                hidden={mutation.isPending || isValid}
                text={t('All mandatory fields must be filled in to proceed')}
              >
                <Button
                  className="w-full"
                  data-test="modal-button-action"
                  disabled={mutation.isPending || !isDirty || !isValid}
                  text={t('Import')}
                  variant="solidBlue"
                  onClick={mutation.mutate}
                />
              </Tooltip>
            </div>
          }
        />
      </FormProvider>
      <BlockNavigation shouldBlock={isDirty}>
        {(blocker) => (
          <CancelPrompt
            title={t('Cancel Manual Data Import?')}
            isOpen={blocker.state === 'blocked'}
            onClose={() => {
              if (blocker.state === 'blocked') {
                blocker.reset();
              }
            }}
            onExitClick={() => {
              if (blocker.state === 'blocked') {
                blocker.proceed();
              }
              onClose();
            }}
          />
        )}
      </BlockNavigation>
      <CancelPrompt
        title={t('Cancel Manual Data Import?')}
        isOpen={isPromptVisible}
        onClose={() => {
          setIsPromptVisible(false);
        }}
        onExitClick={() => {
          setIsPromptVisible(false);
          onClose();
        }}
      />
    </>
  );
};

ManualDataImport.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ManualDataImport.defaultProps = {
  isOpen: false,
  onClose: undefined,
};

export default ManualDataImport;
