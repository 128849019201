import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import UserRole from '../../enums/UserRole';
import HubsCardList from '../../features/hubs/HubsCardList';
import HubsTable from '../../features/hubs/HubsTable';
import WarehousesFilters from '../../features/shared/HubFilters';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';
import Allow from '../../lib/rbac/Allow';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationHubs = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${user.carrier.id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${user.carrier.id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const [searchParams] = useSearchParams({});

  const clientIdQuery = searchParams.get('clientId') || '';
  const nameQuery = searchParams.get('name') || '';
  const hasFiltersApplied = !!(clientIdQuery || nameQuery);

  const params = useMemo(
    () => ({
      clientId: clientIdQuery,
      name: nameQuery,
    }),
    [clientIdQuery, nameQuery],
  );

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        isLoading={isPending}
        backLink="/my-organisation"
        hasFiltersApplied={hasFiltersApplied}
        menu={<WarehousesFilters carrierName={organisation?.name} />}
        textPrimary={`${t('Hubs')} (${organisation?.hubCount})`}
        textSecondary={organisation?.name}
        titleRightContent={
          <Allow roles={[UserRole.Admin]}>
            <Button
              as={Link}
              className="w-full lg:w-fit"
              icon="plus"
              text={t('New Hub')}
              to="new"
              variant="solidBlue"
            />
          </Allow>
        }
      />
      {organisation && (
        <Page.Content variant={isDesktop ? 'white' : 'grey'}>
          <Resource
            params={params}
            resourceUrl={`/carriers/${organisation?.id}/hubs`}
          >
            {isDesktop ? <HubsTable /> : <HubsCardList />}
          </Resource>
        </Page.Content>
      )}
    </Page>
  );
};
export default MyOrganisationHubs;
