import Breakpoint from '../../enums/Breakpoint';
import GroceriesToursPageDesktop from '../../features/groceries/GroceriesToursPage/GroceriesToursPageDesktop';
import GroceriesToursPageMobile from '../../features/groceries/GroceriesToursPage/GroceriesToursPageMobile';
import useWindowSize from '../../hooks/useWindowSize';

const FixedTours = () => {
  const { width } = useWindowSize();

  const isMobile = width < Breakpoint.LG;

  if (isMobile) {
    return <GroceriesToursPageMobile />;
  }

  return <GroceriesToursPageDesktop />;
};

export default FixedTours;
