import moment from 'moment/moment';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import ToursSearchBy from '../enums/ToursSearchBy';
import useDateQuery from './useDateQuery';

const useGroceriesToursPageSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    searchBy: ToursSearchBy.TourNumber,
  });
  const dateQuery = useDateQuery();

  const hubIdQuery = searchParams.get('hubId') || '';
  const searchByQuery =
    searchParams.get('searchBy') || ToursSearchBy.TourNumber;
  const searchQuery = searchParams.get('search') || '';

  const statusQuery = searchParams.get('tourStatus') || '';
  const shiftQuery = searchParams.get('shiftNumber') || '';
  const subcarrierQuery = searchParams.get('subcarrierId') || '';

  const isFilterApplied = useMemo(
    () =>
      !!dateQuery ||
      !!hubIdQuery ||
      !!searchQuery ||
      !!shiftQuery ||
      !!statusQuery ||
      !!subcarrierQuery,
    [
      dateQuery,
      hubIdQuery,
      searchQuery,
      shiftQuery,
      statusQuery,
      subcarrierQuery,
    ],
  );

  useEffect(() => {
    if (!Object.values(ToursSearchBy).includes(searchByQuery)) {
      searchParams.set('searchBy', ToursSearchBy.TourNumber);
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, [searchByQuery, searchParams, setSearchParams]);

  const allParams = {
    date: dateQuery ? moment(dateQuery).format('YYYY-MM-DD') : undefined,
    hubId: hubIdQuery,
    shiftNumber: shiftQuery,
    status: statusQuery,
    subcarrierId: subcarrierQuery,
    ...(searchQuery && { search: searchQuery }),
    ...(searchQuery && { searchBy: searchByQuery }),
  };

  return {
    dateQuery,
    hubIdQuery,
    searchParams,
    shiftQuery,
    setSearchParams,
    searchQuery,
    searchByQuery,
    statusQuery,
    allParams,
    isFilterApplied,
    subcarrierQuery,
  };
};

export default useGroceriesToursPageSearchParams;
