import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationForm from '../../features/shared/OrganisationForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationEdit = () => {
  const { toastSuccess } = useCustomToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUser();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${user.carrier.id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${user.carrier.id}`);
      return response.json();
    },
  });

  const mutation = useMutation({
    mutationFn: async (values) => {
      const body = {
        address: values.address,
        name: values.name,
        publicName: values.publicName,
      };

      const response = await fetch(`/carriers/${user.carrier.id}`, {
        body,
        method: 'PATCH',
      });
      return response.json();
    },
    onError: toastFetchError,
    onSuccess: () => {
      setIsFormDirty(false);
      toastSuccess(t('Organisation data successfully updated.'));
    },
  });

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, mutation.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  const organisation = fetchedOrganisation?.data;

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate(`/my-organisation`, { replace: true });
    }
  }, [mutation.isSuccess, navigate]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <CancelPrompt
        title={t('Cancel Editing Organisation?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => {
          blocker.reset();
        }}
        onExitClick={() => {
          blocker.proceed();
        }}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="edit" />}
          textPrimary={t('Edit Organisation')}
          textSecondary={organisation?.name}
          isLoading={isPending}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              disabled={mutation.isPending}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate(`/my-organisation`)}
            />
          }
        />

        <Page.Content centerContent variant="grey">
          <div className="w-full max-w-[480px]">
            <Card className="pt-6 sm:p-8">
              {isPending ? (
                <div className="grid gap-8">
                  <Skeleton height={156} />
                  <Skeleton height={110} />
                  <Skeleton height={110} />
                </div>
              ) : (
                <OrganisationForm
                  errorCode={mutation?.error?.data?.errorCode}
                  errors={mutation?.error?.data?.errors}
                  isEdit
                  isSubmitting={mutation.isPending}
                  organisation={organisation}
                  onCancel={() => navigate(`/my-organisation`)}
                  onIsDirtyChange={setIsFormDirty}
                  onSubmit={mutation.mutate}
                />
              )}
            </Card>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default MyOrganisationEdit;
