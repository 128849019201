import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import DebouncedSearch from '../../components/DebouncedSearch';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import ClientsCardList from '../../features/clients/ClientsCardList';
import ClientsTable from '../../features/clients/ClientsTable';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';

const OrganisationCustomers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
  } = useQuery({
    queryKey: [`/carriers/${id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const [searchParams, setSearchParams] = useSearchParams();

  const searchQuery = searchParams.get('search') || '';
  const hasFiltersApplied = !!searchQuery;

  const params = useMemo(
    () => ({
      name: searchQuery,
    }),
    [searchQuery],
  );

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (!organisation) {
    return null;
  }

  return (
    <Page>
      <Titlebar
        backLink={`/organisations/${id}`}
        isActive={hasFiltersApplied}
        menu={
          <div className="lg:w-[200px]">
            <DebouncedSearch
              placeholder={`${t('Find client')}...`}
              searchValue={searchQuery}
              onSearch={(newSearchValue) => {
                if (newSearchValue === searchQuery) {
                  return;
                }
                searchParams.set('page', '1');
                if (!newSearchValue) {
                  searchParams.delete('search');
                } else {
                  searchParams.set('search', newSearchValue?.trim());
                }
                setSearchParams(searchParams);
              }}
            />
          </div>
        }
        textPrimary={`${t('Clients')} (${organisation?.clients?.length || 0})`}
        textSecondary={organisation?.name}
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource params={params} resourceUrl={`/carriers/${id}/clients`}>
          {isDesktop ? <ClientsTable /> : <ClientsCardList />}
        </Resource>
      </Page.Content>
    </Page>
  );
};
export default OrganisationCustomers;
