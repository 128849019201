import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import BlockNavigation from '../../../components/BlockNavigation';
import Button from '../../../components/Button';
import CancelPrompt from '../../../components/CancelPrompt';
import Drawer from '../../../components/Drawer';
import FormTextarea from '../../../components/FormTextarea';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';

const AddUnitNote = (props) => {
  const { isOpen, onClose, showBackButton, transparentBackdrop, unitId } =
    props;
  const { t } = useTranslation();
  const { toastSuccess } = useCustomToast();

  const methods = useForm({
    defaultValues: {
      note: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isDirty },
    getValues,
    reset,
  } = methods;

  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const drawerOnClose = () => {
    if (isDirty) {
      setIsPromptVisible(true);
      return;
    }
    onClose();
    reset();
  };

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => {
      const { note } = getValues();

      return fetch(`/units/${unitId}/note`, {
        method: 'POST',
        body: {
          note,
        },
      });
    },
    onError: (error) => toastFetchError(error),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: [`/units/${unitId}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`/units/${unitId}`, 'details'],
      });
      toastSuccess(t('Unit note added.'));
      reset();
    },
  });

  return (
    <>
      <Drawer
        showBackButton={showBackButton}
        header={t('Add Unit Note')}
        transparentBackdrop={transparentBackdrop}
        onClose={drawerOnClose}
        isOpen={isOpen}
        footer={
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={drawerOnClose}
            />
            <Button
              disabled={mutation.isPending || !isDirty}
              text={t('Save')}
              variant="solidBlue"
              onClick={mutation.mutate}
            />
          </div>
        }
        body={
          <FormProvider {...methods}>
            <FormTextarea
              showCounter
              label={t('Add note')}
              name="note"
              placeholder={t('Type here...')}
            />
            <Alert
              className="mt-2"
              variant="info"
              fontWeight="normal"
              message={t(
                'Keep in mind that the note is immutable, and editing or deleting is not possible; it will be displayed in the event history.',
              )}
            />
          </FormProvider>
        }
      />

      <CancelPrompt
        title={t('Cancel Adding Note?')}
        isOpen={isPromptVisible}
        onClose={() => {
          setIsPromptVisible(false);
        }}
        onExitClick={() => {
          setIsPromptVisible(false);
          onClose();
          reset();
        }}
      />

      <BlockNavigation shouldBlock={isDirty}>
        {(blocker) => (
          <CancelPrompt
            title={t('Cancel Adding Note?')}
            isOpen={blocker.state === 'blocked'}
            onClose={() => {
              setIsPromptVisible(false);
              if (blocker.state === 'blocked') {
                blocker.reset();
              }
            }}
            onExitClick={() => {
              setIsPromptVisible(false);
              if (blocker.state === 'blocked') {
                blocker.proceed();
              }
              onClose();
              reset();
            }}
          />
        )}
      </BlockNavigation>
    </>
  );
};

AddUnitNote.propTypes = {
  unitId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transparentBackdrop: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

AddUnitNote.defaultProps = {
  unitId: undefined,
  transparentBackdrop: false,
  showBackButton: false,
};

export default AddUnitNote;
