import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import Select from '../../../components/Select';
import Breakpoint from '../../../enums/Breakpoint';
import CheckoutType from '../../../enums/CheckoutType';
import TourStatusFilter from '../../../enums/TourStatusFilter';

const TourStatusSelect = (props) => {
  const { checkoutType, onChange, tourStatus, ...rest } = props;

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const { t } = useTranslation();

  const value = useMemo(() => {
    if (!tourStatus) {
      return undefined;
    }

    if (checkoutType) {
      return `${tourStatus}-${checkoutType}`;
    }

    return tourStatus;
  }, [checkoutType, tourStatus]);

  const options = useMemo(
    () => [
      {
        label: t('Created'),
        value: TourStatusFilter.Created,
      },
      {
        label: t('Driver not assigned'),
        value: TourStatusFilter.NotAssignedDriver,
      },
      {
        label: t('Pending activation'),
        value: TourStatusFilter.Pending,
      },
      {
        label: t('Active'),
        value: TourStatusFilter.Active,
      },
      {
        value: TourStatusFilter.Completed,
        isGroupSelectable: true,
        groupLabel: t('Completed'),
        backButtonLabel: t('Tour status group'),
        options: [
          {
            label: t('By Driver'),
            value: `${TourStatusFilter.Completed}-${CheckoutType.Driver}`,
          },
          {
            label: t('By Supervisor'),
            value: `${TourStatusFilter.Completed}-${CheckoutType.Manual}`,
          },
        ],
      },
    ],
    [t],
  );

  return (
    <Select
      isSingleDropdown={!isDesktop}
      id="tour-status-select"
      options={options}
      placeholder={t('Status')}
      required={false}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

TourStatusSelect.propTypes = {
  onChange: PropTypes.func,
  tourStatus: PropTypes.string,
  checkoutType: PropTypes.string,
};

TourStatusSelect.defaultProps = {
  onChange: undefined,
  tourStatus: undefined,
  checkoutType: undefined,
};

export default TourStatusSelect;
