import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DebouncedSearch from '../../../components/DebouncedSearch';
import Select from '../../../components/Select';
import ToursSearchBy from '../../../enums/ToursSearchBy';

const ToursSearch = (props) => {
  const { onSearch, onSearchByChange, searchBy, searchValue } = props;

  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        label: t('Tour ID'),
        value: ToursSearchBy.TourNumber,
      },
      {
        dropdownLabel: t('Driver name'),
        label: t('Driver'),
        value: ToursSearchBy.DriverName,
      },
    ],
    [t],
  );

  return (
    <div className="flex items-center">
      <Select
        className="w-[115px]"
        dropdownClassname="lg:w-[160px]"
        flatRight
        id="tour-id-select"
        isSearchable={false}
        options={options}
        required
        value={searchBy}
        onChange={onSearchByChange}
      />
      <div className="flex-1">
        <DebouncedSearch
          minLength={3}
          className="rounded-l-none lg:w-[240px]"
          isFullWidth
          placeholder={`${t('Type here')} (min. 3)...`}
          searchValue={searchValue}
          onSearch={onSearch}
          data-test="tours-search-input"
        />
      </div>
    </div>
  );
};

ToursSearch.propTypes = {
  onSearch: PropTypes.func,
  onSearchByChange: PropTypes.func,
  searchBy: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
};

ToursSearch.defaultProps = {
  onSearch: () => {},
  onSearchByChange: () => {},
  searchValue: undefined,
};

export default ToursSearch;
