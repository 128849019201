import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import Modal from '../../components/Modal';

const ConfirmMemberDeleteModal = (props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onConfirm } = props;

  return (
    <Modal
      body={
        <p>
          {t(
            'Note that this action is permanent. Member will no longer exist in the Urbify system.',
          )}
        </p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Delete')}
            variant="solidRed"
            onClick={onConfirm}
          />
        </div>
      }
      header={<span>{t('Delete Member?')}</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

ConfirmMemberDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

ConfirmMemberDeleteModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onConfirm: () => {},
};

export default ConfirmMemberDeleteModal;
