import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import SubcarrierFilters from '../../features/shared/SubcarrierFilters';
import SubcarriersCardList from '../../features/subcarriers/SubcarriersCardList';
import SubcarriersTable from '../../features/subcarriers/SubcarriersTable';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';

const OrganisationSubcarriers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${id}`);
      return response.json();
    },
  });

  const [searchParams] = useSearchParams({});

  const organisation = fetchedOrganisation?.data || null;
  const subcarrierCount = organisation?.subcarrierCount || 0;
  const nameQuery = searchParams.get('name') || '';

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const params = useMemo(
    () => ({
      name: nameQuery,
    }),
    [nameQuery],
  );

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={`/organisations/${id}`}
        menu={<SubcarrierFilters />}
        textPrimary={`${t('Subcarriers')} (${subcarrierCount})`}
        textSecondary={organisation?.name}
        isLoading={isPending}
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource params={params} resourceUrl={`/carriers/${id}/subcarriers`}>
          {isDesktop ? <SubcarriersTable /> : <SubcarriersCardList />}
        </Resource>
      </Page.Content>
    </Page>
  );
};
export default OrganisationSubcarriers;
