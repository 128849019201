import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import UserRole from '../../enums/UserRole';
import ShiftsCardList from '../../features/shifts/ShiftsCardList';
import ShiftsTable from '../../features/shifts/ShiftsTable';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';
import Allow from '../../lib/rbac/Allow';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationShifts = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const { fetch } = useFetch();

  const { user } = useUser();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${user.carrier.id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${user.carrier.id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink="/my-organisation"
        closeOnLocationChange={false}
        textPrimary={`${t('Shifts')} (${organisation?.shiftCount})`}
        textSecondary={organisation?.name}
        isLoading={isPending}
        titleRightContent={
          <Allow roles={[UserRole.Admin]}>
            <Button
              as={Link}
              className="w-full lg:w-fit"
              icon="plus"
              text={t('New Shift')}
              to="new"
              variant="solidBlue"
            />
          </Allow>
        }
      />
      {organisation && (
        <Page.Content variant={isDesktop ? 'white' : 'grey'}>
          <Resource resourceUrl={`/carriers/${organisation?.id}/shifts`}>
            {isDesktop ? <ShiftsTable /> : <ShiftsCardList />}
          </Resource>
        </Page.Content>
      )}
    </Page>
  );
};

export default MyOrganisationShifts;
