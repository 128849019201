import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import Collapse from '../../../components/Collapse';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Icon from '../../../components/Icon';
import LinkButton from '../../../components/LinkButton';
import ListSelectionCheckbox from '../../../components/ListSelectionCheckbox';
import Tooltip from '../../../components/Tooltip';
import TaskStatus from '../../../enums/TaskStatus';
import TaskType from '../../../enums/TaskType';
import TasksUnitsHelper from '../../../helpers/TasksUnitsHelper';
import useCopyTaskInfoToClipboard from '../../../hooks/useCopyTaskInfoToClipboard';
import useTaskStatusTranslations from '../../../hooks/useTaskStatusTranslations';
import useTaskTypeTranslations from '../../../hooks/useTaskTypeTranslations';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import ShiftLabel from '../../shared/ShiftLabel';
import NestedUnits from './NestedUnits';

const Row = ({ children, title, withBorder }) => (
  <div
    className={cn(
      'flex flex-col gap-3 pb-3 pt-4',
      withBorder && 'border-t border-grey-200',
    )}
  >
    {title && <span className="text-grey-700 text-xs">{title}</span>}
    <div>{children}</div>
  </div>
);
Row.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  withBorder: PropTypes.bool,
};

Row.defaultProps = {
  title: undefined,
  withBorder: true,
};

const TaskCard = (props) => {
  const {
    onUnitDetailsClick,
    onUpdateAddressClick,
    onUpdateDateClick,
    onUpdateUnitStatus,
    task,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [seeAll, setSeeAll] = useState(false);
  const copyTaskInfoToClipboard = useCopyTaskInfoToClipboard();
  const { isSelectionVisible } = useListSelection();
  const typeTranslation = useTaskTypeTranslations();
  const statusTranslation = useTaskStatusTranslations();

  const menuItems = () => {
    const isAddressEditable = TasksUnitsHelper.isTaskAddressActionEnabled(task);
    const isDateEditable =
      TasksUnitsHelper.isTaskExecutionDateActionEnabled(task);

    return [
      {
        icon: 'locationAddress',
        iconClassName: 'text-grey-700',
        onClick: () => {
          if (isAddressEditable) {
            onUpdateAddressClick(task);
          }
        },
        textClassName: 'w-full',
        isDisabled: !isAddressEditable,
        text: (
          <Tooltip
            hidden={isAddressEditable}
            text={t('Update address is available only for Tasks without Tour')}
          >
            <div className="flex items-center w-full justify-between flex-1 gap-1">
              <span>{t('Update Address')}</span>
              {!isAddressEditable && (
                <Icon icon="infoFilled" className="h-4 w-4 text-grey-700" />
              )}
            </div>
          </Tooltip>
        ),
        key: 'update-address',
      },
      {
        icon: 'dateRange',
        iconClassName: 'text-grey-700',
        onClick: () => {
          if (isDateEditable) {
            onUpdateDateClick(task);
          }
        },
        textClassName: 'w-full',
        isDisabled: !isDateEditable,
        text: (
          <Tooltip
            hidden={isDateEditable}
            text={t('Update date is available only for Tasks without Tour')}
          >
            <div className="flex items-center w-full justify-between flex-1 gap-1">
              <span>{t('Change Execution Date')}</span>
              {!isDateEditable && (
                <Icon icon="infoFilled" className="h-4 w-4 text-grey-700" />
              )}
            </div>
          </Tooltip>
        ),
        key: 'update-date',
      },
      {
        icon: 'copy',
        iconClassName: 'text-grey-700',
        onClick: async () => {
          copyTaskInfoToClipboard(task);
        },
        text: t('Copy info to clipboard'),
      },
    ];
  };

  return (
    <CollapsableCard
      id={task.id || task.code}
      expandedContent={
        <div className="flex flex-col">
          {!isSelectionVisible && (
            <NestedUnits
              units={task.units}
              onUpdateUnitStatus={onUpdateUnitStatus}
              onUnitDetailsClick={onUnitDetailsClick}
            />
          )}
          <Row title={t('Task Type')} withBorder={false}>
            <span className="text-sm">{typeTranslation[task.type]}</span>
          </Row>
          <Row title={t('Address')}>
            <span
              className={cn('text-sm', task.invalidAddress && 'text-ui-red')}
            >
              {task.address || 'N/A'}
            </span>
          </Row>
          <Row title={t('Execution Date')}>
            <span>{moment(task.date).format('DD.MM.YYYY.')}</span>
          </Row>
          <Row title={t('Execution Slot')}>
            <div className="flex items-center gap-2 text-sm">
              <ShiftLabel shiftNumber={task.shiftNumber} />
              <span>
                {moment(task.timeFrom).format('HH:mm')} -{' '}
                {moment(task.timeTo).format('HH:mm')}
              </span>
            </div>
          </Row>
          <Row title={t('Execution Window')}>
            <div className="flex items-center justify-between gap-2 text-sm">
              <span>
                {task.latestTour?.tourStop?.windowFrom &&
                task.latestTour?.tourStop?.windowTo
                  ? `${moment(task.latestTour.tourStop.windowFrom).format(
                      'HH:mm',
                    )}-${moment(task.latestTour.tourStop.windowTo).format(
                      'HH:mm',
                    )}`
                  : 'N/A'}
              </span>
            </div>
          </Row>
          <Row title={t('Date Opened')}>
            <div className="flex items-center justify-between gap-2 text-sm">
              <span>{moment(task.createdAt).format('DD.MM.YYYY.')}</span>
            </div>
          </Row>
          <Row title={t('Tour Stop')}>
            <div className="text-sm">
              {task.latestTour?.tourStop ? (
                <Link
                  to={{
                    pathname: `/tours/${task.latestTour?.id}`,
                    search: `?stopId=${task.latestTour?.tourStop.id}`,
                  }}
                  state={{
                    backLink: -1,
                  }}
                  className="flex justify-between w-full items-center"
                >
                  <LinkButton
                    text={`${task.latestTour?.vehicle} ${task.latestTour?.tourStop?.sequence}/${task.latestTour?.totalStops}`}
                  />
                  <Icon className="h-4 w-4 text-ui-blue" icon="openInNew" />
                </Link>
              ) : (
                'N/A'
              )}
            </div>
          </Row>
          <Collapse isOpen={seeAll}>
            <Row title={t('Hub')}>
              <span className="text-sm">{task?.hub?.shortCode || 'N/A'}</span>
            </Row>
            <Row title={t('Client')}>
              <span className="text-sm">{task.client?.name || 'N/A'}</span>
            </Row>
            <Row title={t('Customer info')}>
              <span className="text-sm">{task.endCustomerName || 'N/A'}</span>
            </Row>
          </Collapse>
          <Row>
            <div>
              <LinkButton
                text={seeAll ? t('See Less') : t('See All')}
                onClick={() => {
                  if (seeAll) {
                    setSeeAll(false);
                    return;
                  }
                  setSeeAll(true);
                }}
              />
              {!isSelectionVisible && (
                <div className="flex flex-col rounded-md p-4 bg-grey-100 mt-4">
                  <Dropdown menu={menuItems}>
                    <Button
                      color="secondary"
                      icon="moreVert"
                      isFullWidth
                      size="s"
                      text={t('More Unit options')}
                      variant="outlineBlack"
                    />
                  </Dropdown>
                </div>
              )}
            </div>
          </Row>
        </div>
      }
      {...rest}
    >
      <div className="flex gap-4">
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex gap-2 items-center">
            {isSelectionVisible && (
              <div className="flex items-center">
                <ListSelectionCheckbox
                  record={task}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            )}

            <span className="">{task?.code}</span>
          </div>
          <div>
            <span className="">{statusTranslation[task.status]}</span>
          </div>
        </div>
      </div>
    </CollapsableCard>
  );
};

TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    type: PropTypes.oneOf(Object.values(TaskType)),
    status: PropTypes.oneOf(Object.values(TaskStatus)),
    address: PropTypes.string,
    invalidAddress: PropTypes.bool,
    date: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    shiftNumber: PropTypes.number,
    latestTour: PropTypes.shape({
      id: PropTypes.string,
      vehicle: PropTypes.string,
      totalStops: PropTypes.number,
      tourStop: PropTypes.shape({
        id: PropTypes.string,
        sequence: PropTypes.number,
        windowTo: PropTypes.string,
        windowFrom: PropTypes.string,
      }),
    }),
    units: PropTypes.arrayOf(PropTypes.shape({})),
    endCustomerName: PropTypes.string,
    hub: PropTypes.shape({
      id: PropTypes.string,
      shortCode: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    client: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  onUpdateAddressClick: PropTypes.func,
  onUpdateDateClick: PropTypes.func,
  onUpdateUnitStatus: PropTypes.func.isRequired,
  onUnitDetailsClick: PropTypes.func.isRequired,
};

TaskCard.defaultProps = {
  task: undefined,
  onUpdateAddressClick: undefined,
  onUpdateDateClick: undefined,
};

export default TaskCard;
