import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import OrganisationType from '../../../enums/OrganisationType';
import usePlatformFeaturesTranslations from '../../../hooks/usePlatformFeaturesTranslations';
import useFetch from '../../../lib/api/hooks/useFetch';

const OrganisationDetailsPlatformFeaturesCard = ({
  organisationId,
  organisationType,
}) => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const platformFeaturesTranslations = usePlatformFeaturesTranslations();

  const organisationFeaturesUrl =
    organisationType === OrganisationType.Carrier
      ? `/carriers/${organisationId}/features`
      : `/clients/${organisationId}/features`;

  const { data: featuresData } = useQuery({
    queryKey: [organisationFeaturesUrl],

    queryFn: async () => {
      const response = await fetch(organisationFeaturesUrl, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const features = useMemo(
    () => featuresData?.data?.features || [],
    [featuresData?.data?.features],
  );

  return (
    <div className="mt-5 rounded-md bg-grey-100 p-3">
      <div className="flex flex-col gap-3 divide-y divide-grey-300">
        <div className="font-medium">
          {organisationType === OrganisationType.Carrier
            ? t('Carrier Platform Features')
            : t('Platform Features for Clients')}
        </div>
        <div className="flex flex-col gap-3 pt-3">
          {features.length > 0 && (
            <>
              <div className="text-xs leading-4 text-grey-700">
                {t('Features activated')}
              </div>
              <div className="text-sm">
                {features
                  .map(
                    (item) =>
                      platformFeaturesTranslations[`${item.code}:name`] ||
                      item.code,
                  )
                  .join(', ')}
              </div>
            </>
          )}
          <Button
            as={Link}
            className="w-max"
            icon="tune"
            text={t('Manage Features')}
            to="platform-features"
            variant="outlineBlue"
          />
        </div>
      </div>
    </div>
  );
};

OrganisationDetailsPlatformFeaturesCard.propTypes = {
  organisationType: PropTypes.string,
  organisationId: PropTypes.string,
};

OrganisationDetailsPlatformFeaturesCard.defaultProps = {
  organisationType: undefined,
  organisationId: undefined,
};
export default OrganisationDetailsPlatformFeaturesCard;
