import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import ActivityItem from '../../components/ActivityItem';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import FadingOverlay from '../../components/FadingOverlay';
import Page from '../../components/Page';
import TabsCard from '../../components/TabsCard';
import Titlebar from '../../components/Titlebar';
import ActivityStatsItem from '../../features/activity/ActivityStatsItem';
import DismissAlertsModal from '../../features/activity/DissmisAlertsModal';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import { SocketHandler } from '../../lib/api/hooks/useSocket';
import { useUser } from '../../providers/UserProvider';

const InnerContent = (props) => {
  const {
    isOrderCountDataLoading,
    isStatusCenterDataLoading,
    orderCountData,
    statusCenterData,
  } = props;
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const queryClient = useQueryClient();

  const markAsRead = useMutation({
    mutationFn: (activityId) =>
      fetch(`/activity/${activityId}/read`, {
        method: 'PATCH',
      }),
    onError: (error) => toastFetchError(error),
    onSuccess: () => {
      toastSuccess(t('Alert marked as read.'));
      queryClient.invalidateQueries({
        queryKey: [`/activity/statusCenter/`],
      });
    },
  });

  return (
    <div className="flex flex-col gap-4 xl:flex-row">
      <div className="flex basis-full flex-col gap-4 xl:basis-1/3">
        {isStatusCenterDataLoading ? (
          <Card>
            <Skeleton height={68} />
          </Card>
        ) : (
          <ActivityStatsItem
            title={t('Alerts in Active Tours')}
            value={parseInt(statusCenterData.activityCount, 10)}
          />
        )}
        {isOrderCountDataLoading ? (
          <>
            <Card>
              <Skeleton height={68} />
            </Card>
            <Card>
              <Skeleton height={68} />
            </Card>
          </>
        ) : (
          <>
            <ActivityStatsItem
              title={t('Tours completed this week')}
              value={parseInt(orderCountData.completed, 10)}
              variant="secondary"
            />
            <ActivityStatsItem
              title={t('Unassigned Tours')}
              value={parseInt(orderCountData.awaiting, 10)}
              variant="ternary"
            />
          </>
        )}
      </div>

      <div className="flex basis-full flex-col gap-4 xl:basis-2/3">
        {isStatusCenterDataLoading ? (
          <Card>
            <FadingOverlay>
              <div className="flex flex-col gap-4">
                <Skeleton height={100} />
                <Skeleton height={100} />
                <Skeleton height={100} />
              </div>
            </FadingOverlay>
          </Card>
        ) : (
          <TabsCard
            tabs={[
              {
                content:
                  statusCenterData.activity.length > 0 ? (
                    <div>
                      {statusCenterData.activity.map((item) => (
                        <ActivityItem
                          activityItem={item}
                          key={item.id}
                          markAsRead={markAsRead.mutate}
                          tourLink
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="mx-auto max-w-[296px] rounded-md px-4 pb-9 pt-[52px] text-center text-sm text-grey-700">
                      {t(
                        'You’re all caught up. There are no alerts that need your attention.',
                      )}
                    </div>
                  ),
                label: t('Alerts'),
              },
              {
                content:
                  statusCenterData.recentActivity.length > 0 ? (
                    <div>
                      {statusCenterData.recentActivity.map((item) => (
                        <ActivityItem
                          activityItem={item}
                          isRecent
                          key={item.id}
                          markAsRead={markAsRead.mutate}
                          tourLink
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="mx-auto rounded-md px-4 pb-[46px] pt-[62px] text-center text-sm text-grey-700">
                      {t('There are no past activities.')}
                    </div>
                  ),
                label: t('Recent'),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

InnerContent.propTypes = {
  isStatusCenterDataLoading: PropTypes.bool,
  isOrderCountDataLoading: PropTypes.bool,
  orderCountData: PropTypes.shape({
    awaiting: PropTypes.string,
    completed: PropTypes.string,
    total: PropTypes.string,
  }),
  statusCenterData: PropTypes.shape({
    activity: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    activityCount: PropTypes.number,
    recentActivity: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  }),
};

InnerContent.defaultProps = {
  isStatusCenterDataLoading: false,
  isOrderCountDataLoading: false,
  orderCountData: undefined,
  statusCenterData: undefined,
};

const Activity = () => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const queryClient = useQueryClient();
  const [isDismissAlertsModalOpen, setIsDismissAlertsModalOpen] =
    useState(false);
  const { isGroceries } = useUser();

  const url = isGroceries ? `/grocery-tours/count` : `/tours/count`;

  const {
    data: orderCountData,
    error: orderCountError,
    isError: orderCountIsError,
    isLoading: isLoadingOrderCountData,
    refetch: refetchOrderCountData,
  } = useQuery({
    queryKey: [url],

    queryFn: async () => {
      const response = await fetch(url, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const {
    data: statusCenterData,
    error: statusCenterError,
    isError: statusCenterIsError,
    isLoading: isLoadingStatusCenterData,
    refetch: refetchStatusCenterData,
  } = useQuery({
    queryKey: [`/activity/statusCenter/`],

    queryFn: async () => {
      const response = await fetch(`/activity/statusCenter/`, {
        method: 'GET',
      });

      return response.json();
    },
  });

  const markAllAsRead = useMutation({
    mutationFn: () =>
      fetch('/activity/readAll', {
        method: 'PATCH',
      }),
    onError: (error) => toastFetchError(error),
    onSuccess: () => {
      toastSuccess(t('All alerts marked as read.'));
      setIsDismissAlertsModalOpen(false);
      queryClient.invalidateQueries({
        queryKey: [`/activity/statusCenter/`],
      });
    },
  });

  if (orderCountIsError || statusCenterIsError) {
    return <ErrorPage error={orderCountError || statusCenterError} />;
  }

  const title = t('Activity');
  return (
    <>
      <SocketHandler
        entity="activity"
        onEvent={(event) => {
          if (event.type === 'created') {
            refetchOrderCountData();
            refetchStatusCenterData();
          }
        }}
      />
      <DismissAlertsModal
        handleReadAll={markAllAsRead.mutate}
        isOpen={isDismissAlertsModalOpen}
        onClose={() => setIsDismissAlertsModalOpen(false)}
      />
      <Page>
        <Titlebar
          textPrimary={title}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              icon="xCircleFilled"
              text={t('Dismiss All Alerts')}
              variant="outlineBlack"
              onClick={() => setIsDismissAlertsModalOpen(true)}
            />
          }
        />
        <Page.Content variant="grey">
          <InnerContent
            isStatusCenterDataLoading={isLoadingStatusCenterData}
            isOrderCountDataLoading={isLoadingOrderCountData}
            orderCountData={orderCountData?.data}
            statusCenterData={statusCenterData?.data}
          />
        </Page.Content>
      </Page>
    </>
  );
};

export default Activity;
