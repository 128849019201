import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import CollapsableCardsProvider from '../../providers/CollapsableCardsProvider';
import Card from '../Card/Card';
import FadingOverlay from '../FadingOverlay';
import Icon from '../Icon';
import StickyLoader from '../StickyLoader';

const CardListContainer = (props) => {
  const { children, id, isEmpty, isLoading, isMultipleExpandable, isPending } =
    props;
  const { t } = useTranslation();

  if (isPending) {
    return (
      <Card>
        <FadingOverlay>
          <div className="grid gap-4">
            <Skeleton height={84} />
            <Skeleton height={84} />
            <Skeleton height={84} />
          </div>
        </FadingOverlay>
      </Card>
    );
  }

  return (
    <div
      className={cn(
        'relative flex flex-col items-center justify-center',
        isLoading && 'min-h-[92px]',
        isEmpty && !isLoading && 'min-h-[156px]',
      )}
    >
      <div className={cn('w-full', isLoading && 'opacity-40')}>
        {isEmpty ? (
          <div className="flex h-full flex-col items-center justify-center">
            {!isLoading && (
              <>
                <Icon className="h-10 w-10 text-grey-300" icon="inbox" />
                <span className="mt-4 text-sm text-grey-700">
                  {t('No records')}
                </span>
              </>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <CollapsableCardsProvider
              id={id}
              isMultipleExpandable={isMultipleExpandable}
            >
              {children}
            </CollapsableCardsProvider>
          </div>
        )}
      </div>
      <StickyLoader isLoading={isLoading} />
    </div>
  );
};

CardListContainer.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isPending: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isMultipleExpandable: PropTypes.bool,
};

CardListContainer.defaultProps = {
  id: undefined,
  children: undefined,
  isLoading: false,
  isPending: false,
  isEmpty: false,
  isMultipleExpandable: false,
};

export default CardListContainer;
