import PropTypes from 'prop-types';

import CardListContainer from '../../../components/CardListContainer';
import OrganisationCard from '../OrganisationCard';

const OrganisationsCardList = (props) => {
  const { data, isLoading, isPending } = props;
  return (
    <CardListContainer
      isLoading={isLoading}
      isPending={isPending}
      isEmpty={data.length === 0}
    >
      {data.map((organisation) => (
        <OrganisationCard
          address={organisation.address}
          id={organisation.id}
          key={organisation.id}
          memberCount={organisation.userCount}
          name={organisation.name}
          type={organisation.type}
        />
      ))}
    </CardListContainer>
  );
};

OrganisationsCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isPending: PropTypes.bool,
};

OrganisationsCardList.defaultProps = {
  data: undefined,
  isLoading: false,
  isPending: false,
};

export default OrganisationsCardList;
