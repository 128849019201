import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import UserRole from '../../enums/UserRole';
import Select from '../Select';

const RoleSelect = (props) => {
  const { className, onChange, organisationType, value, ...rest } = props;

  const { t } = useTranslation();

  const roleOptions = useMemo(
    () => [
      {
        label: t('Admin'),
        value: UserRole.Admin,
      },
      {
        label: t('Driver'),
        value: UserRole.Driver,
      },
      {
        label: t('Operations Manager'),
        value: UserRole.OperationsManager,
      },
      {
        label: t('Hub Manager'),
        value: UserRole.HubManager,
      },
      {
        label: t('Crew'),
        value: UserRole.Crew,
      },
    ],
    [t],
  );

  return (
    <Select
      className={className}
      options={roleOptions}
      placeholder={t('Role')}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

RoleSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  organisationType: PropTypes.string,
  value: PropTypes.string,
};

RoleSelect.defaultProps = {
  className: '',
  onChange: undefined,
  organisationType: undefined,
  value: undefined,
};

export default RoleSelect;
