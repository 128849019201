import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';

import useBorderBoxSize from '../../hooks/useBorderBoxSize';
import Icon from '../Icon';
import LoadingSpinner from '../LoadingSpinner';
import SelectVariant from './SelectVariant';

const sizesMap = {
  m: 'h-10 pl-3',
  s: 'h-8 pl-2.5',
};

const variantsMap = {
  [SelectVariant.Default]: {
    input:
      'flex w-full cursor-text items-center justify-between gap-3 truncate rounded-md border py-2.5 text-sm text-primary-dark placeholder:text-grey-700 focus:border-primary-yellow disabled:cursor-default disabled:bg-grey-200 border-grey-500',
    clearIcon:
      'absolute top-1/2 -translate-y-1/2 text-primary-dark h-3 w-3 right-10',
  },
  [SelectVariant.Filter]: {
    input:
      'cursor-pointer border outline-none text-primary-dark placeholder:text-grey-700 w-fit rounded-lg h-8 text-xs truncate min-w-full',
    inputInactive: 'border-grey-300 focus:border-grey-700',
    inputActive:
      'bg-ui-blue-light border-transparent focus:border-ui-blue text-ui-blue font-semibold disabled:cursor-not-allowed disabled:bg-ui-blue-light disabled:text-ui-blue',
    clearIcon: 'text-ui-blue h-2.5 w-2.5 right-3',
    leftIcon: 'w-4 h-4',
  },
};

const SelectInput = forwardRef((props, ref) => {
  const {
    'data-test': dataTest,
    disabled,
    error,
    flatRight,
    icon,
    iconClassname,
    id,
    isLoading,
    isOpen,
    isSearchable,
    minWidth,
    name,
    onBlur,
    onChange,
    onChevronClick,
    onClear,
    onClick,
    placeholder,
    readOnly,
    renderedInputValue,
    selectId,
    showClear,
    size,
    value,
    variant,
  } = props;

  const hiddenSpanRef = useRef(null);
  const [width, setWidth] = useState(minWidth);
  const internalRef = useRef(null);

  const hiddenSpanValue = renderedInputValue || placeholder || '';
  const showChevron =
    !readOnly &&
    (variant === SelectVariant.Default ||
      (variant === SelectVariant.Filter && !value && !showClear));
  const showLeftIcon = icon && variant === SelectVariant.Filter;

  const paddingLeft = useMemo(() => {
    if (showLeftIcon) {
      return 'pl-[35px]';
    }

    return undefined;
  }, [showLeftIcon]);

  const paddingRight = useMemo(() => {
    if ((showClear || isLoading) && showChevron) {
      return 'pr-[54px]';
    }
    if (showClear || isLoading || showChevron) {
      return 'pr-[30px]';
    }

    return 'pr-3';
  }, [showClear, isLoading, showChevron]);

  const [setSpanBlockRef, spanBorderBoxSize] = useBorderBoxSize();

  useEffect(() => {
    if (variant !== SelectVariant.Filter) {
      return;
    }

    const inlineSize = Math.round(spanBorderBoxSize?.inlineSize);
    if (
      inlineSize >= minWidth &&
      inlineSize === hiddenSpanRef?.current?.offsetWidth &&
      width !== inlineSize
    ) {
      // this calculation is required for initial loading, when font load changes the inlineSize.
      // as width is updated only if value changes, we need to manually update width if this case

      setWidth(inlineSize);
    }
  }, [minWidth, spanBorderBoxSize?.inlineSize, variant, width]);

  useLayoutEffect(() => {
    if (isLoading || hiddenSpanValue) {
      setWidth(Math.max(hiddenSpanRef?.current?.offsetWidth || 0, minWidth));
    } else {
      setWidth(minWidth);
    }
  }, [hiddenSpanValue, isLoading, minWidth]);

  return (
    <div className={cn(disabled && 'opacity-40')}>
      <input
        style={{
          width:
            // offset of 1px is needed for "truncate" class to not hide text that fits
            variant === SelectVariant.Filter ? `${width + 1}px` : undefined,
        }}
        autoComplete="off"
        className={cn(
          variantsMap[variant]?.input,
          value
            ? variantsMap[variant]?.inputActive
            : variantsMap[variant]?.inputInactive,
          error && 'border-ui-red',
          flatRight && 'rounded-r-none border-r-0',
          paddingLeft,
          paddingRight,
          sizesMap[size],
          isSearchable && 'read-only:bg-grey-200 read-only:text-primary-dark',
          'max-w-full',
        )}
        data-test={dataTest}
        data-test-value={value}
        data-test-loading={isLoading}
        disabled={disabled}
        tabIndex={0}
        id={id}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly || !isSearchable}
        ref={mergeRefs([ref, internalRef])}
        value={renderedInputValue}
        title={renderedInputValue}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        data-select-id={selectId}
      />
      {isLoading && (
        <div
          className={cn(
            'pointer-events-none absolute top-1/2 h-4 w-4 -translate-y-1/2 text-grey-500',
            (showClear || isLoading) && showChevron
              ? 'right-[30px]'
              : 'right-[11px]',
          )}
        >
          <LoadingSpinner className="h-4 w-4" />
        </div>
      )}
      {showClear && !readOnly && !isLoading && (
        <>
          <span
            className={cn(
              'cursor-pointer',
              variant === SelectVariant.Filter &&
                'p-[3px] absolute top-1/2 -translate-y-1/2 right-2',
            )}
            onClick={(e) => {
              if (disabled) {
                return;
              }
              onClear(e);
            }}
            tabIndex={-1}
          >
            <Icon
              className={cn(
                variantsMap[variant]?.clearIcon,
                disabled && 'pointer-events-none',
              )}
              icon="close"
            />
          </span>
          {showChevron && (
            <span className="pointer-events-none absolute right-[30px] top-1/2 h-5 w-[1px] -translate-y-1/2 rounded-[1px] bg-grey-500" />
          )}
        </>
      )}
      {showChevron && (
        <div className="absolute bottom-0 right-[11px] top-0 flex items-center justify-center">
          <button
            className={cn('cursor-pointer')}
            disabled={disabled}
            type="button"
            tabIndex={-1}
            onClick={() => {
              onChevronClick();
            }}
          >
            {variant === SelectVariant.Default && (
              <Icon
                className={cn('h-2.5 w-2.5 cursor-pointer')}
                icon={isOpen ? 'chevronBoldUp' : 'chevronBoldDown'}
              />
            )}
            {variant === SelectVariant.Filter && (
              <Icon
                className={cn(
                  'h-2.5 w-2.5 cursor-pointer',
                  isOpen && 'text-grey-700',
                  !isOpen && 'text-grey-500',
                )}
                icon={isOpen ? 'chevronUp' : 'chevronDown'}
              />
            )}
          </button>
        </div>
      )}

      {showLeftIcon && (
        <Icon
          className={cn(
            'left-[11px] pointer-events-none absolute top-1/2 -translate-y-1/2',
            iconClassname,
          )}
          icon={icon}
        />
      )}

      <span
        ref={(el) => {
          hiddenSpanRef.current = el;
          setSpanBlockRef(el);
        }}
        className={cn(
          '!h-0 !border-transparent !min-w-0 overflow-hidden whitespace-pre absolute invisible',
          variantsMap[variant]?.input,
          value
            ? variantsMap[variant]?.inputActive
            : variantsMap[variant]?.inputInactive,
          paddingLeft,
          paddingRight,
          sizesMap[size],
        )}
      >
        {renderedInputValue || placeholder}
      </span>
    </div>
  );
});

SelectInput.propTypes = {
  'data-test': PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  flatRight: PropTypes.bool,
  id: PropTypes.string,
  isSearchable: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderedInputValue: PropTypes.string,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
  showClear: PropTypes.bool,
  onChevronClick: PropTypes.func,
  onClear: PropTypes.func,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconClassname: PropTypes.string,
  minWidth: PropTypes.number,
  selectId: PropTypes.string,
};

SelectInput.defaultProps = {
  'data-test': undefined,
  disabled: false,
  error: '',
  flatRight: false,
  id: '',
  isSearchable: true,
  name: undefined,
  onBlur: () => {},
  onChange: () => {},
  placeholder: '',
  readOnly: false,
  size: 'm',
  value: undefined,
  renderedInputValue: undefined,
  isLoading: false,
  variant: SelectVariant.Default,
  showClear: false,
  onChevronClick: undefined,
  onClear: undefined,
  isOpen: false,
  onClick: undefined,
  icon: undefined,
  iconClassname: undefined,
  minWidth: 30,
  selectId: undefined,
};

export default SelectInput;
