import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BlockNavigation from '../../../components/BlockNavigation';
import Button from '../../../components/Button';
import LinkButton from '../../../components/LinkButton';
import Tooltip from '../../../components/Tooltip';
import Breakpoint from '../../../enums/Breakpoint';
import useWindowSize from '../../../hooks/useWindowSize';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import UnitStatusUpdate from '../../shared/UnitStatusUpdate';
import ExitEditModeModal from './ExitEditModeModal';
import FailedUnitsModal from './FailedUnitsModal';

const UnitBulkEdit = ({ isBulkFiltersApplied }) => {
  const { t } = useTranslation();
  const { disableSelection, selectedItems } = useListSelection();
  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const [isExitEditModeModalOpen, setIsExitEditModeModalOpen] = useState(false);
  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState(false);
  const [failedUnitIds, setFailedUnitIds] = useState([]);
  const [successUnitIdsLength, setSuccessUnitIdsLength] = useState(0);

  const isBulkEditEnabled = isBulkFiltersApplied && selectedItems.length > 0;

  return (
    <>
      <BlockNavigation
        shouldBlock={selectedItems.length > 0}
        ignoreIdenticalPathname
        requiredSearchParams={['startDate', 'status']}
      >
        {(blocker) => (
          <ExitEditModeModal
            isOpen={blocker.state === 'blocked'}
            onClose={() => {
              // only reset blocker if the prompt was opened for the location transition, not by clicking on the "done" button
              if (blocker.state === 'blocked') {
                blocker.reset();
              }
            }}
            onExitClick={() => {
              // only proceed with transition if the prompt opened for the location transition, not by clicking on the "done" button
              if (blocker.state === 'blocked') {
                blocker.proceed();
              }

              disableSelection();
            }}
          />
        )}
      </BlockNavigation>
      <ExitEditModeModal
        isOpen={isExitEditModeModalOpen}
        onClose={() => {
          setIsExitEditModeModalOpen(false);
        }}
        onExitClick={() => {
          setIsExitEditModeModalOpen(false);
          disableSelection();
        }}
      />
      <UnitStatusUpdate
        isOpen={isBulkEditDrawerOpen && isBulkEditEnabled}
        units={selectedItems}
        onClose={() => setIsBulkEditDrawerOpen(false)}
        isBulkUpdate
        onBulkUpdateFail={({ failedIds, successIdsLength }) => {
          setIsBulkEditDrawerOpen(false);
          setFailedUnitIds(failedIds);
          setSuccessUnitIdsLength(successIdsLength);
        }}
      />
      <FailedUnitsModal
        isOpen={failedUnitIds.length > 0}
        onClose={() => {
          setFailedUnitIds([]);
          setSuccessUnitIdsLength(0);
        }}
        failedIds={failedUnitIds}
        unitsUpdated={successUnitIdsLength}
      />
      <div className="grid grid-cols-[max-content_auto_auto] gap-4 items-center py-4 lg:py-1 px-3 lg:px-4 bg-ui-blue-light rounded-lg">
        <div className="text-sm font-medium">{t('Bulk actions')}</div>
        <div className="lg:col-span-1 col-span-3 order-last lg:order-none">
          <Tooltip
            className="flex lg:inline-flex"
            text={t(
              'Date and status filters must be active and at least one unit selected',
            )}
            hidden={isBulkEditEnabled}
          >
            <Button
              size={isDesktop ? 's' : 'm'}
              isFullWidth={!isDesktop}
              disabled={!isBulkEditEnabled}
              text={`${t('Update Unit Status')}${
                selectedItems.length ? ` (${selectedItems.length})` : ''
              }`}
              variant="outlineBlue"
              icon="updateUnitStatus"
              iconSize="m"
              onClick={() => setIsBulkEditDrawerOpen(true)}
            />
          </Tooltip>
        </div>

        <div className="col-span-2 lg:col-span-1 text-right">
          <LinkButton
            variant="dark"
            text={t('Exit Bulk Edit Mode')}
            onClick={() =>
              selectedItems.length > 0
                ? setIsExitEditModeModalOpen(true)
                : disableSelection()
            }
          />
        </div>
      </div>
    </>
  );
};

UnitBulkEdit.propTypes = {
  isBulkFiltersApplied: PropTypes.bool,
};

UnitBulkEdit.defaultProps = {
  isBulkFiltersApplied: false,
};

export default UnitBulkEdit;
