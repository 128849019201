import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import Modal from '../../components/Modal';

const DeleteRoutingConfirmationModal = ({
  handleDelete,
  isOpen,
  message,
  onClose,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={<p>{message}</p>}
      cancelButtonText={t('Cancel')}
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Delete')}
            variant="solidRed"
            onClick={() => {
              onClose();
              handleDelete();
            }}
          />
        </div>
      }
      header={<span>{title}</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

DeleteRoutingConfirmationModal.propTypes = {
  handleDelete: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
};

DeleteRoutingConfirmationModal.defaultProps = {
  handleDelete: () => {},
  title: '',
  message: '',
};

export default DeleteRoutingConfirmationModal;
