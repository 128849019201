import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import HubType from '../enums/HubType';
import useFetch from '../lib/api/hooks/useFetch';
import { usePackageScanningSettings } from '../providers/PackageScanningSettingsProvider';

const useScanFeedProgress = (date) => {
  const { isCentral } = usePackageScanningSettings();

  const { fetch } = useFetch();
  const url = `/units/hub-scan-progress?date=${moment(
    date || new Date(),
  ).format('YYYY-MM-DD')}&type=${isCentral ? HubType.Central : HubType.Local}`;
  const { data, refetch } = useQuery({
    queryKey: [url],

    queryFn: async () => {
      const response = await fetch(url, {
        method: 'GET',
      });
      return response.json();
    },
  });

  return {
    getFeedData: refetch,
    scanned: data?.data?.progress?.scanned || 0,
    total: data?.data?.progress?.total || 0,
  };
};

export default useScanFeedProgress;
