const TasksSearchBy = {
  Code: 'code',
  UnitCode: 'unitCode',
  TourCode: 'tourNumber',
  Address: 'address',
  Email: 'email',
  PhoneNumber: 'phoneNumber',
  EndCustomerName: 'endCustomerName',
};

export default TasksSearchBy;
