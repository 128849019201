import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

const CompleteScanSessionModal = ({ isOpen, onActionClick, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {t(
            'Please confirm that you are the last remaining one with the scanning.',
          )}
        </p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Confirm')}
            variant="solidBlack"
            onClick={() => {
              onClose();
              onActionClick();
            }}
          />
        </div>
      }
      header={<span>{t('Complete Scan Session')}</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

CompleteScanSessionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompleteScanSessionModal;
