import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import InfiniteScrollSelect from '../../../components/InfiniteScrollSelect';
import SelectVariant from '../../../components/Select/SelectVariant';

const CarriersSelect = (props) => {
  const {
    carrierId,
    className,
    dropdownClassname,
    flatRight,
    onChange,
    prependOptions,
    required,
    value,
    variant,
  } = props;

  const { t } = useTranslation();
  const [mountedValue, setMountedValue] = useState(undefined);
  // enable mounted query only if it's not carrier
  const enableMountedQuery = !!mountedValue && mountedValue !== 'null';

  useMount(() => {
    setMountedValue(value);
  });

  const transformOptionFn = useCallback(
    (subcarrier) => ({
      label: `${subcarrier.shortCode} (${subcarrier.name})`,
      value: subcarrier.id,
    }),
    [],
  );

  return (
    <InfiniteScrollSelect
      singleItemUrl="/subcarriers/"
      url={`/carriers/${carrierId}/subcarriers`}
      className={className}
      id="carriers-select"
      value={value}
      onChange={onChange}
      prependOptions={prependOptions}
      placeholder={t('Carrier')}
      transformOptionFn={transformOptionFn}
      isMountedValueQueryEnabled={enableMountedQuery}
      data-test="carriers-select"
      variant={variant}
      dropdownClassname={dropdownClassname}
      flatRight={flatRight}
      required={required}
    />
  );
};

CarriersSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
  carrierId: PropTypes.string.isRequired,
  prependOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  dropdownClassname: PropTypes.string,
  flatRight: PropTypes.bool,
  required: PropTypes.bool,
};

CarriersSelect.defaultProps = {
  className: undefined,
  onChange: undefined,
  value: undefined,
  variant: undefined,
  prependOptions: undefined,
  dropdownClassname: undefined,
  flatRight: undefined,
  required: false,
};

export default CarriersSelect;
