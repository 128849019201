import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import OnOffSwitch from '../../../components/OnOffSwitch';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import OrganisationDetailsSettingsCard from '../OrganisationDetailsSettingsCard';

const OrganisationDetailsVolumeBundling = (props) => {
  const { disabled, organisation } = props;
  const { toastSuccess } = useCustomToast();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [isEnabled, setIsEnabled] = useState(organisation.bundling);

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (checked) => {
      const response = await fetch(`/clients/${organisation.id}`, {
        body: {
          address: organisation.address,
          bundling: checked,
          name: organisation.name,
          publicName: organisation.publicName,
        },
        method: 'PATCH',
      });
      return response.json();
    },
    onError: (error) => toastFetchError(error),
    onSuccess: (response) => {
      if (response.data?.bundling === true) {
        toastSuccess(t('Volume bundling enabled.'));
      } else {
        toastSuccess(t('Volume bundling disabled.'));
      }

      setIsEnabled(response.data.bundling);
      queryClient.invalidateQueries([`/clients/`, organisation.id]);
      setIsModalOpen(false);
    },
  });

  return (
    <>
      <Modal
        body={
          <p>
            {isChecked
              ? t('This action will enable volume bundling functionality.')
              : t(
                  'This action will disable volume bundling functionality.',
                )}{' '}
            {t('Are you sure you wish to proceed?')}
          </p>
        }
        footer={
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              data-test="modal-button-action"
              text={isChecked ? t('Enable') : t('Disable')}
              variant={isChecked ? 'solidBlue' : 'solidRed'}
              onClick={() => mutation.mutate(isChecked)}
            />
          </div>
        }
        header={<span>{t('Warning')}</span>}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <OrganisationDetailsSettingsCard
        text={
          <div className="flex items-center gap-2">
            <Icon className="h-4 w-4 text-grey-500" icon="infoFilled" />
            <span>
              {t('Tours can combine deliveries from multiple clients')}
            </span>
          </div>
        }
        title={t('Volume Bundling')}
      >
        <OnOffSwitch
          checked={isEnabled}
          disabled={disabled}
          onChange={(checked) => {
            setIsModalOpen(true);
            setIsChecked(checked);
          }}
        />
      </OrganisationDetailsSettingsCard>
    </>
  );
};

OrganisationDetailsVolumeBundling.propTypes = {
  disabled: PropTypes.bool,
  organisation: PropTypes.shape({
    address: PropTypes.string,
    bundling: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    publicName: PropTypes.string,
  }),
};

OrganisationDetailsVolumeBundling.defaultProps = {
  disabled: false,
  organisation: undefined,
};

export default OrganisationDetailsVolumeBundling;
