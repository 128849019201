import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import OnOffSwitch from '../../../components/OnOffSwitch';

const PlatformFeaturesSwitch = ({
  code,
  isDisabled,
  isInitiallyChecked,
  onDisable,
  onEnable,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(isInitiallyChecked);

  return (
    <>
      <Modal
        body={
          <p>
            {isChecked
              ? t(
                  'Enabling this action will activate specific platform feature.',
                )
              : t(
                  'Enabling this action will deactivate specific platform feature.',
                )}
          </p>
        }
        footer={
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              data-test="modal-button-action"
              text={isChecked ? t('Enable') : t('Disable')}
              variant={isChecked ? 'solidBlue' : 'solidRed'}
              onClick={() => {
                if (isChecked) {
                  onEnable(code);
                } else {
                  onDisable(code);
                }
                setIsModalOpen(false);
              }}
            />
          </div>
        }
        header={
          <span>
            {isChecked
              ? t('Activate Platform Feature?')
              : t('Deactivate Platform Feature?')}
          </span>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <OnOffSwitch
        checked={isInitiallyChecked}
        disabled={isDisabled}
        onChange={(checked) => {
          setIsModalOpen(true);
          setIsChecked(checked);
        }}
      />
    </>
  );
};

PlatformFeaturesSwitch.propTypes = {
  code: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isInitiallyChecked: PropTypes.bool.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
};

PlatformFeaturesSwitch.defaultProps = {
  isDisabled: false,
};

export default PlatformFeaturesSwitch;
