/* eslint-disable react/no-unstable-nested-components */
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import NewTable, { CellAlignment } from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import Breakpoint from '../../../enums/Breakpoint';
import OrganisationType from '../../../enums/OrganisationType';
import UserRole from '../../../enums/UserRole';
import useWindowSize from '../../../hooks/useWindowSize';
import { useUser } from '../../../providers/UserProvider';
import MemberRating from '../../organisations/MemberRating';
import MembersFilters from '../../organisations/MembersFilters';
import MemberStatus from '../../organisations/MemberStatus';
import MemberBadge from '../MemberBadge';

const MembersTable = (props) => {
  const { isClient, isSuperadmin, organisation, total, ...rest } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const { isSubcarrierUser } = useUser();

  const { width } = useWindowSize();
  const isSmallScreen = width < Breakpoint.XXL;

  const columns = useMemo(() => {
    const allColumns = [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        id: 'name',
        header: t('Name'),
        size: 250,
        cell: (info) => (
          <span>
            {info.row.original.firstName} {info.row.original.lastName}{' '}
            {info.row.original.subcarrier && !isSubcarrierUser && (
              <Label
                size="xs"
                text={info.row.original?.subcarrier?.shortCode}
                variant="primary"
              />
            )}
          </span>
        ),
      },
      {
        accessorKey: 'role',
        header: t('Role'),
        cell: (info) => (
          <MemberBadge
            memberRole={info.getValue()}
            size="s"
            shortened={isSmallScreen}
          />
        ),
      },
      {
        accessorKey: 'hub',
        header: t('Hub'),
        cell: (info) => {
          const isAdmin = info.row.original?.role === UserRole.Admin;
          if (isAdmin) {
            return t('All');
          }
          return info.getValue()?.name || '/';
        },
      },
      {
        accessorKey: 'email',
        header: t('E-mail'),
        size: 350,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'phoneNumber',
        header: t('Phone Number'),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'status',
        header: t('Status'),
        cell: (info) => <MemberStatus status={info.getValue()} />,
      },
      {
        accessorKey: 'lastActiveAt',
        header: t('Last Active'),
        cell: (info) =>
          info.getValue() ? moment(info.getValue()).format('DD.MM.YYYY') : '/',
      },
      {
        accessorKey: 'rating',
        header: t('Rating'),
        cell: (info) => {
          if (info.getValue()) {
            return <MemberRating rating={info.getValue()} />;
          }

          return <span className="text-grey-500">N/A</span>;
        },
      },
      {
        id: 'actions',
        cell: (info) => (
          <Tooltip
            className="flex"
            placement="top"
            size="sm"
            text={t('Member Details')}
          >
            <IconButton
              as={Link}
              icon="pageView"
              size="xs"
              state={{
                backLink: location.pathname + location.search,
              }}
              to={{
                pathname: `/members/${info.row.original?.id}`,
              }}
              variant="ghostBlack"
            />
          </Tooltip>
        ),
        meta: {
          align: CellAlignment.Center,
        },
        size: 50,
        enableResizing: false,
      },
    ];

    return allColumns.filter((column) => {
      if (isClient) {
        return (
          column.accessorKey !== 'rating' &&
          column.accessorKey !== 'role' &&
          column.accessorKey !== 'hub'
        );
      }

      if (isSuperadmin) {
        return column.accessorKey !== 'hub' && column.accessorKey !== 'rating';
      }

      if (!isSuperadmin) {
        return column.accessorKey !== 'phoneNumber';
      }

      return column;
    });
  }, [
    isClient,
    isSmallScreen,
    isSubcarrierUser,
    isSuperadmin,
    location.pathname,
    location.search,
    t,
  ]);

  return (
    <>
      <MembersFilters
        organisation={organisation}
        count={total}
        isClient={isClient}
        isSuperadmin={isSuperadmin}
      />
      <NewTable
        columns={columns}
        inlineScroll
        minColSize={50}
        data-test="members-table"
        {...rest}
      />
    </>
  );
};

MembersTable.propTypes = {
  isClient: PropTypes.bool,
  isSuperadmin: PropTypes.bool,
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.oneOf([
      OrganisationType.Carrier,
      OrganisationType.Superadmin,
    ]),
  }),
  total: PropTypes.number,
};

MembersTable.defaultProps = {
  isClient: false,
  isSuperadmin: false,
  organisation: undefined,
  total: undefined,
};

export default MembersTable;
