import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import CreateClientMemberForm from '../../features/clients/CreateClientMemberForm';
import useFetch from '../../lib/api/hooks/useFetch';

const NewClientMember = () => {
  const { id } = useParams();

  const { fetch } = useFetch();
  const { data, error, isError } = useQuery({
    queryKey: [`/clients/`, id],

    queryFn: async () => {
      const response = await fetch(`/clients/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const organisation = data?.data || null;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (redirect) {
      navigate(`/clients/${id}/members`, {
        replace: true,
      });
    }
  }, [id, navigate, redirect]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (!organisation) {
    return null;
  }

  const title = t('New Member');
  return (
    <Page>
      <Titlebar
        icon={<Icon className="h-5 w-5 text-ui-blue" icon="userAdd" />}
        textPrimary={title}
        titleRightContent={
          <Button
            className="w-full lg:w-fit"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={() => navigate(`/clients/${id}/members`)}
          />
        }
      />
      <Page.Content centerContent variant="grey">
        <div className="w-full max-w-[480px]">
          <Card className="px-4 py-5 sm:p-8 sm:pt-6">
            <CreateClientMemberForm
              organisationId={organisation.id}
              organisationName={organisation.name}
              onCancel={() => {
                navigate(`/clients/${id}/members`);
              }}
              onSuccess={() => {
                setRedirect(true);
              }}
            />
          </Card>
        </div>
      </Page.Content>
    </Page>
  );
};

export default NewClientMember;
