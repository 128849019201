import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import ReactModal from 'react-modal';

import FloatingContainerProvider from '../../providers/FloatingContainerProvider';
import IconButton from '../IconButton';

const Drawer = forwardRef((props, ref) => {
  const {
    body,
    bodyClassname,
    bodyPadding,
    'data-test': dataTest,
    footer,
    header,
    isOpen,
    onClose,
    onOpen,
    shouldCloseOnOverlayClick,
    showBackButton,
    transparentBackdrop,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    // FloatingContainerProvider must be used to reset the floating container to global
    <FloatingContainerProvider>
      <ReactModal
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        bodyOpenClassName="overflow-hidden pr-[var(--scrollbar-width)]"
        className={cn(
          // h-full must be set for mobile height to work correctly
          'flex w-full h-full sm:max-w-[412px] flex-col justify-between bg-white p-0 text-primary-dark',
          !transparentBackdrop && 'shadow-elevation-400',
        )}
        data={{ test: dataTest }}
        isOpen={isOpen}
        overlayClassName={cn(
          // h-full must be set for mobile height to work correctly
          'flex h-full justify-end bg-opacity-40 p-0 transition-opacity duration-300',
          !transparentBackdrop && 'bg-primary-dark',
        )}
        portalClassName={
          isOpen ? 'fixed inset-0 z-30 overflow-y-auto overflow-x-hidden' : ''
        }
        onAfterOpen={onOpen}
        onRequestClose={onClose}
      >
        <div>
          <div className="flex items-center justify-between text-base font-medium px-4 py-4">
            <div className="flex capitalize gap-2 items-center">
              {showBackButton && (
                <IconButton
                  size="xs"
                  iconSize="s"
                  variant="ghostGrey"
                  icon="arrowBack"
                  onClick={onClose}
                />
              )}
              {header}
            </div>
            <IconButton
              aria-label="Close"
              data-test="modal-close"
              icon="close"
              iconSize="xs"
              size="xs"
              variant="ghostGrey"
              onClick={onClose}
            />
          </div>
          <div className="h-[1px] bg-grey-200" />
        </div>
        <div
          ref={ref}
          className={cn(
            'flex-1 overflow-y-scroll',
            bodyClassname,
            bodyPadding && 'px-4 py-6',
          )}
        >
          {body}
        </div>
        {footer && (
          <>
            <div className="h-[1px] bg-grey-200" />
            <div className="p-4 w-full">{footer}</div>
          </>
        )}
      </ReactModal>
    </FloatingContainerProvider>
  );
});

Drawer.propTypes = {
  'data-test': PropTypes.string,
  isOpen: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  body: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node,
  transparentBackdrop: PropTypes.bool,
  showBackButton: PropTypes.bool,
  bodyPadding: PropTypes.bool,
  bodyClassname: PropTypes.string,
};

Drawer.defaultProps = {
  'data-test': undefined,
  isOpen: false,
  shouldCloseOnOverlayClick: true,
  footer: undefined,
  onClose: () => {},
  onOpen: () => {},
  transparentBackdrop: false,
  showBackButton: false,
  bodyPadding: true,
  bodyClassname: '',
};

export default Drawer;
