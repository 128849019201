import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';

const DeleteShiftModal = ({ isHubShift, isOpen, onClose, shiftId }) => {
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { t } = useTranslation();

  const deleteShift = useMutation({
    mutationFn: () =>
      fetch(`/shifts/${shiftId}`, {
        method: 'DELETE',
      }),
    onError: (error) => toastFetchError(error),
    onSuccess: () => {
      toastSuccess(t('Shift is successfully deleted.'));
      queryClient.invalidateQueries({
        queryKey: [`/carriers/${user.carrier.id}/shifts`],
      });
    },
  });

  return (
    <Modal
      body={
        <p>
          {isHubShift
            ? t(
                'Note that this action is permanent. Hubs will no longer be able to operate within this time slot and will be automatically assigned to the Carrier Shifts.',
              )
            : t(
                'Note that this action is permanent. Hub will not be able to operate within this time slot.',
              )}
        </p>
      }
      cancelButtonText={t('Cancel')}
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Delete')}
            variant="solidRed"
            onClick={() => {
              onClose();
              deleteShift.mutate();
            }}
          />
        </div>
      }
      header={
        <span>
          {t('Delete {{shiftType}} Shift?', {
            shiftType: isHubShift ? 'Hub' : 'Carrier',
          })}
        </span>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

DeleteShiftModal.propTypes = {
  isHubShift: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shiftId: PropTypes.string.isRequired,
};

DeleteShiftModal.defaultProps = {
  isHubShift: false,
};

export default DeleteShiftModal;
