import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../../components/DatePicker';
import DatePickerVariant from '../../../components/DatePicker/DatePickerVariant';
import LinkButton from '../../../components/LinkButton';
import Select from '../../../components/Select';
import SelectVariant from '../../../components/Select/SelectVariant';
import UserRole from '../../../enums/UserRole';
import useGroceriesToursPageSearchParams from '../../../hooks/useGroceriesToursPageSearchParams';
import { useIsRole, useUser } from '../../../providers/UserProvider';
import CarriersSelect from '../../shared/CarriersSelect';
import HubSelect from '../../shared/HubSelect';
import TourStatusSelect from '../TourStatusSelect';

const GroceriesToursFilters = (props) => {
  const { count } = props;
  const { t } = useTranslation();
  const {
    dateQuery,
    hubIdQuery,
    searchByQuery,
    searchParams,
    searchQuery,
    setSearchParams,
    shiftQuery,
    statusQuery,
    subcarrierQuery,
  } = useGroceriesToursPageSearchParams();

  const isSubcarrierFitlerVisible = useIsRole([
    UserRole.Admin,
    UserRole.HubManager,
    UserRole.OperationsManager,
  ]);
  const { user } = useUser();
  const organisation = user?.carrier;

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      searchParams.set('page', '1');
      if (!queryValue) {
        searchParams.delete(queryKey);
      } else {
        searchParams.set(queryKey, queryValue);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const shiftOptions = [1, 2, 3].map((shift) => ({
    value: String(shift),
    label: `${t('Shift')} ${shift}`,
  }));

  return (
    <div className="flex w-full flex-col flex-wrap justify-between gap-3 lg:flex-row relative px-4 py-2 border-grey-300 rounded-t-md border items-center">
      <div className="flex flex-col flex-wrap gap-2 lg:flex-row lg:items-center">
        <div className="flex justify-center items-center">
          <div className="text-xs w-[104px] leading-4" title={count}>
            <div className="text-grey-700 align-middle block">
              {t('Tour count')}:{' '}
            </div>
            <div className="font-medium text-primary-dark align-middle">
              {count}
            </div>
          </div>
        </div>
        <DatePicker
          variant={DatePickerVariant.Filter}
          dateFormat="dd.MM.yyyy"
          isClearable
          name="date"
          placeholderText={t('Date')}
          required
          value={dateQuery}
          onChange={(date) => {
            searchParams.set('page', '1');
            if (!date) {
              searchParams.delete('date');
            } else {
              const formattedDate = moment(date).format('YYYY-MM-DD');
              searchParams.set('date', formattedDate);
            }
            setSearchParams(searchParams);
          }}
        />
        <Select
          variant={SelectVariant.Filter}
          className="lg:max-w-[100px]"
          placeholder={t('Shift')}
          options={shiftOptions}
          value={shiftQuery}
          onChange={(value) => onSelectChange('shiftNumber', value)}
        />

        {isSubcarrierFitlerVisible && (
          <CarriersSelect
            variant={SelectVariant.Filter}
            prependOptions={[
              {
                label: organisation.name,
                value: 'null',
              },
            ]}
            carrierId={organisation.id}
            className="max-w-[220px]"
            value={subcarrierQuery}
            onChange={(value) => {
              searchParams.set('page', '1');
              if (!value) {
                searchParams.delete('subcarrierId');
              } else if (value === organisation?.id) {
                searchParams.set('subcarrierId', 'null');
              } else {
                searchParams.set('subcarrierId', value);
              }
              setSearchParams(searchParams);
            }}
          />
        )}

        <TourStatusSelect
          variant={SelectVariant.Filter}
          value={statusQuery}
          onChange={(value) => {
            onSelectChange('tourStatus', value);
          }}
        />

        <HubSelect
          variant={SelectVariant.Filter}
          value={hubIdQuery}
          onChange={(hubId) => {
            onSelectChange('hubId', hubId);
          }}
        />

        <LinkButton
          className="mx-1"
          text={t('Clear All')}
          onClick={() => {
            const newParams = new URLSearchParams({
              page: 1,
              searchBy: searchByQuery,
              search: searchQuery,
            });

            setSearchParams(newParams);
          }}
        />
      </div>
    </div>
  );
};

GroceriesToursFilters.propTypes = {
  count: PropTypes.number,
};

GroceriesToursFilters.defaultProps = {
  count: undefined,
};

export default GroceriesToursFilters;
