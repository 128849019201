import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import ErrorBoundary from '../app/ErrorBoundary';
import ScannerType from '../enums/ScannerType';
import UserRole from '../enums/UserRole';
import Workflow from '../enums/Workflow';
import AlternativeDeliveryOptions from '../features/clients/AlternativeDeliveryOptions';
import ResponsiveLayout from '../layout/ResponsiveLayout';
import VerticalLayout from '../layout/VerticalLayout';
import { BrowserPrintProvider } from '../lib/browser-print/hooks/useBrowserPrint';
import ActivateUser from '../pages/activate-user/ActivateUser';
import Activity from '../pages/activity/Activity';
import ClientDetails from '../pages/clients/ClientDetails';
import ClientMembers from '../pages/clients/ClientMembers';
import ClientPlatformFeatures from '../pages/clients/ClientPlatformFeatures';
import EditClient from '../pages/clients/EditClient';
import NewClientMember from '../pages/clients/NewClientMember';
import GroceryTourDetails from '../pages/groceries/GroceryTourDetails';
import GroceryTours from '../pages/groceries/GroceryTours';
import EditHub from '../pages/hubs/EditHub';
import HubDetails from '../pages/hubs/HubDetails';
import Login from '../pages/login/Login';
import EditMember from '../pages/members/EditMember';
import MemberDetails from '../pages/members/MemberDetails';
import MyAccount from '../pages/my-account/MyAccount';
import MyOrganisation from '../pages/my-organisation/MyOrganisation';
import MyOrganisationCustomers from '../pages/my-organisation/MyOrganisationCustomers';
import MyOrganisationEdit from '../pages/my-organisation/MyOrganisationEdit';
import MyOrganisationEditShift from '../pages/my-organisation/MyOrganisationEditShift';
import MyOrganisationEditSubcarrier from '../pages/my-organisation/MyOrganisationEditSubcarrier';
import MyOrganisationHubs from '../pages/my-organisation/MyOrganisationHubs';
import MyOrganisationMembers from '../pages/my-organisation/MyOrganisationMembers';
import MyOrganisationNewCustomer from '../pages/my-organisation/MyOrganisationNewCustomer';
import MyOrganisationNewHub from '../pages/my-organisation/MyOrganisationNewHub';
import MyOrganisationNewMember from '../pages/my-organisation/MyOrganisationNewMember';
import MyOrganisationNewShift from '../pages/my-organisation/MyOrganisationNewShift';
import MyOrganisationNewSubcarrier from '../pages/my-organisation/MyOrganisationNewSubcarrier';
import MyOrganisationPlatformFeatures from '../pages/my-organisation/MyOrganisationPlatformFeatures';
import MyOrganisationRoutings from '../pages/my-organisation/MyOrganisationRoutings';
import MyOrganisationShifts from '../pages/my-organisation/MyOrganisationShifts';
import MyOrganisationSubcarriers from '../pages/my-organisation/MyOrganisationSubcarriers';
import NotFound from '../pages/not-found/NotFound';
import EditOrganisation from '../pages/organisations/EditOrganisation';
import NewOrganisation from '../pages/organisations/NewOrganisation';
import OrganisationCustomers from '../pages/organisations/OrganisationCustomers';
import OrganisationDetails from '../pages/organisations/OrganisationDetails';
import OrganisationHubs from '../pages/organisations/OrganisationHubs';
import OrganisationMembers from '../pages/organisations/OrganisationMembers';
import OrganisationNewMember from '../pages/organisations/OrganisationNewMember';
import OrganisationPlatformFeatures from '../pages/organisations/OrganisationPlatformFeatures';
import OrganisationRoutings from '../pages/organisations/OrganisationRoutings';
import Organisations from '../pages/organisations/Organisations';
import OrganisationShifts from '../pages/organisations/OrganisationShifts';
import OrganisationSubcarriers from '../pages/organisations/OrganisationSubcarriers';
import PackageScanning from '../pages/package-scanning/PackageScanning';
import ScannerConfiguration from '../pages/package-scanning/ScannerConfiguration';
import VirtualPrinterPackageScanning from '../pages/package-scanning/VirtualPrinterPackageScanning';
import ForgotPassword from '../pages/reset-password/ForgotPassword';
import ForgotPasswordSuccess from '../pages/reset-password/ForgotPasswordSuccess';
import ResetPasswordSuccess from '../pages/reset-password/ResetPasswordSuccess';
import UserResetPassword from '../pages/reset-password/UserResetPassword';
import NewSuperadmin from '../pages/superadmin/NewSuperadmin';
import Superadmins from '../pages/superadmin/Superadmins';
import Tasks from '../pages/tasks/Tasks';
import FixedTours from '../pages/tours/FixedTours';
import TourDetails from '../pages/tours/TourDetails';
import Units from '../pages/units/Units';
import { PackageScanningSettingsProvider } from '../providers/PackageScanningSettingsProvider';
import GuestApp from './GuestApp';
import PrivateRoute from './PrivateRoute';
import ProtectedApp from './ProtectedApp';
import Root from './Root';
import RootRedirect from './RootRedirect';

const createRoutes = () =>
  createRoutesFromElements(
    // Root is the default element, wraps the whole application, and it contains all the global providers
    // errorElement is used as a global error boundary and all uncaught errors are propagated to sentry
    <Route element={<Root />} errorElement={<ErrorBoundary />} path="/">
      <Route element={<ProtectedApp />}>
        <Route element={<RootRedirect />} index />

        <Route element={<ResponsiveLayout />}>
          <Route
            element={
              <PrivateRoute
                allowedWorkflows={[Workflow.Operations]}
                roles={[
                  UserRole.Admin,
                  UserRole.OperationsManager,
                  UserRole.HubManager,
                ]}
              >
                <Tasks />
              </PrivateRoute>
            }
            path="/tasks"
          />

          <Route
            element={
              <PrivateRoute
                allowedWorkflows={[Workflow.Operations]}
                roles={[
                  UserRole.Admin,
                  UserRole.OperationsManager,
                  UserRole.HubManager,
                ]}
              >
                <Units />
              </PrivateRoute>
            }
            path="/units"
          />

          <Route
            element={
              <PrivateRoute
                allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                roles={[UserRole.OperationsManager, UserRole.HubManager]}
              >
                <Activity />
              </PrivateRoute>
            }
            path="/activity"
          />

          <Route path="/tours">
            <Route element={<Navigate replace to="fixed" />} index />
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Operations]}
                  roles={[
                    UserRole.Admin,
                    UserRole.OperationsManager,
                    UserRole.HubManager,
                    UserRole.Customer,
                  ]}
                >
                  <FixedTours />
                </PrivateRoute>
              }
              path="fixed"
            />
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Operations]}
                  roles={[
                    UserRole.Admin,
                    UserRole.OperationsManager,
                    UserRole.HubManager,
                    UserRole.Customer,
                  ]}
                >
                  <TourDetails />
                </PrivateRoute>
              }
              path=":id"
            />
          </Route>

          <Route path="/grocery-tours">
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Groceries]}
                  roles={[
                    UserRole.Admin,
                    UserRole.OperationsManager,
                    UserRole.HubManager,
                    UserRole.Customer,
                  ]}
                >
                  <GroceryTours />
                </PrivateRoute>
              }
              index
            />
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Groceries]}
                  roles={[
                    UserRole.Admin,
                    UserRole.OperationsManager,
                    UserRole.HubManager,
                    UserRole.Customer,
                  ]}
                >
                  <GroceryTourDetails />
                </PrivateRoute>
              }
              path=":id"
            />
          </Route>

          <Route
            element={
              <PrivateRoute
                allowedWorkflows={[Workflow.Operations]}
                roles={[UserRole.Superadmin]}
              />
            }
            path="/organisations"
          >
            <Route element={<Organisations />} index />
            <Route element={<NewOrganisation />} path="new" />
            <Route path=":id">
              <Route element={<OrganisationDetails />} index />
              <Route element={<EditOrganisation />} path="edit" />
              <Route element={<OrganisationHubs />} path="hubs" />
              <Route element={<OrganisationShifts />} path="shifts" />
              <Route element={<OrganisationRoutings />} path="routing" />
              <Route path="members">
                <Route element={<OrganisationMembers />} index />
                <Route element={<OrganisationNewMember />} path="new" />
              </Route>
              <Route element={<OrganisationSubcarriers />} path="subcarriers" />
              <Route element={<OrganisationCustomers />} path="clients" />
              <Route
                element={<OrganisationPlatformFeatures />}
                path="platform-features"
              />
            </Route>
          </Route>

          <Route
            element={
              <PrivateRoute
                allowedWorkflows={[Workflow.Operations]}
                roles={[UserRole.Superadmin]}
              />
            }
            path="/superadmins"
          >
            <Route element={<Superadmins />} index />
            <Route element={<NewSuperadmin />} path="new" />
          </Route>

          <Route path="clients">
            <Route path=":id">
              <Route
                element={
                  <PrivateRoute
                    allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                    roles={[
                      UserRole.Superadmin,
                      UserRole.Admin,
                      UserRole.OperationsManager,
                      UserRole.HubManager,
                    ]}
                  >
                    <ClientDetails />
                  </PrivateRoute>
                }
                index
              />
              <Route
                element={
                  <PrivateRoute
                    allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                    roles={[UserRole.Admin]}
                  >
                    <EditClient />
                  </PrivateRoute>
                }
                path="edit"
              />
              <Route path="members">
                <Route
                  element={
                    <PrivateRoute
                      allowedWorkflows={[
                        Workflow.Groceries,
                        Workflow.Operations,
                      ]}
                      roles={[
                        UserRole.Superadmin,
                        UserRole.Admin,
                        UserRole.OperationsManager,
                        UserRole.HubManager,
                      ]}
                    >
                      <ClientMembers />
                    </PrivateRoute>
                  }
                  index
                />
                <Route
                  element={
                    <PrivateRoute
                      allowedWorkflows={[
                        Workflow.Groceries,
                        Workflow.Operations,
                      ]}
                      roles={[UserRole.Admin]}
                    >
                      <NewClientMember />
                    </PrivateRoute>
                  }
                  path="new"
                />
              </Route>
              <Route
                element={
                  <PrivateRoute
                    allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                    roles={[UserRole.Superadmin, UserRole.Admin]}
                  >
                    <ClientPlatformFeatures />
                  </PrivateRoute>
                }
                path="platform-features"
              />
              <Route
                element={
                  <PrivateRoute
                    allowedWorkflows={[Workflow.Operations]}
                    roles={[UserRole.Admin]}
                  >
                    <AlternativeDeliveryOptions />
                  </PrivateRoute>
                }
                path="delivery-options"
              />
            </Route>
          </Route>

          <Route
            element={
              <PrivateRoute
                allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                roles={[
                  UserRole.Admin,
                  UserRole.OperationsManager,
                  UserRole.HubManager,
                ]}
              />
            }
            path="/my-organisation"
          >
            <Route element={<MyOrganisation />} index />
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                  roles={[UserRole.Admin]}
                >
                  <MyOrganisationEdit />
                </PrivateRoute>
              }
              path="edit"
            />
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Operations]}
                  roles={[UserRole.Admin]}
                />
              }
              path="shifts"
            >
              <Route element={<MyOrganisationShifts />} index />
              <Route element={<MyOrganisationNewShift />} path="new" />
              <Route path=":id">
                <Route element={<MyOrganisationEditShift />} path="edit" />
              </Route>
            </Route>

            <Route path="hubs">
              <Route element={<MyOrganisationHubs />} index />
              <Route
                element={
                  <PrivateRoute
                    allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                    roles={[UserRole.Admin]}
                  >
                    <MyOrganisationNewHub />
                  </PrivateRoute>
                }
                path="new"
              />
            </Route>
            <Route path="members">
              <Route element={<MyOrganisationMembers />} index />
              <Route element={<MyOrganisationNewMember />} path="new" />
            </Route>
            <Route path="subcarriers">
              <Route element={<MyOrganisationSubcarriers />} index />
              <Route element={<MyOrganisationNewSubcarrier />} path="new" />
              <Route path=":id">
                <Route element={<MyOrganisationEditSubcarrier />} path="edit" />
              </Route>
            </Route>
            <Route path="clients">
              <Route element={<MyOrganisationCustomers />} index />
              <Route element={<MyOrganisationNewCustomer />} path="new" />
            </Route>
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                  roles={[UserRole.Admin]}
                >
                  <MyOrganisationPlatformFeatures />
                </PrivateRoute>
              }
              path="platform-features"
            />
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                  roles={[UserRole.Admin]}
                >
                  <MyOrganisationRoutings />
                </PrivateRoute>
              }
              path="routing"
            />
          </Route>

          <Route path="/hubs/:id">
            <Route element={<HubDetails />} index />
            <Route
              element={
                <PrivateRoute
                  allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                  roles={[UserRole.Admin]}
                >
                  <EditHub />
                </PrivateRoute>
              }
              path="edit"
            />
          </Route>

          <Route path="/members">
            <Route path=":id">
              <Route
                element={
                  <PrivateRoute
                    allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                    roles={[
                      UserRole.Superadmin,
                      UserRole.Admin,
                      UserRole.OperationsManager,
                      UserRole.HubManager,
                    ]}
                  >
                    <MemberDetails />
                  </PrivateRoute>
                }
                index
              />
              <Route
                element={
                  <PrivateRoute
                    allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                    roles={[
                      UserRole.Superadmin,
                      UserRole.Admin,
                      UserRole.OperationsManager,
                      UserRole.HubManager,
                    ]}
                  >
                    <EditMember />
                  </PrivateRoute>
                }
                path="edit"
              />
            </Route>
          </Route>
          <Route
            element={
              <PrivateRoute
                allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                roles={[
                  UserRole.Superadmin,
                  UserRole.Admin,
                  UserRole.OperationsManager,
                  UserRole.HubManager,
                  UserRole.Crew,
                  UserRole.Customer,
                ]}
              >
                <MyAccount />
              </PrivateRoute>
            }
            path="/my-account"
          />

          <Route
            element={
              <PrivateRoute
                allowedWorkflows={[Workflow.Groceries, Workflow.Operations]}
                roles={[
                  UserRole.Superadmin,
                  UserRole.Admin,
                  UserRole.OperationsManager,
                  UserRole.HubManager,
                  UserRole.Crew,
                  UserRole.Customer,
                ]}
              >
                <NotFound />
              </PrivateRoute>
            }
            path="*"
          />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedWorkflows={[Workflow.Operations]}
              roles={[UserRole.Crew]}
            />
          }
        >
          <Route
            element={
              <PackageScanningSettingsProvider>
                <BrowserPrintProvider>
                  <VerticalLayout variant="white" />
                </BrowserPrintProvider>
              </PackageScanningSettingsProvider>
            }
            path="/package-scanning"
          >
            <Route element={<PackageScanning />} index />
            <Route
              element={<VirtualPrinterPackageScanning />}
              path="virtual-printer"
            />
            <Route
              element={
                <ScannerConfiguration scannerType={ScannerType.InateckBCST60} />
              }
              path="scanner-configuration/inateck"
            />
            <Route
              element={
                <ScannerConfiguration scannerType={ScannerType.SymbolLS2208} />
              }
              path="scanner-configuration/symbol"
            />
          </Route>
        </Route>
      </Route>

      <Route element={<GuestApp />}>
        <Route element={<Login />} path="/login" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route
          element={<ForgotPasswordSuccess />}
          path="/forgot-password-success"
        />
        <Route
          element={<UserResetPassword />}
          path="/user/reset-password/:token"
        />
        <Route
          element={<ResetPasswordSuccess />}
          path="/reset-password-success"
        />
      </Route>

      <Route element={<ActivateUser />} path="/user/activate/:token" />
    </Route>,
  );

export default createRoutes;
