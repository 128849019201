import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../../../components/Select';
import SelectVariant from '../../../components/Select/SelectVariant';
import DeliveryOption from '../../../enums/DeliveryOption';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import FailureReason from '../../../enums/FailureReason';
import PostponeReason from '../../../enums/PostponeReason';
import ReturnReason from '../../../enums/ReturnReason';
import UnitProgress from '../../../enums/UnitProgress';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';
import useDeliveryUnitStatusTranslations from '../../../hooks/useDeliveryUnitStatusTranslations';
import useFailureReasonTranslations from '../../../hooks/useFailureReasonTranslations';
import usePostponeReasonTranslations from '../../../hooks/usePostponeReasonTranslations';
import useReturnReasonTranslations from '../../../hooks/useReturnReasonTranslations';
import useUnitProgress from '../../../hooks/useUnitProgress';
import useFetch from '../../../lib/api/hooks/useFetch';

// this is a reduced list of failure reasons that are used exclusively in the
// UnitProgressAndStatusSelect this is a requirement for frontend because larger
// list of failureReason is used for postponement status and for backward
// compatibility of some reasons (e.g. other)
const failureReasons = [
  FailureReason.NoOneAtTheAddress,
  FailureReason.NameNotOnTheDoorbell,
  FailureReason.StoreClosed,
  FailureReason.AddressNotFound,
];

const UnitProgressAndStatusSelect = (props) => {
  const {
    allowProgressOnlySelection,
    className,
    deliveryOption,
    disabled,
    failureReason,
    isSingleDropdown,
    onChange,
    postponeReason,
    returnReason,
    unitProgress,
    unitStatus,
    variant,
  } = props;

  const value = useMemo(() => {
    if (!unitProgress) {
      return undefined;
    }

    if (unitStatus === DeliveryTaskUnitStatus.Delivered && deliveryOption) {
      return `${unitProgress}-${unitStatus}-${deliveryOption}`;
    }

    if (unitStatus === DeliveryTaskUnitStatus.Retry && failureReason) {
      return `${unitProgress}-${unitStatus}-${failureReason}`;
    }

    if (unitStatus === DeliveryTaskUnitStatus.Postponed && postponeReason) {
      return `${unitProgress}-${unitStatus}-${postponeReason}`;
    }

    if (unitStatus === DeliveryTaskUnitStatus.ReturnToClient && returnReason) {
      return `${unitProgress}-${unitStatus}-${returnReason}`;
    }

    if (unitProgress && unitStatus) {
      return `${unitProgress}-${unitStatus}`;
    }

    return unitProgress;
  }, [
    deliveryOption,
    failureReason,
    postponeReason,
    returnReason,
    unitProgress,
    unitStatus,
  ]);

  const { t } = useTranslation();
  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();
  const { nameTranslations: deliveryOptionTranslations } =
    useDeliveryOptionsTranslations();
  const failureReasonTranslations = useFailureReasonTranslations();
  const returnReasonTranslations = useReturnReasonTranslations();
  const postponeReasonTranslations = usePostponeReasonTranslations();

  const { fetch } = useFetch();

  const { data } = useQuery({
    queryKey: ['/delivery-options'],
    queryFn: async () => {
      const response = await fetch('/delivery-options', {
        method: 'GET',
      });
      return response.json();
    },
  });

  const deliveryOptions = useMemo(
    () => data?.data.map((item) => item.code) || [],
    [data],
  );

  const unitProgressMap = useUnitProgress();
  const options = useMemo(
    () =>
      Object.values(unitProgressMap).map((currentUnitProgress) => {
        const {
          label,
          labelIcon,
          labelIconClassname,
          statuses,
          value: progressValue,
          ...rest
        } = currentUnitProgress;

        const statusOptions = statuses.map((currentUnitStatus) => {
          if (currentUnitStatus === DeliveryTaskUnitStatus.Delivered) {
            return {
              value: `${progressValue}-${DeliveryTaskUnitStatus.Delivered}`,
              groupLabel:
                deliveryUnitStatusTranslations[
                  DeliveryTaskUnitStatus.Delivered
                ],
              backButtonLabel: label,
              inputIcon: labelIcon,
              inputIconClassname: labelIconClassname,
              isGroupSelectable: true,
              options: deliveryOptions.map((currentDeliveryOption) => ({
                value: `${progressValue}-${DeliveryTaskUnitStatus.Delivered}-${currentDeliveryOption}`,
                label: deliveryOptionTranslations[currentDeliveryOption],
                inputIcon: labelIcon,
                inputIconClassname: labelIconClassname,
              })),
            };
          }

          if (currentUnitStatus === DeliveryTaskUnitStatus.Retry) {
            return {
              value: `${progressValue}-${DeliveryTaskUnitStatus.Retry}`,
              groupLabel:
                deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Retry],
              backButtonLabel: label,
              isGroupSelectable: true,
              inputIcon: labelIcon,
              inputIconClassname: labelIconClassname,
              options: failureReasons.map((currentFailureReason) => ({
                value: `${progressValue}-${DeliveryTaskUnitStatus.Retry}-${currentFailureReason}`,
                label: failureReasonTranslations[currentFailureReason],
                inputIcon: labelIcon,
                inputIconClassname: labelIconClassname,
              })),
            };
          }

          if (currentUnitStatus === DeliveryTaskUnitStatus.Postponed) {
            return {
              value: `${progressValue}-${DeliveryTaskUnitStatus.Postponed}`,
              groupLabel:
                deliveryUnitStatusTranslations[
                  DeliveryTaskUnitStatus.Postponed
                ],
              backButtonLabel: label,
              inputIcon: labelIcon,
              inputIconClassname: labelIconClassname,
              isGroupSelectable: true,
              options: Object.values(PostponeReason).map(
                (currentPostponeReason) => ({
                  value: `${progressValue}-${DeliveryTaskUnitStatus.Postponed}-${currentPostponeReason}`,
                  label: postponeReasonTranslations[currentPostponeReason],
                  inputIcon: labelIcon,
                  inputIconClassname: labelIconClassname,
                }),
              ),
            };
          }

          if (currentUnitStatus === DeliveryTaskUnitStatus.ReturnToClient) {
            return {
              value: `${progressValue}-${DeliveryTaskUnitStatus.ReturnToClient}`,
              groupLabel:
                deliveryUnitStatusTranslations[
                  DeliveryTaskUnitStatus.ReturnToClient
                ],
              backButtonLabel: label,
              inputIcon: labelIcon,
              inputIconClassname: labelIconClassname,
              isGroupSelectable: true,
              options: Object.values(ReturnReason).map(
                (currentReturnReason) => ({
                  value: `${progressValue}-${DeliveryTaskUnitStatus.ReturnToClient}-${currentReturnReason}`,
                  label: returnReasonTranslations[currentReturnReason],
                  inputIcon: labelIcon,
                  inputIconClassname: labelIconClassname,
                }),
              ),
            };
          }

          return {
            value: `${progressValue}-${currentUnitStatus}`,
            label: deliveryUnitStatusTranslations[currentUnitStatus],
            inputIcon: labelIcon,
            inputIconClassname: labelIconClassname,
          };
        });

        return {
          value: progressValue,
          groupLabel: label,
          labelIcon,
          labelIconClassname,
          inputIcon: labelIcon,
          inputIconClassname: labelIconClassname,
          ...rest,
          backButtonLabel: t('Status groups'),
          isGroupSelectable: allowProgressOnlySelection,
          options: statusOptions,
        };
      }),
    [
      allowProgressOnlySelection,
      deliveryOptionTranslations,
      deliveryOptions,
      deliveryUnitStatusTranslations,
      failureReasonTranslations,
      postponeReasonTranslations,
      returnReasonTranslations,
      t,
      unitProgressMap,
    ],
  );

  return (
    <Select
      variant={variant}
      data-test="unit-progress-select"
      id="unit-progress-select"
      options={options}
      placeholder={t('Unit status')}
      value={value}
      onChange={onChange}
      className={className}
      disabled={disabled}
      isSingleDropdown={isSingleDropdown}
    />
  );
};

UnitProgressAndStatusSelect.propTypes = {
  unitStatus: PropTypes.oneOf([...Object.values(DeliveryTaskUnitStatus), '']),
  deliveryOption: PropTypes.oneOf([...Object.values(DeliveryOption), '']),
  failureReason: PropTypes.oneOf([...Object.values(FailureReason), '']),
  postponeReason: PropTypes.oneOf([...Object.values(PostponeReason), '']),
  returnReason: PropTypes.oneOf([...Object.values(ReturnReason), '']),
  unitProgress: PropTypes.oneOf([...Object.values(UnitProgress), '']),
  className: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
  disabled: PropTypes.bool,
  allowProgressOnlySelection: PropTypes.bool,
  isSingleDropdown: PropTypes.bool,
};

UnitProgressAndStatusSelect.defaultProps = {
  unitStatus: undefined,
  unitProgress: undefined,
  deliveryOption: undefined,
  failureReason: undefined,
  postponeReason: undefined,
  returnReason: undefined,
  className: undefined,
  onChange: undefined,
  variant: undefined,
  disabled: false,
  allowProgressOnlySelection: true,
  isSingleDropdown: false,
};

export default UnitProgressAndStatusSelect;
