import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import Button from '../../../components/Button';
import DebouncedSearch from '../../../components/DebouncedSearch';
import Drawer from '../../../components/Drawer';
import RoleSelect from '../../../components/RoleSelect';
import StatusSelect from '../../../components/StatusSelect';
import UserRole from '../../../enums/UserRole';
import parseSearchParams from '../../../helpers/parseSearchParams';
import useMembersPageSearchParams from '../../../hooks/useMembersPageSearchParams';
import { useIsRole, useUser } from '../../../providers/UserProvider';
import CarriersSelect from '../../shared/CarriersSelect';
import HubSelect from '../../shared/HubSelect';

const MembersFiltersDrawer = (props) => {
  const { isOpen, onClose, organisation } = props;
  const {
    currentSortType,
    searchParams: currentSearchParams,
    searchQuery,
    setSearchParams,
  } = useMembersPageSearchParams();
  const prevIsOpen = usePrevious(isOpen);
  const { t } = useTranslation();
  const [params, setParams] = useState(parseSearchParams(currentSearchParams));
  const { user } = useUser();

  const isLoggedUserSuperadmin = useIsRole(UserRole.Superadmin);
  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setParams(parseSearchParams(currentSearchParams));
    }
  }, [currentSearchParams, isOpen, prevIsOpen]);

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      const newParams = { ...params };

      newParams.page = 1;
      if (!queryValue) {
        delete newParams[queryKey];
      } else {
        newParams[queryKey] = queryValue;
      }
      setParams(newParams);
    },
    [params],
  );

  const [searchInputKey, setSearchInputKey] = useState(new Date().toString());

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      header={t('Filters')}
      footer={
        <Button
          data-test="modal-button-action"
          text={t('Apply')}
          variant="solidBlue"
          isFullWidth
          onClick={() => {
            const urlParams = new URLSearchParams(params);
            setSearchParams(urlParams);
            onClose();
          }}
        />
      }
      body={
        <div className="flex flex-col gap-4">
          <DebouncedSearch
            key={searchInputKey}
            debounceMs={0}
            placeholder={`${t('Find member')}...`}
            searchValue={searchQuery}
            onSearch={(newSearchValue) => {
              const newParams = { ...params };
              if (newSearchValue === searchQuery) {
                return;
              }
              newParams.page = 1;
              if (!newSearchValue) {
                delete newParams.search;
              } else {
                newParams.search = newSearchValue?.trim();
              }
              setParams(newParams);
            }}
          />
          <div className="border-b border-grey-200" />
          <CarriersSelect
            prependOptions={[
              {
                label: organisation?.name,
                value: 'null',
              },
            ]}
            carrierId={
              isLoggedUserSuperadmin ? organisation?.id : user.carrier.id
            }
            value={params.subcarrierId}
            onChange={(subcarrierId) => {
              onSelectChange('subcarrierId', subcarrierId);
            }}
          />
          <HubSelect
            value={params.hubId}
            organisationId={
              isLoggedUserSuperadmin ? organisation?.id : undefined
            }
            onChange={(hubId) => {
              onSelectChange('hubId', hubId);
            }}
          />
          <RoleSelect
            value={params.role}
            onChange={(role) => {
              onSelectChange('role', role);
            }}
          />
          <StatusSelect
            value={params.status}
            onChange={(status) => {
              onSelectChange('status', status);
            }}
          />
          <Button
            isFullWidth
            variant="outlineBlue"
            text={t('Clear All')}
            onClick={() => {
              setSearchInputKey(new Date().toString());
              const newParams = {
                page: 1,
                sortType: currentSortType,
                searchBy: params.searchBy,
                search: '',
              };

              setParams(newParams);
            }}
          />
        </div>
      }
    />
  );
};

MembersFiltersDrawer.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

MembersFiltersDrawer.defaultProps = {
  organisation: undefined,
  isOpen: false,
  onClose: undefined,
};

export default MembersFiltersDrawer;
