import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

const WrongHubModal = (props) => {
  const { correctHubName, isOpen, onClose } = props;
  const { t } = useTranslation();
  return (
    <Modal
      data-test="wrong-hub-modal"
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      header={<span>{t('Unit at the wrong Hub')}</span>}
      body={
        <div>
          {t('This unit belongs to the {{hubName}} Hub.', {
            hubName: correctHubName,
          })}{' '}
          {t('Press OK to continue.')}
        </div>
      }
      footer={
        <div>
          <Button
            isFullWidth
            text={t('OK')}
            variant="solidRed"
            onClick={onClose}
            data-test="modal-button-action"
          />
        </div>
      }
    />
  );
};

WrongHubModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  correctHubName: PropTypes.string,
};

WrongHubModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  correctHubName: '',
};

export default WrongHubModal;
