import cn from 'classnames';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useKeyPressEvent, usePrevious } from 'react-use';

import useDropdownList from '../../hooks/useDropdownList';
import Dropdown from './Dropdown';
import flattenOptions from './flattenOptions';
import { optionPropType } from './Option';
import SelectContext from './SelectContext';
import SelectInput from './SelectInput';
import SelectVariant from './SelectVariant';
import SingleDropdown from './SingleDropdown';
import useIndexedOptions from './useIndexedOptions';
import useMaxOptionsDepth from './useMaxOptionsDepth';

const Select = (props) => {
  const {
    className,
    'data-test': dataTest,
    disabled,
    dropdownClassname,
    dropdownMinWidth,
    error,
    flatRight,
    id,
    injectAllOptionForSelectableGroups,
    isLoading,
    isSearchable,
    isSingleDropdown,
    mode,
    name,
    noOptionsMessage,
    onBlur,
    onBottomReached,
    onChange,
    onSearch,
    options,
    placeholder,
    placement,
    readOnly,
    required,
    size,
    useLocalSearch,
    value,
    variant,
  } = props;

  const inputRef = useRef(undefined);
  const selectId = useId();

  const [popperReferenceElement, setPopperReferenceElement] = useState(null);
  const [inputFilterValue, setInputFilterValue] = useState('');
  const previousInputFilterValue = usePrevious(inputFilterValue);

  // used as memory for rendering current option, without interfering with prop options
  const [fallbackOption, setFallbackOption] = useState(undefined);

  const indexedOptions = useIndexedOptions(
    options,
    injectAllOptionForSelectableGroups,
  );

  const maxDepth = useMaxOptionsDepth(indexedOptions);
  const fuseOptionsList = useMemo(() => {
    const levels = [...Array(maxDepth)].map((_, index) => index + 1);
    const keys = levels
      .map((level) => {
        const prefix = 'options.'.repeat(level - 1);

        return [`${prefix}value`, `${prefix}label`, `${prefix}dropdownLabel`];
      })
      .flat(1);
    return new Fuse(indexedOptions, {
      keys,
      // lower is better (0 matches perfect string, 1 matches anything)
      threshold: 0.2,
      // can be anywhere in the string
      ignoreLocation: true,
    });
  }, [indexedOptions, maxDepth]);
  const indexedFilteredOptions = useMemo(() => {
    if (inputFilterValue === '' || !useLocalSearch) {
      return indexedOptions;
    }
    return fuseOptionsList
      .search(inputFilterValue)
      .map((result) => result.item);
  }, [fuseOptionsList, indexedOptions, inputFilterValue, useLocalSearch]);

  const flattenedIndexedFilteredOptions = useMemo(
    () => flattenOptions(indexedFilteredOptions),
    [indexedFilteredOptions],
  );

  const onSelect = useCallback(
    (option) => {
      onChange(option.value);
      setInputFilterValue('');
      onSearch('');
      onBlur();
      setFallbackOption(option);
    },
    [onBlur, onChange, onSearch],
  );

  const selectedOption = useMemo(() => {
    const foundOption = flattenedIndexedFilteredOptions
      // reverse is to support "all" options that have the same value as the parent group
      // as the reversed array will first list the "all" item, instead of parent group item
      .reverse()
      .find((option) => option?.value === value);

    if (foundOption) {
      return foundOption;
    }

    const hasMatchingFallbackOption = value === fallbackOption?.value;

    if (hasMatchingFallbackOption) {
      return fallbackOption;
    }

    return undefined;
  }, [fallbackOption, flattenedIndexedFilteredOptions, value]);

  const renderInputValue = useCallback(() => {
    if (inputFilterValue) {
      return inputFilterValue;
    }

    return selectedOption?.groupLabel || selectedOption?.label || '';
  }, [inputFilterValue, selectedOption?.groupLabel, selectedOption?.label]);

  const {
    activePath,
    close: closeDropdown,
    getOptionProps,
    getRootProps,
    isOpen,
    open,
    setActivePath,
    toggle,
  } = useDropdownList({
    onSelect,
    options: flattenedIndexedFilteredOptions,
    // preserve active path for infinite lists
    preserveActivePath: inputFilterValue === '',
    defaultActivePath:
      selectedOption?.path?.length > 1
        ? selectedOption.path.slice(0, -1)
        : undefined,
  });

  useKeyPressEvent('Escape', () => {
    setInputFilterValue('');
    onSearch('');
    if (isOpen) {
      toggle();
    }
  });

  const close = useCallback(() => {
    setInputFilterValue('');
    onSearch('');
    closeDropdown();
    onBlur();
  }, [onBlur, closeDropdown, onSearch]);

  const handleBlur = (e) => {
    // not needed for single dropdown as levels are handled per click
    if (isSingleDropdown) {
      return;
    }

    const isClickOnInput = e.relatedTarget?.closest(
      `input[data-select-id="${selectId}"]`,
    );
    const isClickOnInnerSelectElements = e.relatedTarget?.closest(
      `[data-select-group="${selectId}"]`,
    );

    if (isClickOnInnerSelectElements || isClickOnInput) {
      return;
    }

    setInputFilterValue('');
    onSearch('');
    close();
    onBlur();
  };

  const focus = useCallback(() => {
    if (inputRef?.current?.focus) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (
      inputFilterValue !== '' &&
      previousInputFilterValue !== inputFilterValue &&
      !isOpen
    ) {
      open();
    }
  }, [inputFilterValue, isOpen, open, previousInputFilterValue]);

  const showClear =
    inputFilterValue === '' &&
    selectedOption?.value !== undefined &&
    selectedOption?.value !== '' &&
    !required;

  const selectContextValue = useMemo(
    () => ({
      activePath,
      selectedOption,
      getOptionProps,
      value,
      onSelect,
      setActivePath,
      focus,
      close,
      inputFilterValue,
      previousInputFilterValue,
      selectId,
    }),
    [
      activePath,
      selectedOption,
      getOptionProps,
      value,
      onSelect,
      setActivePath,
      focus,
      close,
      inputFilterValue,
      previousInputFilterValue,
      selectId,
    ],
  );

  const renderedInputValue = renderInputValue();

  return (
    <SelectContext.Provider value={selectContextValue}>
      <div
        className={cn(
          'relative',
          className,
          variant === SelectVariant.Filter && 'w-fit',
        )}
        {...getRootProps()}
      >
        <div className="relative" ref={setPopperReferenceElement}>
          <SelectInput
            selectId={selectId}
            icon={selectedOption?.inputIcon}
            iconClassname={selectedOption?.inputIconClassname}
            data-test={dataTest}
            disabled={disabled}
            error={error}
            flatRight={flatRight}
            id={id}
            renderedInputValue={renderedInputValue}
            isLoading={isLoading}
            isOpen={isOpen}
            isSearchable={isSearchable && !isSingleDropdown}
            name={name}
            ref={inputRef}
            onChange={(e) => {
              // deleting characters of filtered value
              if (e.nativeEvent.data === null && !inputFilterValue) {
                setInputFilterValue('');
                onSearch('');
                return;
              }

              // first input
              if (inputFilterValue === '') {
                setInputFilterValue(e.nativeEvent.data);
                onSearch(e.nativeEvent.data);
                return;
              }

              setInputFilterValue(e.target.value);
              onSearch(e.target.value);
            }}
            onChevronClick={() => {
              toggle();
              if (!isOpen) {
                inputRef?.current?.focus();
              }
            }}
            onClear={() => {
              setFallbackOption(undefined);
              onChange(null);
              onBlur();
              setInputFilterValue('');
              onSearch('');
              if (isOpen) {
                toggle();
              }
            }}
            onClick={toggle}
            placeholder={placeholder}
            readOnly={readOnly}
            showClear={showClear}
            size={size}
            value={value}
            variant={variant}
            onBlur={handleBlur}
          />
        </div>
        {isOpen && !readOnly && !isSingleDropdown && (
          <Dropdown
            onBottomReached={onBottomReached}
            className={dropdownClassname}
            minWidth={dropdownMinWidth}
            noOptionsMessage={noOptionsMessage}
            options={indexedFilteredOptions}
            placement={placement}
            popperReferenceElement={popperReferenceElement}
            mode={mode}
          />
        )}
        {isOpen && !readOnly && isSingleDropdown && (
          <SingleDropdown
            onBottomReached={onBottomReached}
            className={dropdownClassname}
            minWidth={dropdownMinWidth}
            noOptionsMessage={noOptionsMessage}
            options={indexedFilteredOptions}
            placement={placement}
            popperReferenceElement={popperReferenceElement}
          />
        )}
      </div>
    </SelectContext.Provider>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  'data-test': PropTypes.string,
  disabled: PropTypes.bool,
  dropdownClassname: PropTypes.string,
  dropdownMinWidth: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  flatRight: PropTypes.bool,
  id: PropTypes.string,
  isSearchable: PropTypes.bool,
  name: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionPropType),
  placeholder: PropTypes.string,
  placement: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onBottomReached: PropTypes.func,
  onSearch: PropTypes.func,
  useLocalSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  mode: PropTypes.oneOf(['cascade', 'vertical']),
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
  isSingleDropdown: PropTypes.bool,
  injectAllOptionForSelectableGroups: PropTypes.bool,
};

Select.defaultProps = {
  className: '',
  'data-test': undefined,
  disabled: false,
  dropdownClassname: '',
  dropdownMinWidth: undefined,
  error: '',
  flatRight: false,
  id: '',
  isSearchable: true,
  name: undefined,
  noOptionsMessage: undefined,
  onBlur: () => {},
  onChange: () => {},
  onSearch: () => {},
  options: [],
  placeholder: '',
  placement: undefined,
  readOnly: false,
  required: false,
  size: 'm',
  value: undefined,
  onBottomReached: () => {},
  useLocalSearch: true,
  isLoading: false,
  mode: 'cascade',
  variant: SelectVariant.Default,
  isSingleDropdown: false,
  injectAllOptionForSelectableGroups: true,
};

export default Select;
