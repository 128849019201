import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import LinkButton from '../../../components/LinkButton';
import RoleSelect from '../../../components/RoleSelect';
import SelectVariant from '../../../components/Select/SelectVariant';
import StatusSelect from '../../../components/StatusSelect';
import UserRole from '../../../enums/UserRole';
import useMembersPageSearchParams from '../../../hooks/useMembersPageSearchParams';
import { useIsRole, useUser } from '../../../providers/UserProvider';
import CarriersSelect from '../../shared/CarriersSelect';
import HubSelect from '../../shared/HubSelect';
import MembersSortDropdown from '../../shared/MembersSortDropdown';

const MembersFilters = (props) => {
  const { count, isClient, isSuperadmin, organisation } = props;

  const isLoggedUserSuperadmin = useIsRole(UserRole.Superadmin);
  const { user } = useUser();
  const { t } = useTranslation();
  const {
    currentSortType,
    hubIdQuery,
    roleQuery,
    searchParams,
    searchQuery,
    setSearchParams,
    statusQuery,
    subcarrierIdQuery,
  } = useMembersPageSearchParams();

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      searchParams.set('page', '1');
      if (!queryValue) {
        searchParams.delete(queryKey);
      } else {
        searchParams.set(queryKey, queryValue);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    <div className="flex w-full flex-col flex-wrap justify-between gap-3 lg:flex-row relative px-4 py-2 border-grey-300 rounded-t-md border items-center">
      <div className="flex flex-col flex-wrap gap-2 lg:flex-row lg:items-center">
        <div className="flex justify-center items-center">
          <div className="text-xs w-[104px] truncate" title={count}>
            <span className="text-grey-700 align-middle block">
              {t('Member count')}:{' '}
            </span>
            <span className="font-medium text-primary-dark align-middle">
              {count}
            </span>
          </div>
        </div>
        {!isClient && !isSuperadmin && (
          <>
            <CarriersSelect
              variant={SelectVariant.Filter}
              className="max-w-[220px]"
              carrierId={
                isLoggedUserSuperadmin ? organisation?.id : user.carrier.id
              }
              prependOptions={[
                {
                  label: organisation.name,
                  value: 'null',
                },
              ]}
              value={subcarrierIdQuery}
              onChange={(subcarrierId) => {
                onSelectChange('subcarrierId', subcarrierId);
              }}
            />
            <HubSelect
              variant={SelectVariant.Filter}
              placeholder={t('Hub')}
              organisationId={
                isLoggedUserSuperadmin ? organisation?.id : undefined
              }
              value={hubIdQuery}
              onChange={(hubId) => {
                onSelectChange('hubId', hubId);
              }}
            />
            <RoleSelect
              variant={SelectVariant.Filter}
              value={roleQuery}
              onChange={(role) => {
                onSelectChange('role', role);
              }}
            />
            <StatusSelect
              variant={SelectVariant.Filter}
              value={statusQuery}
              onChange={(status) => {
                onSelectChange('status', status);
              }}
            />
            <LinkButton
              className="mx-1"
              text={t('Clear All')}
              onClick={() => {
                const newParams = new URLSearchParams({
                  page: 1,
                  sortType: currentSortType,
                  search: searchQuery,
                });

                setSearchParams(newParams);
              }}
            />
          </>
        )}
      </div>
      <div className="flex flex-col gap-4 lg:flex-row">
        <MembersSortDropdown />
      </div>
    </div>
  );
};

MembersFilters.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  count: PropTypes.number,
  isClient: PropTypes.bool,
  isSuperadmin: PropTypes.bool,
};

MembersFilters.defaultProps = {
  organisation: undefined,
  count: undefined,
  isClient: false,
  isSuperadmin: false,
};

export default MembersFilters;
