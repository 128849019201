import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Drawer from '../../../components/Drawer';
import parseSearchParams from '../../../helpers/parseSearchParams';
import useTasksPageSearchParams from '../../../hooks/useTasksPageSearchParams';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import ClientSelect from '../../shared/ClientSelect';
import CustomDateRangeDropdown from '../../shared/CustomDateRangeDropdown/CustomDateRangeDropdown';
import HubSelect from '../../shared/HubSelect';
import ShiftsSelect from '../../shared/ShiftsSelect';
import TasksSearch from '../TasksSearch';
import TasksStatusSelect from '../TasksStatusSelect';
import TasksTypeSelect from '../TasksTypeSelect';

const TasksFiltersDrawer = (props) => {
  const { isOpen, onClose } = props;
  const {
    currentSortType,
    searchParams: currentSearchParams,
    setSearchParams,
  } = useTasksPageSearchParams();
  const { selectedItems } = useListSelection();
  const isSelectionActive = selectedItems.length > 0;
  const prevIsOpen = usePrevious(isOpen);
  const { t } = useTranslation();
  const [params, setParams] = useState(parseSearchParams(currentSearchParams));

  const datepickerRef = useRef(null);

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setParams(parseSearchParams(currentSearchParams));
    }
  }, [currentSearchParams, isOpen, prevIsOpen]);

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      const newParams = { ...params };

      newParams.page = 1;
      if (!queryValue) {
        delete newParams[queryKey];
      } else {
        newParams[queryKey] = queryValue;
      }
      setParams(newParams);
    },
    [params],
  );

  const [searchInputKey, setSearchInputKey] = useState(new Date().toString());

  const customCalendarContainer = (calendarProps) => (
    <CustomDateRangeDropdown
      onTodayClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment().format('YYYY-MM-DD');
        newParams.endDate = moment().format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      onTomorrowClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment().add(1, 'days').format('YYYY-MM-DD');
        newParams.endDate = moment().add(1, 'days').format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      onYesterdayClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        newParams.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      onLastWeekClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment()
          .subtract(1, 'week')
          .startOf('week')
          .format('YYYY-MM-DD');
        newParams.endDate = moment()
          .subtract(1, 'week')
          .endOf('week')
          .format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      onThisWeekClick={() => {
        const newParams = { ...params };
        newParams.page = 1;
        newParams.startDate = moment().startOf('week').format('YYYY-MM-DD');
        newParams.endDate = moment().endOf('week').format('YYYY-MM-DD');
        setParams(newParams);
        datepickerRef?.current.setOpen(false);
      }}
      {...calendarProps}
    />
  );

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      header={t('Filters')}
      footer={
        <Button
          data-test="modal-button-action"
          text={t('Apply')}
          variant="solidBlue"
          isFullWidth
          onClick={() => {
            const urlParams = new URLSearchParams(params);
            setSearchParams(urlParams);
            onClose();
          }}
        />
      }
      body={
        <div className="flex flex-col gap-4">
          <TasksSearch
            key={searchInputKey}
            debounceMs={0}
            searchBy={params.searchBy}
            searchValue={params.search}
            onSearch={(newSearchValue) => {
              const newParams = { ...params };
              if (newSearchValue === params.search || !params.searchBy) {
                return;
              }
              newParams.page = 1;
              if (!newSearchValue) {
                delete newParams.search;
              } else {
                newParams.search = newSearchValue?.trim();
                newParams.searchBy = params.searchBy;
              }
              setParams(newParams);
            }}
            onSearchByChange={(newSearchBy) => {
              const newParams = { ...params };
              newParams.page = 1;
              newParams.searchBy = newSearchBy;
              setParams(newParams);
            }}
          />

          <div className="border-b border-grey-200" />

          <DatePicker
            disabled={isSelectionActive}
            dateFormat="dd.MM.’yy"
            isClearable
            name="date"
            placeholderText={t('Start date → End date')}
            required
            selectsRange
            startDate={params.startDate ? new Date(params.startDate) : null}
            endDate={params.endDate ? new Date(params.endDate) : null}
            onChange={([selectedStartDate, selectedEndDate]) => {
              const newParams = { ...params };
              newParams.page = 1;

              const formattedStartDate =
                selectedStartDate &&
                moment(selectedStartDate).format('YYYY-MM-DD');
              const formattedEndDate =
                selectedEndDate && moment(selectedEndDate).format('YYYY-MM-DD');

              if (selectedStartDate) {
                newParams.startDate = formattedStartDate;
                newParams.endDate = null;
                setParams(newParams);
              }

              if (selectedEndDate) {
                newParams.endDate = formattedEndDate;
                setParams(newParams);
                datepickerRef.current?.setOpen(false);
              }

              if (!selectedStartDate && !selectedEndDate) {
                delete newParams.startDate;
                delete newParams.endDate;
                setParams(newParams);
              }
            }}
            selected={params.startDate ? new Date(params.startDate) : null}
            minWidth={188}
            ref={datepickerRef}
            calendarContainer={customCalendarContainer}
            onCalendarClose={() => {
              const newParams = { ...params };
              if (newParams.startDate && !newParams.endDate) {
                setParams({
                  ...params,
                  endDate: newParams.startDate,
                });
              }
            }}
          />

          <ShiftsSelect
            value={params.shiftNumber}
            onChange={(value) => {
              const newParams = { ...params };
              newParams.page = 1;
              if (!value) {
                delete newParams.shiftNumber;
              } else {
                newParams.shiftNumber = value;
              }

              setParams(newParams);
            }}
          />

          <TasksStatusSelect
            value={params.status}
            onChange={(status) => onSelectChange('status', status)}
          />

          <TasksTypeSelect
            value={params.type}
            onChange={(type) => onSelectChange('type', type)}
          />

          <HubSelect
            disabled={isSelectionActive}
            value={params.hubId}
            onChange={(hubId) => {
              onSelectChange('hubId', hubId);
            }}
          />

          <ClientSelect
            value={params.clientId}
            onChange={(clientId) => {
              onSelectChange('clientId', clientId);
            }}
          />

          <Button
            isFullWidth
            variant="outlineBlue"
            text={t('Clear All')}
            onClick={() => {
              setSearchInputKey(new Date().toString());
              const newParams = {
                page: 1,
                sortType: currentSortType,
                searchBy: params.searchBy,
                search: '',
              };

              setParams(newParams);
            }}
          />
        </div>
      }
    />
  );
};

TasksFiltersDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

TasksFiltersDrawer.defaultProps = {
  isOpen: false,
  onClose: undefined,
};

export default TasksFiltersDrawer;
