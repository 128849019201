import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import SubcarrierForm from '../../features/subcarriers/SubcarriersForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const MyOrganisationNewSubcarrier = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { fetch } = useFetch();
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();

  const mutation = useMutation({
    mutationFn: (values) =>
      fetch('/subcarriers', {
        body: values,
        method: 'POST',
      }),
    onError: toastFetchError,
    onSuccess: () => {
      setIsFormDirty(false);
      toastSuccess(t('Subcarrier created.'));
    },
  });

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, mutation.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate('/my-organisation/subcarriers', {
        replace: true,
      });
    }
  }, [mutation.isSuccess, navigate]);

  const title = t('New Subcarrier');

  return (
    <>
      <CancelPrompt
        isOpen={blocker.state === 'blocked'}
        title={t('Cancel Creating Subcarrier?')}
        onClose={() => blocker.reset()}
        onExitClick={() => blocker.proceed()}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="userAdd" />}
          textPrimary={title}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate('/my-organisation/subcarriers')}
            />
          }
        />
        <Page.Content variant="grey">
          <Card className="m-auto mt-4 w-full max-w-[480px] px-4 py-5 sm:p-8">
            <SubcarrierForm
              isLoading={mutation.isPending}
              onCancel={() => {
                navigate('/my-organisation/subcarriers');
              }}
              onIsDirtyChange={setIsFormDirty}
              onSubmit={mutation.mutate}
            />
          </Card>
        </Page.Content>
      </Page>
    </>
  );
};

export default MyOrganisationNewSubcarrier;
