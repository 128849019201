import Breakpoint from '../../enums/Breakpoint';
import FixedToursPageDesktop from '../../features/fixed-tours/FixedToursPage/FixedToursPageDesktop';
import FixedToursPageMobile from '../../features/fixed-tours/FixedToursPage/FixedToursPageMobile';
import useWindowSize from '../../hooks/useWindowSize';

const FixedTours = () => {
  const { width } = useWindowSize();

  const isMobile = width < Breakpoint.LG;

  if (isMobile) {
    return <FixedToursPageMobile />;
  }

  return <FixedToursPageDesktop />;
};

export default FixedTours;
