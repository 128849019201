import PropTypes from 'prop-types';

import CardListContainer from '../../../components/CardListContainer';
import FixedTourCard from '../FixedTourCard';

const FixedToursCardList = (props) => {
  const { data, isLoading, isPending } = props;

  return (
    <CardListContainer
      isLoading={isLoading}
      isPending={isPending}
      isEmpty={data.length === 0}
      id="tasks"
    >
      {data.map((tour) => (
        <FixedTourCard key={tour.id} tour={tour} type="fixed" />
      ))}
    </CardListContainer>
  );
};

FixedToursCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isPending: PropTypes.bool,
};

FixedToursCardList.defaultProps = {
  data: [],
  isLoading: false,
  isPending: false,
};

export default FixedToursCardList;
