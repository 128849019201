import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import UserRole from '../../../enums/UserRole';
import useGroceriesToursPageSearchParams from '../../../hooks/useGroceriesToursPageSearchParams';
import Resource from '../../../lib/api/Resource';
import Allow from '../../../lib/rbac/Allow';
import GroceriesDataExportModal from '../DataExportModal';
import GroceryToursTable from '../GroceryToursTable';
import ToursSearch from '../ToursSearch';

const GroceriesToursPageDesktop = () => {
  const { t } = useTranslation();
  const {
    allParams,
    searchByQuery,
    searchParams,
    searchQuery,
    setSearchParams,
  } = useGroceriesToursPageSearchParams();
  const [isDataExportModalOpen, setIsDataExportModalOpen] = useState(false);

  return (
    <>
      <GroceriesDataExportModal
        isOpen={isDataExportModalOpen}
        onClose={() => setIsDataExportModalOpen(false)}
      />
      <Page className="max-h-screen">
        <Titlebar
          variant="small"
          menu={
            <div className="flex flex-col lg:flex-row gap-6">
              <ToursSearch
                searchBy={searchByQuery}
                searchValue={searchQuery}
                onSearch={(newSearchValue) => {
                  if (newSearchValue === searchQuery) {
                    return;
                  }
                  searchParams.set('page', '1');
                  if (!newSearchValue) {
                    searchParams.delete('search');
                  } else {
                    searchParams.set('search', newSearchValue?.trim());
                    searchParams.set('searchBy', searchByQuery);
                  }
                  setSearchParams(searchParams);
                }}
                onSearchByChange={(newSearchBy) => {
                  searchParams.set('page', '1');
                  searchParams.set('searchBy', newSearchBy);
                  setSearchParams(searchParams);
                }}
              />
            </div>
          }
          textPrimary={t('Tours')}
          titleRightContent={
            <Allow roles={[UserRole.Admin, UserRole.OperationsManager]}>
              <div className="flex flex-col gap-4 lg:flex-row">
                <Button
                  data-test="data-export"
                  icon="dataExport"
                  text={t('Data Export')}
                  variant="outlineBlack"
                  onClick={() => setIsDataExportModalOpen(true)}
                />
              </div>
            </Allow>
          }
        />

        <Page.Content variant="wide">
          <div className="flex flex-col min-h-0">
            <Resource
              paginationStickyBottom
              itemsPerPage={100}
              showPageSizePicker={false}
              params={allParams}
              resourceUrl="/grocery-tours"
              socketEntityName="groceryTour"
            >
              <GroceryToursTable />
            </Resource>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default GroceriesToursPageDesktop;
