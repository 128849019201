import { useMutation, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import FormCheckbox from '../../../components/FormCheckbox';
import FormSelect from '../../../components/FormSelect';
import RadioGroup from '../../../components/RadioGroup';
import Switch from '../../../components/Switch';
import DeliveryProof from '../../../enums/DeliveryProof';
import useCustomToast from '../../../hooks/useCustomToast';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';

const AlternativeDeliveryOptionDrawer = ({
  clientId,
  deliveryOption,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (values) => {
      let response;

      const body = {
        requiredConsent: values.requiredConsent,
        requiredProof: values.requiredProof,
        requiredInstructions: values.requiredInstructions,
        requiredDriverNote: values.requiredDriverNote,
      };

      if (deliveryOption.enabled && values.enabled) {
        // update
        response = await fetch(
          `/clients/${clientId}/delivery-options/${deliveryOption.id}`,
          {
            body,
            method: 'PUT',
          },
        );
      } else if (!deliveryOption.enabled && values.enabled) {
        // enable
        response = await fetch(`/clients/${clientId}/delivery-options`, {
          body: { ...body, deliveryOptionId: deliveryOption.id },
          method: 'POST',
        });
      } else {
        // delete
        response = await fetch(
          `/clients/${clientId}/delivery-options/${deliveryOption.id}`,
          {
            method: 'DELETE',
          },
        );
      }

      return response.json();
    },
    onError: (error) => toastFetchError(error),
    onSuccess: (response) => {
      if (!response.success) {
        return;
      }
      toastSuccess(t('Delivery option configured.'));
      queryClient.invalidateQueries([`/clients/${clientId}/delivery-options`]);
      onClose();
    },
  });

  const { nameTranslations } = useDeliveryOptionsTranslations();

  const methods = useForm({
    defaultValues: {
      enabled: false,
      requiredConsent: false,
      requiredProof: '',
      requiredInstructions: false,
      requiredDriverNote: false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const { control, formState, handleSubmit, reset, watch } = methods;

  const { isDirty } = formState;

  const watchEnabled = watch('enabled');
  const watchRequiredConsent = watch('requiredConsent');

  const isSubmitEnabled =
    deliveryOption &&
    // enable
    ((!deliveryOption.enabled && watchEnabled && isDirty) ||
      // update
      (deliveryOption.enabled && watchEnabled && isDirty) ||
      // delete
      (deliveryOption.enabled && !watchEnabled && isDirty));

  return (
    <FormProvider {...methods}>
      <Drawer
        onOpen={() => {
          reset({
            enabled: deliveryOption.enabled || false,
            requiredConsent: deliveryOption.requiredConsent || false,
            requiredDriverNote: deliveryOption.requiredDriverNote || false,
            requiredProof: deliveryOption.requiredProof || DeliveryProof.Photo,
            requiredInstructions: deliveryOption.requiredInstructions || false,
          });
        }}
        data-test="delivery-option-drawer"
        body={
          <form>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <Controller
                    control={control}
                    name="enabled"
                    render={({ field: { onChange, value } }) => (
                      <div
                        className="w-full"
                        data-test="delivery-option-enabled"
                        data-test-status={deliveryOption?.enabled}
                      >
                        <div
                          className={cn(
                            value ? 'bg-ui-green-dark-light' : 'bg-grey-100',
                            'px-4 py-6 w-full flex items-center gap-3 rounded-md',
                          )}
                        >
                          <Switch
                            variant="green"
                            checked={value}
                            onChange={onChange}
                          />
                          <span className="text-sm font-medium">
                            {t('Enable option')}
                          </span>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div>
                  <h2 className="font-semibold text-base mb-5 mt-6">
                    {t('Customer Consent')}
                  </h2>
                  <FormCheckbox
                    data-test="recipent-consent-checkbox"
                    id="recipent-consent"
                    label={t('Require customer consent')}
                    name="requiredConsent"
                  />

                  {watchRequiredConsent && (
                    <div className="pl-6">
                      <Controller
                        control={control}
                        name="requiredInstructions"
                        render={({ field }) => (
                          <RadioGroup
                            options={[
                              {
                                value: false,
                                label: t('No additional instructions required'),
                              },
                              {
                                value: true,
                                label: t(
                                  'Customer must provide additional instructions',
                                ),
                              },
                            ]}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col text-sm">
                <h2 className="font-semibold text-base mb-5">{t('Setup')}</h2>

                <div>
                  <FormSelect
                    id="proof"
                    label={t('Delivery Proof')}
                    name="requiredProof"
                    required
                    options={[
                      {
                        value: DeliveryProof.Photo,
                        label: t('Photo'),
                      },
                      {
                        value: DeliveryProof.Signature,
                        label: t('Signature'),
                      },
                    ]}
                  />
                </div>

                <Alert
                  className="mt-2"
                  fontWeight="normal"
                  variant="info"
                  message={t(
                    'Proof represents a mandatory action that the Driver needs to perform to save proof of the delivery in the database.',
                  )}
                />

                <div className="mt-4">
                  <FormCheckbox
                    name="requiredDriverNote"
                    label={t(
                      'Details of completed delivery required (Driver note)',
                    )}
                  />
                </div>

                <Alert
                  className="mt-2"
                  fontWeight="normal"
                  variant="info"
                  message={t(
                    "A mandatory Driver note for Drivers to enter delivery details, for example, if delivered to neighbour - neighbour's name.",
                  )}
                />
              </div>
            </div>
          </form>
        }
        header={
          <span
            data-test="delivery-option-drawer-header-code"
            data-code={deliveryOption?.code}
          >
            {nameTranslations[deliveryOption?.code] ||
              deliveryOption?.code ||
              ''}
          </span>
        }
        isOpen={isOpen}
        onClose={onClose}
        footer={
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={onClose}
            />
            <Button
              type="submit"
              data-test="modal-button-action"
              text={t('Save')}
              variant="solidBlue"
              disabled={!isSubmitEnabled}
              onClick={handleSubmit(mutation.mutate)}
            />
          </div>
        }
      />
    </FormProvider>
  );
};

AlternativeDeliveryOptionDrawer.propTypes = {
  clientId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deliveryOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    requiredProof: PropTypes.oneOf(Object.values(DeliveryProof)),
    requiredConsent: PropTypes.bool,
    requiredInstructions: PropTypes.bool,
    requiredDriverNote: PropTypes.bool,
  }),
};

AlternativeDeliveryOptionDrawer.defaultProps = {
  deliveryOption: undefined,
};

export default AlternativeDeliveryOptionDrawer;
