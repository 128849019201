import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Breakpoint from '../../../enums/Breakpoint';
import MemberSortType from '../../../enums/MemberSortType';
import useMembersPageSearchParams from '../../../hooks/useMembersPageSearchParams';
import useWindowSize from '../../../hooks/useWindowSize';

const MembersSortDropdown = () => {
  const { currentSortType, searchParams, setSearchParams } =
    useMembersPageSearchParams();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;

  const lastActiveText = t('Last Active');
  const lastCreatedText = t('Last created');
  const earliestCreatedText = t('Earliest created');
  const ratingBadToExcellentText = t('Rating bad to excellent');
  const currentSortTypeText = useMemo(() => {
    switch (currentSortType) {
      case MemberSortType.LastActive:
        return lastActiveText;
      case MemberSortType.LastCreated:
        return lastCreatedText;
      case MemberSortType.EarliestCreated:
        return earliestCreatedText;
      case MemberSortType.RatingBadToExcellent:
        return ratingBadToExcellentText;
      default:
        return '';
    }
  }, [
    currentSortType,
    earliestCreatedText,
    lastActiveText,
    lastCreatedText,
    ratingBadToExcellentText,
  ]);

  const menuItems = useMemo(
    () => [
      {
        onClick: () => {
          searchParams.set('sort', MemberSortType.LastActive);
          setSearchParams(searchParams);
        },
        text: lastActiveText,
        isActive: currentSortType === MemberSortType.LastActive,
      },
      {
        onClick: () => {
          searchParams.set('sort', MemberSortType.LastCreated);
          setSearchParams(searchParams);
        },
        text: lastCreatedText,
        isActive: currentSortType === MemberSortType.LastCreated,
      },
      {
        onClick: () => {
          searchParams.set('sort', MemberSortType.EarliestCreated);
          setSearchParams(searchParams);
        },
        text: earliestCreatedText,
        isActive: currentSortType === MemberSortType.EarliestCreated,
      },
      {
        onClick: () => {
          searchParams.set('sort', MemberSortType.RatingBadToExcellent);
          setSearchParams(searchParams);
        },
        text: ratingBadToExcellentText,
        isActive: currentSortType === MemberSortType.RatingBadToExcellent,
      },
    ],
    [
      currentSortType,
      earliestCreatedText,
      lastActiveText,
      lastCreatedText,
      ratingBadToExcellentText,
      searchParams,
      setSearchParams,
    ],
  );

  return (
    <Dropdown menu={menuItems} placement="bottom-end">
      <Button
        size={isMobile ? 'm' : 's'}
        icon="sort"
        isFullWidth
        variant="outlineBlack"
        text={currentSortTypeText}
      />
    </Dropdown>
  );
};

export default MembersSortDropdown;
