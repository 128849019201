import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';
import Breakpoint from '../../enums/Breakpoint';
import ClientMembersPageDesktop from '../../features/clients/ClientMembersPage/ClientMembersPageDesktop';
import ClientMembersPageMobile from '../../features/clients/ClientMembersPage/ClientMembersPageMobile';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';

const ClientMembers = () => {
  const { id } = useParams();
  const { width } = useWindowSize();
  const { fetch } = useFetch();

  const isMobile = width < Breakpoint.LG;

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/clients/`, id],

    queryFn: async () => {
      const response = await fetch(`/clients/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (isMobile) {
    return (
      <ClientMembersPageMobile
        organisation={organisation}
        isLoading={isPending}
      />
    );
  }
  return (
    <ClientMembersPageDesktop
      organisation={organisation}
      isLoading={isPending}
    />
  );
};

export default ClientMembers;
