import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

const ShiftTimeConflictModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {t(
            'Two or more shifts cannot operate within the same time slot. Please adjust the duration of the shift accordingly.',
          )}
        </p>
      }
      footer={
        <Button
          data-test="modal-button-cancel"
          text="OK"
          variant="solidBlue"
          onClick={onClose}
        />
      }
      header={<span>{t('Shift Time Conflict')}</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

ShiftTimeConflictModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShiftTimeConflictModal;
