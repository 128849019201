import { keepPreviousData, useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeIndicator from '../../../components/BadgeIndicator';
import IconButton from '../../../components/IconButton';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import useIsStuck from '../../../hooks/useIsStuck';
import useMembersPageSearchParams from '../../../hooks/useMembersPageSearchParams';
import useResourceQuery from '../../../lib/api/hooks/useResourceQuery';
import Resource from '../../../lib/api/Resource';
import FloatingContainerProvider from '../../../providers/FloatingContainerProvider';
import { useTitlebarHeight } from '../../../providers/TitlebarHeightProvider';
import MembersCardList from '../../shared/MembersCardList';
import MembersSortDropdown from '../../shared/MembersSortDropdown';
import MobileListVisibleContent from '../../shared/MobileListVisibleContent';
import MembersFiltersDrawer from '../MembersFiltersDrawer';

const itemsPerPage = 15;

const MembersPageMobile = ({ isLoading, organisation }) => {
  const { t } = useTranslation();
  const { totalTitlebarHeight } = useTitlebarHeight();
  const stickyElementRef = useRef(null);
  const isStuck = useIsStuck(stickyElementRef);
  const { allParams, isFilterApplied } = useMembersPageSearchParams();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const visiblePageContentRef = useRef(null);

  const { queryKey } = useResourceQuery({
    itemsPerPage,
    resourceUrl: '/users',
    params: allParams,
  });

  const { data } = useQuery({ queryKey, placeholderData: keepPreviousData });

  return (
    <Page>
      <MembersFiltersDrawer
        organisation={organisation}
        onClose={() => setIsFilterDrawerOpen(false)}
        isOpen={isFilterDrawerOpen}
      />
      <Titlebar
        textPrimary={t('Members')}
        textSecondary={organisation?.name}
        backLink="/my-organisation"
        isLoading={isLoading}
      />
      <div
        ref={stickyElementRef}
        className={cn(
          'bg-white px-4 pb-2 sticky z-10 border-b transition-[padding]',
          isStuck ? 'border-grey-200 pt-3' : 'border-transparent pt-2',
        )}
        style={{
          top: `calc(var(--topbar-height) + ${totalTitlebarHeight}px)`,
        }}
      >
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col grow overflow-hidden">
            <MembersSortDropdown />
            <div className="text-sm pt-3">
              <span className="text-grey-700">{t('Member count')}:</span>{' '}
              <span className="font-medium">{data?.count || 0}</span>
            </div>
          </div>
          <div className="flex gap-2">
            <BadgeIndicator variant="warning" isVisible={isFilterApplied}>
              <IconButton
                onClick={() => setIsFilterDrawerOpen(true)}
                iconSize="s"
                icon="filter"
                variant="outlineBlack"
              />
            </BadgeIndicator>
          </div>
        </div>
      </div>
      <Page.Content variant="grey">
        <FloatingContainerProvider
          floatingContainer={visiblePageContentRef?.current}
        >
          <Resource
            itemsPerPage={itemsPerPage}
            showPageSizePicker={false}
            params={allParams}
            resourceUrl="/users"
          >
            <MembersCardList />
          </Resource>
          <MobileListVisibleContent
            ref={visiblePageContentRef}
            stickyElementRef={stickyElementRef}
          />
        </FloatingContainerProvider>
      </Page.Content>
    </Page>
  );
};

MembersPageMobile.propTypes = {
  organisation: PropTypes.shape({
    name: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
};

MembersPageMobile.defaultProps = {
  organisation: undefined,
};

export default MembersPageMobile;
