import { useMemo, useState } from 'react';
import useSound from 'use-sound';

import alreadyScannedSound from '../features/package-scanning/ScanFeed/sounds/already-scanned.mp3';
import scannerErrorSound from '../features/package-scanning/ScanFeed/sounds/scanner-error.wav';
import scannerSuccessSound from '../features/package-scanning/ScanFeed/sounds/scanner-success.wav';

const useScanSoundEffects = () => {
  const [scannerErrorSoundReady, setScannerErrorSoundReady] = useState(false);
  const [scannerSuccessSoundReady, setScannerSuccessSoundReady] =
    useState(false);
  const [alreadyScannedSoundReady, setAlreadyScannedSoundReady] =
    useState(false);

  const allSoundsReady = useMemo(
    () =>
      scannerErrorSoundReady &&
      scannerSuccessSoundReady &&
      alreadyScannedSoundReady,
    [
      alreadyScannedSoundReady,
      scannerErrorSoundReady,
      scannerSuccessSoundReady,
    ],
  );

  const [playScannerErrorSound] = useSound(scannerErrorSound, {
    onload: () => {
      setScannerErrorSoundReady(true);
    },
    volume: 0.7,
    interrupt: true,
  });

  const [playScannerSuccessSound] = useSound(scannerSuccessSound, {
    onload: () => {
      setScannerSuccessSoundReady(true);
    },
    volume: 0.7,
    interrupt: true,
  });

  const [playAlreadyScannedSound] = useSound(alreadyScannedSound, {
    onload: () => {
      setAlreadyScannedSoundReady(true);
    },
    volume: 0.7,
    interrupt: true,
  });

  return {
    allSoundsReady,
    playScannerErrorSound,
    playScannerSuccessSound,
    playAlreadyScannedSound,
  };
};

export default useScanSoundEffects;
