import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import hubDefaultCheckInTimeOffset from '../../constants/hubDefaultCheckInTimeOffset';
import HubForm from '../../features/hubs/HubForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const MyOrganisationNewHub = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { fetch } = useFetch();
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();

  const title = t('New Hub');

  const mutation = useMutation({
    mutationFn: (values) =>
      fetch('/hubs', {
        body: {
          ...values,
          checkInTimeOffset: hubDefaultCheckInTimeOffset,
        },
        method: 'POST',
      }),
    onError: (error) => {
      toastFetchError(error);
    },
    onSuccess: () => {
      setIsFormDirty(false);
      toastSuccess(t('New Hub added.'));
    },
  });

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, mutation.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate(`/my-organisation/hubs`, {
        replace: true,
      });
    }
  }, [mutation.isSuccess, navigate]);

  return (
    <>
      <CancelPrompt
        title={t('Cancel Creating Hub?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        onExitClick={() => blocker.proceed()}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="plus" />}
          textPrimary={title}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              disabled={mutation.isPending}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate('/my-organisation/hubs')}
            />
          }
        />
        <Page.Content centerContent variant="grey">
          <div className="w-full max-w-[480px]">
            <Card className="px-4 py-5 sm:p-8">
              <HubForm
                isSubmitting={mutation.isPending}
                onCancel={() => navigate('/my-organisation/hubs')}
                onIsDirtyChange={setIsFormDirty}
                onSubmit={mutation.mutate}
              />
            </Card>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default MyOrganisationNewHub;
